import MainMenu from './MainMenu'

export default class MenuList {
  /**
   * @param params: Array:MainMenu
   */
  constructor (menuList = []) {
    this.menuList = menuList
  }

  /**
   * add new menu entry to the menu list
   * @param mainMenu: menu
   */
  addMenu (mainMenu) {
    this.menuList.push(mainMenu)
  }

  /**
   * collapse all main menus and go to the route of the
   * @param Menu: menu
   */
  async makeRouting (menu) {
    // collapse all main menus
    await this.collapseAllMainMenus()

    // expand main menu of selected menu
    await this.expandMainMenu(menu)

    // only do routing, if there is a routing
    if (menu.getRoute() && !menu.getRoute().meta.inactive) {
      menu.goToRoute()
    }
  }

  /**
   * expand main menu (search for main menu, if it's not a main menu)
   * @param Menu:menu
   */
  expandMainMenu (menu) {
    // is it a mainMenu
    if (menu instanceof MainMenu) {
      menu.expand()
      // eslint-disable-next-line
      return Promise
    } else {
      for (let i = 0; i < this.menuList.length; i++) {
        let tmpMainMenu = this.menuList[i]

        let searchResult = tmpMainMenu.getSubMenus().find((tmpMenu) => {
          return tmpMenu === menu
        })

        // expand menu, if found one
        if (searchResult) {
          tmpMainMenu.expand()
          // eslint-disable-next-line
          return Promise
        }
      }
    }
  }

  // close all menus
  collapseAllMainMenus () {
    this.menuList.forEach((mainMenu) => {
      mainMenu.collapse()
    })
  }

  expandMenuByCurrentRouteName (routeName) {
    // get the main menu of the route
    let mainMenu = this.getMainMenuByRouteName(routeName)

    // any main menu found?
    if (mainMenu) {
      mainMenu.expand()
    }
  }

  getMainMenuByRouteName (routeName) {
    // iterate main menus
    for (let i = 0; i < this.menuList.length; i++) {
      let mainMenu = this.menuList[i]

      // is main menu the route ?
      if (mainMenu.getRoute().name === routeName) {
        return mainMenu
      } else {
        let searchResult = mainMenu.getSubMenus().find((subMenu) => {
          return subMenu.getRoute()?.name === routeName
        })

        if (searchResult) return mainMenu
      }
    }

    return null
  }

  /**
   * GETTERS
   */

  getMenuList () {
    return this.menuList
  }
}
