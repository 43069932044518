  /* default config file for farmx frontend */
    let testMode = false

    export default {
  /* IMPORTANT NOTE: api requires slash at the end of the url! Example: 'https://exampleapi.example.ch/' */
    /* Other apis (eg. billing api) don't need slashes at the end of the url! */

    // LOCALHOST
    // api: 'http://localhost:3000/',
    // billingApi: 'http://localhost:3000/pdf',
    // imageApi: 'http://localhost:3000/images',
    // imageUploadApi: 'http://localhost:3000/upload/picture',
    // frontendBaseUrl: 'http://localhost:8080/',

    // DEV
    // api: 'https://devapi.farmx.ch/',
    // billingApi: 'https://devd.farmx.ch/pdf',
    // imageApi: 'https://devd.farmx.ch/images',
    // imageUploadApi: 'https://devapi.farmx.ch/upload/picture',
    // frontendBaseUrl: 'devapp.farmx.ch/',

    // PROD
    // api: 'https://api.farmx.ch/',
    // billingApi: 'https://d.farmx.ch/pdf',
    // imageApi: 'https://d.farmx.ch/images',
    // imageUploadApi: 'https://api.farmx.ch/upload/picture',
    // frontendBaseUrl: 'https://farmx.ch/',

    // DEV
  api: 'https://api.farmx.ch/',
  billingApi: 'https://d.farmx.ch/pdf',
  imageApi: 'https://d.farmx.ch/images',
  imageUploadApi: 'https://api.farmx.ch/upload/picture',
  frontendBaseUrl: 'https://farmx.ch/',
  wordpressBaseUrl: 'https://info.farmx.ch/wp-json/wp/v2',
  sentryDSN: 'https://7e1e9616c2994ce7ae0254b3c9f394de@sentry.seccom.ch/8',
  sentrySR: 0.1,

                     /**
                     * Access rights
                     */
                       user: {
                         employee: 50,
                         normal: 15,
                         office: 10,
                         support: 5,
                         admin: 0
                       },

                     /**
                     * Timezone: Force application to use this timezone with moment.js
    */
                       timezone: 'Europe/Zurich',

                     /**
                     * Google reCaptcha
                     */
                       recaptcha: {
                         sitekey: '6LcpF4cUAAAAAIK7h6XYEa8HtE7bamlrEpMnNPAE'
                       },

    // get random number in test
    getRecaptchaCode () {
  return testMode ? '1234' : null
  },

    // set default standard vat id for dropdown => id from database
  standardVatId: 1
  }
