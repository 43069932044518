import Vue from 'vue'
import Vuex from 'vuex'

import Languages from '../classes/Languages'
import RedirectMemory from '../classes/Helper/RedirectMemory'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,  // process.env.NODE_ENV !== 'production',

  getters: {
    getRedirectMemory: state => () => {
      return state.redirectMemory
    },

    getCurrentLanguage: state => () => {
      return state.appUserInstance ? state.appUserInstance.getLanguage() : state.languageObject.getCurrentLanguageInstance()
    },
    // get unit name
    getUnitName: (state, getters) => (unitObject) => {
      switch (getters.getCurrentLanguage().getLanguageKey()) {
        case 'de_CH':
          return unitObject.itemUnitNameDe
        case 'fr_CH':
          return unitObject.itemUnitNameFr
        case 'it_CH':
          return unitObject.itemUnitNameIt
        default:
          return unitObject.itemUnitNameDe
      }
    },

    // get the short term of a unit object
    getUnitShort: (state, getters) => (unitObject) => {
      switch (getters.getCurrentLanguage().getLanguageKey()) {
        case 'de_CH':
          return unitObject.itemUnitShortDe
        case 'fr_CH':
          return unitObject.itemUnitShortFr
        case 'it_CH':
          return unitObject.itemUnitShortIt
        default:
          return unitObject.itemUnitShortDe
      }
    },

    getImage: () => (img = null, original = true) => {
      /* global imageApi */
      if (img) {
        return original ? imageApi + img + '.jpg' : imageApi + img + '_thumbnail.jpg'
      } else {
        return null
      }
    },

    getLocalString: () => (miliSeconds, showTime = true) => {
      let tmpDate = new Date(miliSeconds)
      return showTime ? tmpDate.toLocaleDateString('de-DE') + ' | ' + tmpDate.toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'}) : tmpDate.toLocaleDateString('de-DE')
    },

    getLocalTime: () => (miliSeconds) => {
      return new Date(miliSeconds).toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'})
    },

    getReservationDate: (state, getters) => (from, to, showTime = false) => {
      let fromDate = new Date(from)
      let toDate = new Date(to)

      // check if from and to are in the same day
      if (fromDate.getDate() !== toDate.getDate() || fromDate.getMonth() !== toDate.getMonth() || fromDate.getFullYear() !== toDate.getFullYear()) {
        return getters.getLocalString(from, showTime) + ' - ' + getters.getLocalString(to, showTime)
      } else {
        return getters.getLocalString(from) + ' - ' + getters.getLocalTime(to)
      }
    },

    // helper function for sort integers on vue good tables
    sortTableStartDate: () => (x, y, col, rowX, rowY) => {
      return (rowX.startdate < rowY.startdate ? -1 : (rowX.startdate > rowY.startdate ? 1 : 0))
    },

    // helper function to round money to 05 CHF Rp.
    roundCHF: () => (amount) => {
      // fix 07.05.2019: added parseFloat() function => to get a float in csv export
      return parseFloat((Math.ceil(amount * 20 - 0.5) / 20).toFixed(2))
    },

    // todo: integrate auth key into app user
    // is user logged in
    isUserLoggedIn: state => () => {
      if (state.authtoken === 0) {
        return false
      } else {
        // user is logged in
        return true
      }
    },

    getAuthtoken: state => () => {
      return state.authtoken
    },

    getPaginationLimit: state => () => {
      return state.paginationLimit
    },

    getAppUserInstance: state => () => {
      return state.appUserInstance
    }
  },

  state: {
    redirectMemory: new RedirectMemory({}),

    authtoken: 0,

    // currently logged in user
    appUserInstance: null,

    // current language object of application
    languageObject: null,

    nativeAppHandlerInstance: null,

    // app instance: manage the app version on each axios request via axios interceptor
    appInstance: null,

    // save search parameters in advanced search
    tmpSearch: {},
    paginationLimit: 20,
    watchUserUid: '',
  },

  mutations: {
    initializeLanguage (state) {
      state.languageObject = new Languages()

      // init first the browser language as default
      state.languageObject.initBrowserLanguage()
    },

    setRedirectMemory (state, redirectMemory) {
      state.redirectMemory = redirectMemory
    },

    resetRedirectMemory (state) {
      state.redirectMemory = new RedirectMemory({})
    },

    setWatchUserUid (state, uid) {
      state.watchUserUid = uid
    },

    setPaginationLimit (state, limit) {
      state.paginationLimit = limit
    },

    addAuthtoken (state, authtoken) {
      state.authtoken = authtoken
    },

    // add app user instance to store
    addUser (state, appUserInstance) {
      state.appUserInstance = appUserInstance
    },

    saveUserMenu(state, menuObj){
      state.appUserInstance.menuSettings = menuObj
    },

    // set app user instance to null
    resetAppUserInstance (state) {
      state.appUserInstance = null
    },

    saveTmpSearch (state, tmpSearch) {
      state.tmpSearch = tmpSearch
    },

    addNativeAppHandler (state, handlerInstance) {
      state.nativeAppHandlerInstance = handlerInstance
    },

    // add app instance to handle app versions via axios interceptor
    addAppInstance (state, appInstance) {
      state.appInstance = appInstance
    },

    updateAppInstanceVersion(state, version) {
      state.appInstance.version =  version
    }
  },

  actions: {
    // logout user
    logout ({commit}) {
      // reset authtoken to 0
      commit('addAuthtoken', 0)

      // reset app user instance
      commit('resetAppUserInstance')
    }
  }
})
