//menuConf holds the menu settings not editable by the user (route/icon/customizable flag etc)
const menuConf = [
  {
    name: "lease",
    title: 'v2.lease',
    routeName: 'Dashboard',
    icon: 'fal fa-key',
    customizable: true,
    subMenus: [
      {
        name: "leaseRequests",
        title: 'menu.reservationRequests',
        routeName: 'ReservationRequestsRenter',
        customizable: true,
      },
      {
        name: "openReservations",
        title: 'v2.toClose',
        routeName: 'OpenReservations',
        customizable: true,
      },
      {
        name: "leaseArchive",
        title: 'menu.reservations',
        routeName: 'Reservations',
        customizable: true,
      }
    ]
  },
  {
    name: "employee",
    title: 'v2.myItemsMenu',
    routeName: 'EmployeeItems',
    icon: 'fal fa-users',
    customizable: true,
    subMenus: []
  },
  {
    name: "rent",
    title: 'v2.rent',
    routeName: 'OverviewRent',
    icon: 'fal fa-lock-open',
    customizable: true,
    subMenus: [
      {
        name: "rentMyItems",
        title: 'v2.myItemsMenu',
        routeName: 'Items',
        customizable: true,
      },
      {
        name: "rentResponsible",
        title: 'v2.responsibleMenu',
        routeName: 'Responsible',
        customizable: true,
      },
      {
        name: "rentReservationsRequests",
        title: 'menu.reservationRequests',
        routeName: 'ReservationRequestsOwner',
        customizable: true,
      },
      {
        name: "rentConflicts",
        title: 'v2.conflicts',
        routeName: 'ConflictingReservations',
        customizable: true,
      },
      {
        name: "rentDiscounts",
        title: 'menu.discounts',
        routeName: 'Discount',
        customizable: true,
      }
    ]
  },
  {
    name: "team",
    title: 'menu.myTeam',
    routeName: 'TeamMainMenuEntry',
    icon: 'fal fa-users',
    minGid: 15,
    customizable: true,
    subMenus: [
      {
        name: "teamMembers",
        title: 'menu.members',
        routeName: 'Members',
        customizable: true,
      },
      {
        name: "teamMemberships",
        title: 'menu.memberships',
        routeName:'Memberships',
        customizable: true
      },
      {
        name: "teamEmployee",
        title: 'menu.employee',
        routeName: 'Employee',
        customizable: true,
      }
    ]
  },
  {
    name: "incomingInvoices",
    title: 'menu.incomingInvoices',
    routeName: 'incomingInvoices',
    icon: 'fal fa-envelope-open-text',
    customizable: true,
    subMenus: []
  },
  {
    name: "billing",
    title: 'menu.billing',
    routeName: 'Billing',
    icon: 'fal fa-file-invoice',
    customizable: true,
    subMenus: [
      {
        name: "billingPublished",
        title: 'menu.publishedBillings',
        routeName: 'PublishedBillings',
        customizable: true,
      }
    ]
  },
  {
    name: "settings",
    title: 'menu.settings',
    routeName: 'settings',
    icon: 'fal fa-cog',
    customizable: false,
    subMenus: []
  },
  {
    name: "disconnect",
    title: 'v12.logout',
    routeName: 'Logout',
    icon: 'fal fa-sign-out',
    onlyMobile: true,
    customizable: false,
    subMenus: []
  },
  {
    name: "admin",
    title: 'v5.menuAdmin',
    routeName: 'admin',
    icon: 'fal fa-users-cog',
    customizable: false,
    subMenus: []
  }
]

//the fallback menu settings to be used if failed to fetch user settings from backend
const defaultMenu = [
  {
    name: "lease",
    active: true,
    subMenus: [
      {
        name: "leaseRequests",
        active: true,
      },
      {
        name: "openReservations",
        active: true,
      },
      {
        name: "leaseArchive",
        active: true,
      }
    ]
  },
  {
    name: "employee",
    active: true,
    subMenus: []
  },
  {
    name: "rent",
    active: true,
    subMenus: [
      {
        name: "rentMyItems",
        active: true,
      },
      {
        name: "rentResponsible",
        active: true,
      },
      {
        name: "rentReservationsRequests",
        active: true,
      },
      {
        name: "rentConflicts",
        active: true,
      },
      {
        name: "rentDiscounts",
        active: true,
      }
    ]
  },
  {
    name: "team",
    active: true,
    subMenus: [
      {
        name: "teamMembers",
        active: true,
      },
      {
        name: "teamEmployee",
        active: true,
      }
    ]
  },
  {
    name: "incomingInvoices",
    active: true,
    subMenus: []
  },
  {
    name: "billing",
    active: true,
    subMenus: [
      {
        name: "billingPublished",
        active: true,
      }
    ]
  },
  {
    name: "settings",
    active: true,
    subMenus: []
  },
  {
    name: "disconnect",
    active: true,
    subMenus: []
  },
  {
    name: "admin",
    active: true,
    subMenus: []
  }
]

export default  {
  menuConf,
  defaultMenu
 }
