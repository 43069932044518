<template>
    <ul class="wizard-steps horizontal-steps rich-steps" :class="{'completed': completed}">
      <li
        class="wizard-step"
        :class="{'active': currentStep >= index, 'current': currentStep === index}"
        :style="{ width: 100/steps.length + '%' }"
        v-for="(step, index) of steps"
        :key="index"
      >
        <i v-if="(currentStep >= index)" class="fal fa-check fa-2x"/>
        <i v-else class="fal fa-times fa-2x"/>
        <span class="wizard-step-label ellipsis" style="">
          {{ $vuetify.breakpoint.xsOnly ? step.label.slice(0, 12) : step.label}}</span>
        <span class="wizard-step-line"></span>
      </li>
    </ul>
</template>

<script>
  export default {
    name: 'vuestic-wizard-rich-horizontal-indicator',
    props: {
      steps: {
        type: Array,
        default: () => []
      },
      currentStep: {
        type: Number,
        default: 0
      },
      completed: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>

  $wizard-step-height: 4.6rem;
  $wizard-step-label-font-size: $font-size-root;
  $wizard-step-padding-v: 0;
  $wizard-step-padding-h: 0.315rem;
  $wizard-step-icon-height: 2.25rem;
  $wizard-step-icon-fs: 3.125rem;
  $wizard-step-ion-icon-alignment: 0.5rem;

  $wizard-label-width: 100%;
  $wizard-label-padding: 0 0.6rem;

  .wizard-steps{
    list-style-type:  none;
    padding: $wizard-step-padding-v $wizard-step-padding-h;
    height:  $wizard-step-height;
    position:  relative;
  }
  .wizard-step{
    height: $wizard-step-height;
    padding: $wizard-step-padding-v $wizard-step-padding-h;
    color: map-get($grey, lighten-4);
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: color 300ms linear;

    .wizard-step-line{
      width:  100%;
      height:  2px;
      background-color: map-get($grey, lighten-4);
      transition: background-color 300ms linear;
    }

    .wizard-step-label{
      display: inline-block;
      width: $wizard-label-width;
      padding: $wizard-label-padding;
      text-align: center;
      font-size: $wizard-step-label-font-size;
      font-weight: bold;
    }

    &.active {
      color: var(--v-primary-base);

      .wizard-step-line{
        background-color: var(--v-primary-base);
      }

      &.current {
        color: var(--v-primary-base);
      }

      .completed &.current {
        color: var(--v-primary-base);
      }
    }
  }
</style>
