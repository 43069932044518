export default {
  general: {
    confirmed: 'Confermato',
    total: 'Totale',
    yes: 'Si',
    no: 'No',
    from: 'Da',
    to: 'A',
    at: 'A',
    and: 'E',
    cancel: 'Annullare',
    ok: 'Ok',
    add: 'Aggiungere',
    delete: 'Rimuovere',
    edit: 'Modificare',
    save: 'Registrare',
    reallyDelete: 'Cancellare?',
    tableSearch: 'Ricerca nella tabella',
    rowsPerPageLabel: 'Linea per pagina',
    search: 'Ricerca',
    name: 'Nome',
    surname: 'Cognome',
    lastname: 'Cognome',
    firstname: 'Nome',
    address: 'Indirizzo',
    plz: 'CAP',
    city: 'Città',
    categories: 'Categoria',
    back: 'Indietro',
    prev: 'Precedente',
    next: 'Successivo',
    date: 'Data',
    time: 'Ora',
    machine: 'Macchina',
    language: 'Lingua',
    logo: 'Logo',
    phone: 'Telefono',
    mobile: 'Cellulare',
    reservation: 'Prenotazioni',
    renter: 'Locatario',
    lessor: 'Destinatario',
    print: 'Stampare',
    farMeSlug: 'Quando affittare diventa un riflesso',
    confirm: 'Confermare',
    use: 'Uso',
    description: 'Descrizione',
    me: 'Io'
  },
  menu: {
    incomingInvoices: 'Fatture in arrivo',
    dashboard: 'Riservazione',
    items: 'Le mie macchine',
    billing: 'Conto di liquidazione',
    myTeam: 'Il mio team',
    members: 'Membri',
    employee: 'Collaboratori',
    settings: 'Le mie impostazioni',
    reservations: 'Archivio',
    newBilling: 'Nuovo conteggio',
    publishedBillings: 'Confermata',
    draftBillings: 'Bozze',
    notifications: 'Notifiche',
    reservationRequests: 'Richieste di prenotazioni',
    discounts: 'Sconto',
    memberships: 'Soci',
  },
  localString: 'it-CH',
  notifications: {
    overviewWidgetTitle: 'Notifiche',
    all: 'Tutte le notifiche '
  },
  changeUser: {
    changeToMe: 'Continua come utente ',
    permissionDenied: 'Il conto dell\'utente attuale non ti permette l\'accesso a questa pagina',
    changeUserModalTitle: 'Cambiare l\'utente',
    changeUserModalExplanation: 'Con quale utente dsidrera continuare',
    selectUser: 'Ricerca d\'utente',
    changeUser: 'Cambiare l\'utente'
  },
  invoice: {
    invoiceReceived: 'Pagate',
    pdfIsNotCreatedYet: 'Fatture non disponibile in PDF per il momento.Riprovate successivamente',
    copyPdfLink: 'Copiare il collegamento PDF',
    downloadPdf: 'Scaricare il PDF',
    newDescription: 'Descrizione',
    newAmount: 'Importo (CHF)',
    addCustomEntryModalTitle: 'Inserendo una nuova voce di fattura',
    receiver: 'Destinatario',
    sender: 'Mittente',
    invoice: 'Fattura',
    invoiceNr: 'Fattura N°',
    use: 'Uso',
    balance: 'Saldo',
    pricePerUnit: 'Prezzo/ Unità',
    totalExklTaxes: 'Totale senza IVA.',
    taxrate: 'IVA.',
    totalInklTaxes: 'Totale TVA inclusa',
    payConditions: 'Si prega di pagare l\'importo dovuto.30 giorni di tempo netto',
    iban: 'IBAN',
    addOwnEntry: 'Aggiungi una voce',
    appUserMissing: 'Errore inatteso: "AppUserInstance non disponibile".',
    missingIban: 'IBAN non valido',
    provideIban: 'Aggiungi l\'IBAN nelle tue impostazioni.'
  },
  billing: {
    publishedCustomInvoiceTitle: 'Singole bollette confermate',
    addReceiver: 'Destinatario della fattura',
    createNewCustomInvoiceModalTitle: 'Nuova semplice fattura',
    customInvoiceTitle: 'Bozza di singola fattura',
    addNewCustomInvoice: 'Semplice fattura',
    isPdfReady: 'Stato PDF',
    invoicePdfIsReady: 'Prestito',
    pdfIsGenerating: 'In corso...',
    invoicePaid: 'Pagato?',
    pdfIsNotCreatedYet: 'Fattura non disponibile in PDF per il momento.Riprovare successivamente.',
    publishedBillingsTitle: 'Contare',
    reallyConfirm: 'Sei sicuro?',
    reallyConfirmBillingExplanation: 'Questa operazione invierrà le fatture a i tuoi clineti. Quest\'ultime non potranno piu essere modificate. ' +
      '<b>Questa operazione e dfinitiva!</b>',
    showInvoices: 'Visualizza la fattura',
    showInvoice: 'Modificare la fattura',
    confirmBilling: 'Inviare il conteggio!',
    confirmInvoice: 'Confermare',
    whatToDoModalTitle: 'Cosa vuoi fare??',
    deleteBilling: 'Elimina il conteggio',
    deleteInvoice: 'Elimina la fattura',
    noSelectedReservations: 'Non hai scelto il mese di fatturazione',
    selectedReservationsModalTitle: 'Le tue prenotazioni selezionate',
    showAllSelectedReservations: 'Mostra tutte le prenotazioni per il periodo selezionato',
    noDraftBillings: 'Non hai nessuna fattura in modalità bozzo.',
    billingDeleteExplanaition: 'Il conto e le fatture saranno cancellati.Questa operazione elimina la spiegazione',
    draftState: 'Bozza',
    receiver: 'Destinatario',
    billingDetailsTitle: 'Dettagli dell\'account',
    showMoreDetails: 'Visualizza i dettagli dell\'account',
    invoicesHeaderText: 'Conto di liquidazione',
    Mwst: 'IVA',
    period: 'Periodo',
    billingNameTable: 'Conto di liquidazione',
    doNotCorrect: '! Correggi con l\'importo mancante!',
    newBillingSuccessfull: 'Il conteggio e stato generato correttamente.',
    newBillingError: 'Si è verificato un errore durante la generazione del conto ',
    giveBillingMwst: 'Vuoi includere l\'IVA nel conteggio?',
    billingMwst: 'IVA %',
    billingNameTitle: 'Nome di conteggio',
    giveBillingAName: 'Dai un nome al conteggio',
    endUse: 'Utilizzo definitivo da parte del locatario',
    counterStart: 'Stato del contatore prima dell\'uso',
    counterEnd: 'Stato del contatore dopo l\'uso',
    correct: 'Correggi il contatore',
    solveConflictModalTitle: 'Risolvere il conflitto con contatore',
    missingAmount: 'Importo mancante',
    draftBillingsTitle: 'Bozza di dichiarazioni',
    openReservationsToClose: 'Alcune prenotazioni del periodo di regolamento desiderato non hanno alcun uso dichiarato. Devi prima chiuderli.',
    renter: 'Locatario',
    reservationDate: 'Prenotazione',
    billingNameExplanaition: 'Cambia il nome del conteggio',
    billingName: 'Nome del conteggio',
    complete: 'Genera il conteggio',
    wantToConfirmCreationOfNewBilling: 'Souhaitez vous générer le décompte du {billingFrom} jusqu\'au {billingTo}?',
    noConflictingReservations: 'Molto bene! Nessuna prenotazione genera contocorrente',
    noOpenReservations: 'Molto bene! Noon ci sono prenotazioni aperte nel periodo prescelto.Puoi continuare',
    conflictingReservations: 'Contro il conflitto',
    timePeriod: 'Scegli un periodo',
    openReservations: 'Chiudere le prenotazioni aperte',
    youHaveNoInvoiceReservations: 'Hai le prenotazioni che devono essere fatturate',
    newBilling: 'Creare un conteggio',
    totalChfWithoutDiscount: 'Totale senza sconto (chf)',
    searchInvoice: 'Cerca per nome, cognome o città'
  },
  paginator: {
    loadMore: 'Caricare di più',
    perPage: 'Articoli per pagina'
  },
  setAmount: {
    wantToSplitReservationTitle: 'Sicuro?',
    wantToSplitReservationText: 'Vuoi davvero dividere la prenotazione? Questa operazione non puô essere modificata in seguito',
    splitReservation: 'Dividi le prenotazione',
    startCounterShort: 'Inizio contatore',
    endCounterShort: 'Fine contatore',
    amount: 'Inserire l\'uso',
    amountShort: 'Uso',
    hasNoCounterExplain: 'Quanto hai usato la macchina?',
    endCounter: 'Stato del contatore: di fine prenotazione',
    startCounter: 'Stato del contatore :prenotazione anticipata',
    setCounters: 'Si prega di inserire lo stato del contatore prima e dopo l\'uso nelle caselle sottostanti.',
    modalTitle: 'Inserire l\'uso',
    negativeValuesNotAllowed: 'I valori negativi non possono essere registrati.',
    commentRequired: 'Dite alla società di noleggio perché non avete usato la macchina.'

  },
  user: {
    machines: 'Parco macchine',
    profile: 'Profilo utente'
  },
  settings: {
    passwordIsNotUid: 'La password non puo essere composta da e-mail, nome,codice postale , luogo o numero di telefono',
    passwordHasSmallLetter: 'La password deve contenere almeno una lettera minuscola.',
    passwordHasCapitalLetter: 'La password deve contenere almeno una parola maiuscola.',
    passwordHasSpecialCharacter: 'La password deve contenere almeno un carattere speciale.',
    passwordHasNumber: 'La password deve contenere almeno una cifra.',
    passwordEqual: 'Le password devono coincidere',
    passwordLength: 'La pasword deve contenere almeno 12 caratteri',
    password1: 'Inserire la password',
    password2: 'Conferma la password',
    changePassword: 'Cambia la password',
    company: 'Tipo di sfruttamento',
    myMembersArePublic: 'La mia lista di membri è pubblica',
    mwst: 'IVA',
    iban: 'IBAN',
    moreOptions: 'Impostazioni',
    headerTitle: 'Le mie impostazioni'
  },
  login: {
    autoLogin: 'Login automatico in futuro ( per fare questo registriamo cookie).',
    successfull: 'Si è registrato con successo'
  },
  dashboard: {
    reservationIsNotConfirmed: 'In attesa di conferma della prenotazione',
    noRequests: 'Al momento non hai una richiesta di prenotazione',
    closeOpenReservation: 'Chiudi la prenotazione',
    closeOpenReservationFirstModalTitle: 'Aprire prenotazione',
    closeOpenReservationFirstInformation: 'Devi prima chiudere la prenotazione',
    startReservation: 'Prenotazione anticipata',
    endReservation: 'Fine della prenotazione',
    toDebit: 'A spese di',
    reservationSplitModalTitle: 'Dividi la prenotazione',
    splitReservation: 'O dividi la prenotazione',
    doYouWantToSendThisProposition: 'Vorresti inviare questa proposta?',
    newReservationProposition: 'Fai una nuova proposta',
    deleteRequestModalTitle: 'Rifiutare la prenotazione',
    reallyDeleteRequest: 'Vuoi davvero rifiutare la prenotazione?',
    rejectRequestButton: 'Rifiutare',
    acceptRequestButton: 'Confermare',
    editRequestButton: 'Nuova proposta',
    detailRequestModalTitle: 'Cosa vuoi fare?',
    reservationRequestsTitle: 'Richieste di prenotazione',
    youHaveOverlappingTimeSlots: 'La tua scelta non è valida perchè si sovrappone ad altre prenotazioni',
    editReservation: 'Cambiamento',
    reservationEditModalTitle: 'Elaborare la prenotazione',
    doYouWantToDeleteTheReservation: 'Dobbiamo davvero cancellare la prenotazione?',
    reservationDeleteModalTitle: 'Effettivamente resiliato?',
    cancel: 'Terminare',
    previousRenter: 'Locatario precedente',
    followingRenter: 'Prossimo locatario',
    machineInstructions: 'Istruzioni per l\'uso',
    reservationDetailsModalTitle: 'Dettagli della prenotazione',
    endReservationModalTitle: 'Chiudi la prenotazione?',
    endReservationModalBody: 'Vorresti ora restituire la macchina e chiudere la prenotazione? Il prossimo locatario sara avvertito.',
    extendReservationModalTitle: 'Estendere la prenotazione?',
    extendReservationModalBody: 'Dovremmo provare ad estendere la prenotazione di un\'ora?',
    noUpcomingReservations: 'Non hai prenotazione in sospeso',
    bringBackUntil: 'Riportare su',
    openReservations: 'Prenotazione aperte- Chiudi per favore',
    yourCurrentReservations: 'La tua prenotazione attuale',
    upcommingReservations: 'Prenotazioni in attesa',
    makeReservation: 'Prenota una macchina',
    closeReservation: 'Chiudi la prenotazione',
    machine: 'Macchina',
    ownPer: 'Proprietario',
    resFrom: 'Prenotazione di',
    resTo: 'Prenotazione a',
    resUse: 'uso',
    reservationRequestsFromLessor: 'Richieste di prenotazione (prendo in affito)',
    reservationRequestsFromRenter: 'Richieste die prenotazione (do in affito)',
    membershipRequests: 'Diventa membro',
    customNotifications: 'Messaggio ai membri'
  },
  employee: {
    headerSearch: 'Aggiungi un collaboratore',
    myEmployee: 'I miei collaboratori',
    deleteEmployeeExplanaition: 'Il collaboratore è escluso dalla tua squadra e perde i suoi vari diritti, come ex. prenotare macchina per te.',
    addModalTitle: 'Aggiungi un collaboratore al tuo team?',
    addModalBody: 'Questa persona avrà diritti diversi se la aggiungi alla tua squadra .In particolare puô riservare una macchina al tuo nome',
  },
  reservation: {
    telOnly: 'Solo per telefono',
    telOnlyModalTitle: 'Contatto fornitore',
    gotToUserProfilLink: 'Guarda l\'account utente',
    failExplanation: 'Qualcuno probabilmente ti ha sorpassato al momento della prenotazione. Quindi riprova con un\'altra data',
    success: 'Prenotazione andata a buon fine',
    fail: 'Fallimento della prenotazione!',
    reservationCorrect: 'Prenotazione corretta?',
    getReservations: 'Le prenotazione vengono caricate',
    nextWithConnectedItem: 'Seguente',
    nextWithoutConnectedItem: 'Prossimo senza combinazione',
    removeConnectedItem: 'Disabilitare',
    addConnectedMachine: 'Combinare',
    connectedMachineTitle: 'Questa macchina combina facilmente!',
    myTeamsTab: 'Comunità di macchine',
    myFavoritesTab: 'Preferiti',
    advancedSearch: 'Ricerca avanzata',
    myMachines: 'Le mie macchine',
    category: 'Catégoria',
    fromAdvanced: 'Da',
    toAdvanced: 'A',
    distance: 'Raggio',
    myLocation: 'La mia posizione',
    searchList: 'Sfoglia la lista',
    tooBigRadiusAndSelectCategory: 'Scegli una categoria.Il raggio non puo superare i 100 Km',
    noItemsFound: 'Nessuna macchina da visualizzare',
    location: 'Posizione della macchina',
    price: 'Prezzo',
    reservation: 'Prenotare',
    addItemAsFavorite: 'Aggiungi la macchina ai preferiti( clicca)',
    removeItemFromFavorite: 'Rimuovere la macchina dei favoriti (clicca)',
    searchForItems: 'Cerca una macchina che desidera nolleggiare',
    owner: 'Destinatario',
    showReservation: 'Dettagli Prenotazione'
  },
  calendar: {
    reservationsOn: 'Prenotazioni il',
    reservedBy: 'Riservata da'
  },
  items: {
    telOnly: 'Questa macchina puo essere prenotata solo per telefono',
    myMachines: 'Le mie macchine',
    addNew: 'Noleggiare macchine',
    title: 'Titolo',
    addImgTitle: 'Aggiungi una foto',
    itemDescription: 'Descrizione della macchina',
    categories: 'Scegli la categoria *',
    rules: 'Imporre regole',
    maxDistance: 'Distanza massima noleggio(km)',
    maxTime: 'Tempo di prenotazione massima (h)',
    reservationValidation: 'Devo confermare ogni prenotazione',
    hasCounter: 'La macchina ha un contatore',
    location: 'Posizione della macchina',
    teamOnly: 'Affitto la mia macchina solo ai miei membri ',
    priceTeam: 'Prezzo per i miei membri',
    priceGlobal: 'Prezzo per i non membri',
    pricePerUnit: 'Prezzo per unità *',
    active: 'Stato della macchina',
    showOptions: 'Mostra piû opzioni',
    disableOptions: 'Nascondi le opzioni',
    connectMachinesTitle: 'Combinare questa macchina',
    connectMachineSelect: 'Affittare questa macchina in combinazione con...',
    reallyDelete: 'Rimuovere?',
    reallyDeleteDescription: 'Questa macchina sarà cancellata e rimossa dal tuo profilo! Questo non può essere annullato.',
    deactivateItem: 'L\'annuncio sarà disabilitato',
    updateItem: 'Aggiorna la macchina',
    table: {
      name: 'Nome',
      title: 'Titolo',
      address: 'Indirizzo'
    },
    largerThanZeroRequired: 'Deve essere superiore a 0'
  },
  teams: {
    addHeader: 'Aggiungi un membro',
    myTeam: 'I miei membri',
    searchPlaceholder: 'Ricerca per utente, codice postale o luogo',
    searchResultsHeader: 'Risultati di ricerca',
    reallyAddTeam: 'Vuoi aggiungere la squadra di questa persona alla tua squadra?',
    addTeamExplanaition: 'Se lo fai, i membri del team di questa persona verranno aggiunti al tuo team.Questa operazione non puo essere modificata in seguito!',
    deleteMemberExplanaition: 'In questo modo la persona viene rimossa dal team. È possibile inviare nuovamente la richiesta di adesione alla persona in questione in un secondo momento.',
    reallyAddTitle: 'La richiesta deve essere confermata dalla persona.',
    reallyAddBody: 'La persona deve prima confermare l\'iscrizione richiesta prima che questa diventi attiva. Se la aggiungi, questa persona sarà in grado di noleggiare le tue macchine e beneficiare delle tariffe de tuoi soci',
    memberPrivate: 'La lista dei membri è nascosta'
  },
  notify: {
    invoicePublished: 'La fattura è stata modificata',
    invoiceNotPublished: 'La fattura non è stata modificata',
    invoiceNotDeleted: 'Non siamo riusciti a cancellare la fattura',
    customEntryDeleted: 'La posizione è stata rimossa',
    customEntryNotDeleted: 'La posizione non è stata rimossa',
    endCounterIsSmallerThanStartCounter: 'Il contatore finale non puô essere piu piccolo del contatore iniziale',
    amountHasToBeHigherThanZero: 'L\'importo non deve essere maggiore di 0',
    toHighAmount: 'L\'importo è troppo grande',
    isLastEndCounter: 'Non è possibile dividere ulteriormente la prenotazione',
    selectUser: 'Scegli una persona ',
    endCounterToHigh: 'La quantità del contatore è troppo alta',
    allreadyBilled: 'La prenotazione è già stata fatturata e non puo piû essere distribuita',
    splittingSuccess: 'La prenotazione è stata distribuita con successo',
    splittingFailed: 'L\'assegnazione della prenotazione è fallita.',
    newMessage: 'Hai nuovi messaggi!',
    messagesNotLoaded: 'I messaggi non possono essere caricati',
    contactDataCouldNotBeLoaded: 'Le coordinate non possono essere caricate',
    newRequestSent: 'La tua nuova richiesta e stata inviata',
    requestDeclined: 'La prenotazione è stata rifiutata',
    selectTimeSpan: 'Devi definire un periodo',
    minimumOneDay: 'Devi definire una data',
    reservationsCouldNotBeLoaded: 'Non siamo riusciti a caricare la prenotazione.',
    requestSuccessfullyConfirmed: 'La prenotazione è stata confermata con successo.',
    reservationWasSucessfullyUpdated: 'Non siamo riusciti a modificare la prenotazione con successo',
    reservationwasNotUpdated: 'Purtroppo non siamo riusciti a modificare la prenotazione',
    reservationDeleted: 'Abbiamo cancellato la prenotazione.',
    counterCanNotBeChangedBecauseOfReservations: 'Il contatore non puo essere modificato a causa di prenotazioni esistenti.',
    billingSuccessfullyPublished: 'Il conteggio è stato confermato.',
    customInvoiceEntrySuccessfullAdded: 'Il conto è stato completato con il tuo articolo.',
    invoiceGenerationFailed: 'Non siamo riusciti a generare la fattura.',
    monthIsNotInPast: 'Solo i mesi prima del mese corrente possono essere selezionati',
    monthHasToBeCoherent: 'I mesi selezionati devono seguirsi l\'un l\'altro.',
    isNotSelectable: 'Questo mese non puo essere selezionato perchè è già stato chiuso',
    yearNotLoaded: 'Non siamo riusciti a caricare i dati dell\'anno desiderato',
    deleteBillingSuccess: 'Abbiamo cancellato il conteggio con successo',
    deleteBillingFail: 'Il conteggio non è stato rimosso Si è verificato un errore.',
    aMonthHasToBeSelected: 'Devi almeno scegliere un mese.',
    hasConflicts: 'E necessario prima risolvere i conflitti del contatore.',
    closeOpenReservationsFirst: 'devi prima chiudere tutte le prenotazioni aperte. Per fare questo,fare clic su una prenotazione.',
    reservationEnd: 'Abbiamo cancellato la prenotazione',
    amountSetSuccessfully: 'La voce è andata a buon fine ',
    amountSetNotSuccessfull: 'Purtroppo non siamo riusciti a chiudere la prenotazione',
    passwordChangeFailed: 'Non siamo riusciti a cambiare la password',
    passwordChanged: 'Abbiamo cambiato la tua password',
    userDataNotLoaded: 'I dati dell\'utente non possono essere modificati',
    userUpdateFailed: 'Non siamo riusciti a salvare le modifiche',
    userUpdated: 'Le modifiche sono state salvate',
    reservationExtendMaxTime: 'La durata della prenotazione della macchina è limitata. Deve essere confermata dal suo fornitore.',
    reservationExtendHasFollowingReservation: 'La macchina è stata prenotata da una persona dopo di te.',
    reservationExtendNeedValidation: 'L\'estensione della prenotazione deve prima essere confermata del suo fornitore.',
    reservationExtendTitle: 'La prenotazione non puo essere estesa',
    unkonwnError: 'Scusate! Si è verificato un errore sconosciuto',
    reservationExtended: 'Abbiamo esteso la prenotazione.',
    upcomingReservationsNotLoaded: 'Non è stato possibile caricare le prossime prenotazioni',
    openReservationsLoadFail: 'Purtroppo non siamo riusciti a caricare le tue prenotazioni aperte',
    unwantedReservations: 'Non siamo riusciti a cancellare una delle tue prenotazioni. Controlla le tue prenotazione.',
    overlappingSlots: 'Hai selezionato periodi già occupati. Azione impossibile',
    teamAdded: 'I membri sono stati aggiunti al tuo elenco di appartenenza',
    teamNotAdded: 'Questa squadra non puô essere aggiunta',
    imageDeleted: 'Abbiamo cancellato la foto',
    imageNotDeleted: 'Non siamo riusciti a cancellare la foto',
    searchNotSuccessfull: 'La tua ricerca non ha prodotto alcun risultato.',
    error: 'Errore: ',
    noTeam: 'Nessun membro del team aggiunto',
    memberDeleted: 'Esclusione del membro del team riuscita',
    memerDeleteFailed: 'Il membro del team non puo essere escluso',
    itemAdded: 'La macchina è stata aggiunta',
    itemNotAdded: 'La macchina non puo essere aggiunta',
    itemUpdated: 'Le modifiche sono state salvate',
    itemNotUpdated: 'La macchina non puo essere aggiornata',
    itemsLoadFail: 'Nessuna macchina potrebbe essere caricata',
    itemLoadFailed: 'Non siamo riusciti a caricare la macchina',
    categoriesLoadFail: 'La categoria non puo essere caricata',
    tooLargeRadius: 'Il raggio di ricerca selezionato è troppo grande',
    startdateGreaterThanEnddate: 'La data iniziale è successiva alla data finale',
    itemDelted: 'La macchina è stata rimossa',
    employeesNotLoaded: 'I dipendenti non possono essere caricati',
    employeeNotDeleted: 'I dipendenti non possono essere cancellati',
    employeeNotAdded: 'Questo collaboratore non puo essere aggiunto',
    imagesNotLoaded: 'Le foto non posssono essere caricate',
    imageNotUploaded: 'Non siamo riusciti a scaricare la foto',
    imageUploaded: 'La foto è stata caricata',
    favoriteAdded: 'Macchina aggiunta ai preferiti',
    failFavoriteAdd: 'Sfortunatamente non è stato possibile salvare la macchina nei preferiti',
    favoriteRemoved: ',Abbiamo sostenuto la macchina dai tuoi preferiti',
    failFavoriteRemove: 'La macchina non puo essere rimossa dai i tuoi preferiti',
    connectedItemsNotLoaded: 'Si è verificato un problema durante il caricamento delle combinazioni di macchine'
  },
  spinner: {
    loadingReservationsOfTheYear: 'Carichiamo l\'anno desiderato',
    getPreviousCounter: 'Stiamo cercando lo stato del contatore precedente per te',
    loadingUserData: 'Profilo utente che viene caricato',
    imageUpload: 'Immagine in fase di download. Un momento per favore.',
    pleaseWait: 'Un momento per favore',
    login: 'Collegamento.',
    dataLoading: 'I dati si stanno caricando',
    search: 'Stiamo cercando per te',
    itemDelete: 'La macchina sarà cancellata',
    deleteEmployee: 'Il collaboratore verrà rimosso dalla tua lista',
    addEmployee: 'Il collaboratore verrà aggiunto alla tua lista',
    addFavorite: 'La macchina verrà aggiunta ai preferiti',
    removeFavorite: 'Rimuoviamo la macchina dai tuoi preferiti',
    deleteImage: 'Cancelliamo la foto.Un momento per favore.'
  },
  multiselect: {
    noResult: 'Scusa! Nessun risultato trovato',
    selectLabel: 'Aggiungere',
    deselectLabel: 'Deselezionare',
    selectedLabel: 'Selezionare'
  },
  pictureInput: {
    drag: 'Clicca qui per caricare una foto <br> o scattare l\'immagine qui',
    plus: '+',
    upload: "Aggiungi una foto",
  },
  v2: {
    dayIsInPast: 'Non puoi prenotare in passato',
    invoiceMarked: 'La fattura è stata contrassegnata come pagata',
    invoiceNotMarked: 'La fattura non puo essere contrassegnata come pagata',
    setPrices: 'Fissare i prezzi',
    assuranceLabel: 'Inserire iltipo di aasicurazione',
    assurance: 'Macchina assicurata?',
    manual: 'Manuale d\'uso',
    itemInformations: 'Maggiori informazioni',
    immediatelyReservation: 'Immediatamente encomiabile?',
    noAssurance: 'Nessuna assicurazione specificata',
    unlimitedMaxReservationTime: 'Tempo di prenotazione illimitato',
    unlimitedMaxReservationDistance: 'Distanza di sicurezza illimitata',
    maxReservationTimeLimitReached: 'La macchina ha un tempo di prenotazione massimo.',
    minimumSelectOneDay: 'Per favore scegli almeno un giorno',
    maxReservationLimitExeeded: 'Superi il tempo massimo di prenotazione.',
    ofTotalAmount: 'In totale {totalAmount} distribuito{unit)',
    splitNow: 'Dividi ora',
    splitReservationModalTitle: 'Dividi la prenotazione?',
    reallySplitReservationModalText: 'Vuole dividere questa prenotazione con altri agricoltori?',
    responsible: 'Responsabile',
    responsibleCouldNotBeLoaded: 'Non è stato possibile caricare la persona responsabile della macchina',
    noItemsResponsibleFor: 'Non possiedi macchine di terze parti di cui sei responsabile.',
    responsibleMenu: 'Responsabile',
    myItemsMenu: 'Le mie macchine',
    itemsMenu: 'Macchine',
    responsibleWidgetHeader: 'Responsabile delle macchine',
    autologinFailed: 'Non siamo riusciti ad accedere automaticamente..',
    baseFee: 'Tariffal base per prenotazione',
    baseFeeShort: 'Tariffal base',
    paid: 'Pagato?',
    counterCoefficient: 'Fattore di conversione',
    counterState: 'Stato del contatore',
    counterStatEquals: 'Lo sato del contatore 1 corrisponde a',
    capture: 'Dichiarazione',
    toClose: 'Chiudere',
    conflicts: 'Conflitti',
    missingAmountTableHeader: 'Importo mancante',
    conflictingReservationsWidgetHeader: 'Conflitti di contatore',
    resetPwTitle: 'Reimpostare la password',
    welcomeOnFarme: 'Benvenuro a Farmx',
    mail: 'E-Mail',
    password: 'Password',
    login: 'Accesso',
    mobileNumber: 'Numero di cellulare',
    rent: 'Affitto',
    lease: 'Locazione',
    clickedDayHasToBeLinked: 'I giorni selezionati devono seguire!',
    timeFor: 'Tempo per il',
    allDay: 'Giornata intera',
    higherTimeNotPossible: 'Hai raggiunto il tempo massimo di prenotazione.',
    minDayTimeLimitReached: 'Non puoi andare sotto questa volta.',
    startReservationOn: 'Inizio della prenotazione il',
    endReservationOn: 'Fine della riservazione il',
    reservationTime: 'Tempi e ore di prenotazione',
    maxReservationTimeExeeded: 'Il periodo di prenotazione massimo è stato superato di...ore!',
    isReservationRequestCorrect: 'Vuoi inviare la nuova proposta di prenotazione?',
    notificationType: 'Notifiche e-mail',
    mailCode: 'Inserisci qui il codice dell\'e-mail',
    setNewPassword: 'Imposta la tua nuova password',
    mailCodeExplain: 'Ti abbiamo inviato una e-mail con ul link di sicurezza.Aprilo per impostare ' +
      'la tua nuova password. Suggerimento: Controllate anche le vostre mail di spam se non avete ricevuto alcun ' +
      'messaggio da noi.',
    pwAlreadyResetted: 'La password é già stata ripristinata.Controlla le tue e-mail.',
    mailOrMobileWrong: 'E-mail o numero di telefono errato',
    loginProblem: 'L\'accesso non ha avuto successo. Assicurati che le tue informazioni siano corrette.',
    editCounterNotPossibleAlreadyBilled: 'Il contatore non puo essere modificato perchè la prenotazione è già stata fatturata.',
    commentHere: 'Scrivi qui il tuo messaggio',
    sendMessageToOwner: 'Inviare un messaggio al fornitore di servizi',
    senderWroteToReservation: 'Ha scritto su questa prenotazione',
    messageToReservation: 'Messaggio del locatario',
    counterUnits: 'Unità contatore ',
    setCounterForOtheruser: 'Stai per inserire l\'utente {firstName}',
    withinRadius: 'Entro un raggio di',
    stayLoggedIn: 'Rimani connesso',
    formNotComplete: 'E necessario compilare tutti i campi contrassegnati con*',
    plzDoesNotExist: 'Il codice postale non esiste.',
    plzNotValid: 'Questo codice postale non è valido.'
  },
  v3: {
    unitsNotLoaded: 'Le unità non possono essere caricate',
    yourFeedback: 'Dare la tua opinione su FarmX',
    feedBackTitle: 'FarmX è in fase di test',
    foundABug: 'Hai trovato un errore? O vuoi inviarci un suggerimento per il miglioramento ?',
    dontHesitate: 'Non esitate a contattarci.',
    thanksForTheSupport: 'Grazie per il vostro supporto!'
  },
  v4: {
    reservationOverview: 'Panoramica delle prenotazioni da parte di terzi',
    actualizeApp: 'Aggiorna l\'app',
    reservationValidation: 'Voglio confermare le richieste di prenotazione',
    confirmedReservation: 'Prenotazione confermata',
    unconfirmedReservation: 'Prenotazione non confermata',
    hint: 'Indicazione',
    hintExplanaition: 'La richiesta di prenotazione non puo essere confermata. Esiste già un\'altra prenotazione per il periodo desiderato.',
    reservationOverviewNotLoaded: 'La panoramica della prenotazione non puo essere caricata.',
    noReservations: 'Nessuna prenotazione',
    showMore: 'Mostra altro',
    setStartCounterModalTitle: 'Inserisci il contatore iniziale',
    startCounterRequired: 'Inserisci il valore del contatore.',
    startCounterCaptured: 'Contatore registrato con successo.',
    extend: 'Estendersi',
    countDownNotPossible: 'Conto alla rovescia non possibile(0.5).',
    countUpNotPossible: 'Non è possibile estendere ulteriormente la prenotazione.',
    hasNextReservation: 'L\'utente ha prenotato la macchina il (data) a (ora).',
    hasMaxTime: 'La macchina puo essera affittata al massimo (ore).',
    extendNotPossible: 'Estensione impossibile',
    close: 'Chiudere',
    moreDetails: 'Altri dati',
    cantonalOperationNumber: 'Licenza d\'esercizio cantonale',
    startCounterOnlyExplanation: 'Inserire il contatore iniziale prima di utilizzare la macchina',
    changeReservation: 'Cambiamento',
    noManual: 'Nessuna istruzioni disponibile',
    noPreviousRenter: 'Nessun precedente locatario',
    noFollowingRenter: 'Nessun prossimo locatario',
    locationNotFound: 'Indirizzo non trovato sulla mappa.',
    editLocation: 'Adattare la posizione sulla mappa ( Funzione in sviluppo)',
    counterDiffer: 'La tua iscrizione differisce dal contatore finale della prenozazione precedente.',
    today: 'Oggi',
    selectStartDate: 'Inserisci la data di inizio',
    selectEndDate: 'Inserisci la data di fine',
    reservations: 'Prenotazioni',
    blockMachine: 'Bloccare la macchina',
    reservationsCouldNotBeenLoaded: 'Le prenozazioni non possono essere caricate.',
    blockedByOwner: 'Bloccato dal proprietario',
    hasReservationsInTheMiddle: 'La tua selezione non puo essere interrotta da altre prenotazioni. Abbiamo adattato la tua selezione.',
    doYouWantBlockTheItem: 'Vuoi bloccare la macchina?',
    blockingTim: 'Periodo di blocco giorni e ore?',
    doYouWantToDeactivateItem: 'Attivare l\'annuncio?',
    activateExplanation: 'E attualmente disabillitato e non puo essere prenotato. Vuoi attivare l\'annuncio?',
    blockTime: 'Bloccare il periodo selezionato',
    deactivateUndefined: 'Disabilita per un periodo indefinito',
    activateItem: 'Attivare',
    itemIsActuallyDeactivated: 'L\'annuncio è attualmente disabilitato.',
    deleteBlocking: 'Smettere di bloccare',
    deleteWholeBlockingPeriod: 'Rimozione del periodo di blocco',
    blockingDeleted: 'Il blocco è stato rimosso'
  },
  v5: {
    menuAdmin: 'Amministratore',
    captureCustomer: 'Prendi l\'utente',
    eMailRequired: 'Per favore inserisci il tuo indirizzo E-Mail',
    isNotEmail: 'Indirizzo E-mail non valido',
    register: 'Registro',
    nameRequired: 'Indica il tuo cognome',
    firstNameRequired: 'Indica il tuo nome',
    mailRequired: 'E-Mail *',
    firstNameFormRequired: 'Nome*',
    nameFormRequired: 'Cognome',
    addressRequired: 'Indica il tuo idirizzo',
    addressFormRequired: 'Indirizzo *',
    zipRequired: 'Indica il tuo codice postale',
    zipFormRequired: 'Codice postale*',
    cityRequired: 'Indica la tua località',
    cityFormRequired: 'Località *',
    pwRequired: 'Indica una password',
    setPassword1: 'Password *',
    setPassword2: 'Ripeti la password*',
    mobileRequired: 'Indica il tuo numero di cellulare.',
    mobileFormRequired: 'Cellulare*',
    telForm: 'Fisso',
    ibanForm: 'Coordinate IBAN ( per la fatturazione)',
    newUserSuccessfullyRegistered: 'L\'utente è stato registrato correttamente. Ora puoi accedere e confermare il tuo numero di cellulare.',
    addNewCustomer: 'Aggiungi un nuovo utente',
    selectLanguage: 'Scegli la lingua *',
    languageRequired: 'Si prega di selezionare la lingua desiderata dall\'utente.',
    registration: 'Non hai ancora un account FarmX? Registrati gratuitamente!',
    resetPwHelpText: 'Password dimenticata? Indica la tua E-mail e il tuo cellulare.Ti invieremo quindi una E-mail per reimpostare la tua password .',
    newPassword: 'Nuova password',
    userAlreadyExist: 'Questo nome utente esiste già!',
    pwTooWeak: 'La sicurezza della password è troppo debole!',
    invalidZip: 'Questo CP non esiste',
    reCaptchaNotValid: 'Mi sembra che tu stia cercando di intrappolare la nostra applicazione.',
    registerSuccess: 'Registrati con successo. Ora puoi accedere.',
    wrongCredentials: 'L\'E-mail o la password non dono corretti',
    userLocked: 'Il tuo account è stato bloccato. Contatta l\'assistenza.',
    mobileVerificationModalTitle: 'Conferma la tua identità',
    mobileVerificationCodeRequired: 'Per favore inserisci il tuo codice SMS.',
    mobileVerificationCodeFormLabel: 'Inserisci il tuo codice SMS',
    validateMobileNumber: 'Controllare',
    wrongMobileToken: 'Codice SMS sbagliato',
    checkMobileToken: 'Si prega di verificare se il codice inserito è corretto',
    addNewItem: 'Aggiungi un nuovo annuncio',
    itemTitle: 'Nome ( ad.esempio John Deere 6410) *',
    itemTitleRequired: 'Aggiungi il titolo dell\'annuncio.',
    itemDescription: 'Descrizione ( ad.es. 110 HP, sollevamento anteriore) *',
    itemDescriptionRequired: 'Si prega di indicare una descrizione per il tuo annuncio.',
    selectLabel: 'Scegli la categoria o le categorie*',
    categoryRequired: 'Seleziona almeno una categoria',
    privateAmount: 'Prezzo per membri (CHF) *',
    privateAmountRequired: 'Indica il prezzo per i tuoi memebri(CHF).',
    globalItemPriceRequired: 'Indica il prezzo per i non membri(CHF)',
    globalItemPrice: 'Prezzo per i non membri (CHF) *',
    unitRequired: 'Seleziona l\'unità',
    selectVat: 'Aliquota IVA*',
    selectVatRequired: 'Selezioni l\'aliquota IVA applicabile',
    location: 'Posizione',
    prices: 'Prezzo',
    options: 'Opzioni',
    conflictsNotLoaded: 'I conflitti non possono essere visualizzati',
    user: 'Utente',
    solveConflict: 'Risolvere',
    conflictInfo: 'Fare clic sui valori del contatore per correggerli. Quindi fare clic su "risoluzione" per risolvere il conflitto conquitatore.',
    countConflicts: '1 conflitto (conteggio) in conflitto',
    reallySolveConflict: 'I metri mostrano una differenza',
    reallySolveConflictText: 'Vuoi sistemare il conflitto nonostante la differenza?',
    enterAmount: 'Si prega di selezionare la tariffal applicabile',
    descriptionRequired: 'Inserisci una descrizione per il testo della fattura',
    selectDate: 'Imposta la data',
    minFee: 'Prezzo massimo per prenotazione(CHF)',
    cancellationPoliciesCouldNotBeenLoaded: 'La politica di cancellazione non puo essere caricata. Riprova piû tardi.',
    vatPriceExplanation: 'I prezzi includono sempre l\'IVA.'
  },
  v6: {
    minFeePerReservation: 'Importo minimo per prenotazione',
    cancellationPolicy: 'Condizioni di cancellazione',
    noMemberTarif: 'Tasso non membro',
    memberTarif: 'Tasso membro',
    minFeeTimeLeft: 'E possibile annullare nuovamente la prenotazione per circa(timeleft).',
    minFeeHasToBePaid: 'La prenotazione non puo essere cancellata. Verrà addebitato un importo minimo di (CHF).',
    minFeeShort: 'Importo minimo',
    cancelledThe: 'Cancellato il',
    totalVat: 'IVA totale',
    from: 'Di',
    customer: 'Cliente',
    vat: 'Aliquota IVA',
    editCustomer: 'Modificare le impostazioni utenete / blocco (inattivo)',
    supporCustomer: 'Supporto',
    editVat: 'IVA',
    items: 'Macchine',
    editUnits: 'Unità',
    titleRequired: 'Indica una formula di saluto ( Signora, Signor)',
    firstNameFormRequired: 'Civiltà (Signora, Signor) *',
    pleaseConfirmMobile: 'Per favore conferma il tuo no.di cellulare',
    farmxConfirmationReason: 'Su FarmX , l\'identità di ciascun utente è verificata. Grazie a questo, FarmX ti protegge dalle persone con intenzioni fraudolente.',
    enterSmsCode: 'Per favore inserisci qui il codice che ti abbiamo inviato via SMS',
    mobileTokenResent: 'Il codice è stato appena inviato di nuovo. Grazie di attendere qualche istante.',
    mobileTockenResendingFailed: 'Il codice non pio essere inviato. Per favore controlla il tuo numero di cellulare',
    resendCode: 'Invia di nuovo il codice SMS',
    mobileCorrect: 'Il no, del cellulare è corretto?',
    testThreeMonthForFree: 'Prova FarmX gratuitamente e senza impegno per 3 mesi.',
    page: 'Pagina',
    overview: 'Sondaggio',
    manageCustomers: 'Gestire i clienti',
    inputRequired: 'Iscrizioni neccessaria',
    searchForUsers: 'Nessuna corrispondenza trovata nel campo di ricerca. Inserisci il nome completo, il cognome completo, il codice postale o la località',
    customerSingular: 'Cliente',
    missingPermission: 'Accesso negato'
  },
  v7: {
    signInAsCustomer: 'Accedi come cliente',
    signInReason: 'Motivo per il login',
    loginReasonRequired: 'Indica un motivo per accedere',
    ticketNumber: 'No. biglietto',
    ticketNumberRequired: 'E necessario inserire un numero di biglietto per accedere',
    tooManyRequests: 'Accesso negato. Hai effettuato l\'accesso troppo spesso con questo utente.',
    loginAsCustomerSuccessfully: 'Connessione riuscita come cliente. Si prega di continuare a modificare in una nuova finestra.',
    copyUrlModalTitle: 'Copia l\'URL',
    urlCopiedSuccessfully: 'L\'URL è stato copiato negli appunti',
    // eslint-disable-next-line
    copyLink: 'Copia l\RL in una nuova finestra del bowser per connetterti.',
    fifteenMinutes: 'Questo link è valido <span class="font-weight-bold">15 minutis</span>.'
  },
  v8: {
    toContinue: 'Per continuare, devi',
    closeWindow: 'Chiudi questa finestra',
    getNewLink: 'Richiedi un nuovo link per questo utente nella scheda admin.',
    securityReasons: 'Questo é necessario per motivi di sicurezza.',
    clickRecaptcha: 'Per favore conferma che non sei un robot.',
    gtcCouldNotBeenLoaded: 'Non è stato possibile caricare i termini. Si prega di riprovare piu tardi.',
    acceptGtc: 'Leggo i termini di vendita di FarmX e li approvo.',
    showAgb: 'Leggi i termini',
    acceptGtcRequired: 'Si prega di accettare i termini di utilizzo di servizio di FarmX.',
    gtcModalTitle: 'FarmX termini',
    agreeGtc: 'Approvare',
    rejectGtc: 'Rifiutare',
    gtcCouldNotBeAccepted: 'Si è verificato un errore durante l\'accettazione dei Termini. Si prega di riprovare piu tardi.',
    welcomeToFarmX: 'Benvenuto',
    setPassword: 'Imposta una password',
    why: 'Perchè',
    sendSmsCode: 'Invia il codice SMS',
    downloadExcel: 'Estrarre i dati in fornato Excel',
    selectRadius: 'Indica il raggio inferiore a 100 km',
    freeTestingTillEndOfJune: 'Utilizza gratuitamente tutte le funzioni di FarmX come ospite non registrato',
    onlyForMembers: 'Noleggio riservato ai membri',
    siteIsLoading: 'Caricamento della pagina',
    passwordHasNumberOrSpecialCharacter: 'La password deve contenere almeno un numero o un carattere speciale.',
    hasSmallAndCapitalLetter: 'La password deve contenere almeno una lettera maiuscola e una lettera minuscola.',
    tabUpcomingRent: 'Orario',
    tabAll: 'Archivio',
    selectDateToGetOverview: 'Scegli un periodo per visualizzare la panoramica delle prenotazione.',
    loginStatus: 'Registrazione dello stato',
    entireWordNeeded: 'inserisci una parola completa (es. Bienne ou Berger).',
    userAlreadyAdded: 'Questo utente è già stato aggiunto alla tua lista',
    userCouldNotBeenLoaded: 'Questo utente non puo essere aggiunto',
    senderHasWrittenNotification: '( il mittente) ti ha inviato un messaggio il ( data) alle (ora)',
    endReservationProcess: 'Chiudere'
  },
  v9: {
    overtake: 'Presa',
    itemReturn: 'Ritorno',
    captureUse: 'Finire l\'uso',
    deviceRegisteredSuccessfully: 'Le notifiche push sono state configurate sulla tua app.',
    deviceCouldNotBeRegistered: 'Si è verificato un errore imprevisto durante il salvataggio della tua app.',
    appNotification: 'Notifiche push (Smartphone)',
    chooseYourPw: 'Per favore imposta la password',
    startTimeMustBeSmallerThanEndTime: 'L\'ora di inizio non puo essere successiva all\'ora di fine',
    endTimeMustBeBiggerThanStartTime: 'L\'ora di fine non puo essere precedente all\'ora di fine',
    earlierNotPossible: 'Non è possibile prima',
    laterNotPossible: 'Non è possibile piu tardi',
    startBlockingPeriodOn: 'Inizio del blocco',
    endBlockingPeriodOn: 'Fine del blocco',
    blockingWasDeleted: 'Blocco annulato',
    overlapingSelection: 'Non è possibile selezionare durante un periodo già prenotato o bloccato. Si prega di cambiare il periodo di prenotazione',
    generalDatabaseError: 'Si è verificato un problema con la database. Si prega di contattare l\'assistenza info-fr@farmx.ch',
    dataTooLongForColumn: 'La voce non è riuscita perchè era troppo lunga. Questo è un problema, si prega di informare il supporto info-fr@farmx.ch',
    userTriesToEditSomeBodeyElsesReservation: 'Stai cercando di modificare una prenotazione che non ti riguarda. Questo è un problema. Si prega di informare  info-fr@farmx.ch',
    userNameDoesNotExist: 'Questo nome utente non esiste. Questo è un problema. Si prega di informare il supporto info-fr@farmx.ch',
    updateFailed: 'Questa prenotazione non puo essere aggiornata. Questo è un problema. Si prega di informare il supporto info-fr@farmx.ch',
    updatedInactiveFieldAsNonAdmin: 'Stai tentando di modificare una prenotazione per una macchina in pensione, é un problema. Si prega di informare il supporto info-fr@farmx.ch',
    timeLimitReached: 'Superi il limite di prenotazione di questa macchina. Si prega di riprovare o si prega di far sapere al supporto info-fr@farmx.ch',
    triedToExtendReservation: 'E apparso un errore tecnico. Si prega di contattare il supporto info-fr@farmx.ch',
    triedToUpdateAlreadyBilledReservation: 'Non è possibile modificare le prenotazioni già chiuse, si prega di contattare il supporto info-fr@farmx.ch',
    validationNeededFirst: 'La prenotazione deve prima essere confermata. Si prega di attendere',
    reservationIsInPast: 'Non è possibile inserire una prenotazione per un tempo trascorso, questo è un problema.Si prega di informare il supporto info-fr@farmx.ch',
    maxDistanceLimitExeeded: 'La macchina supera la distanza massima di prenotazione. Si prega di contattare il proprietario o la persona responsabile della macchina .',
    waitForValidation: 'La prenotazione deve essere ancora approvata dal proprietario/gestore della macchina. Riceverai una risposta entro 24 ore.',
    tokenExpiredSentMail: 'Il link non è piu valido, per motivi di sicurezza, ti abbiamo inviato un link al tuo indirizzo e-mail.',
    tokenExpired: 'Il collegamento è finito.'
  },
  v10: {
    bookNow: 'Prenota ora',
    appStatisticsCouldNotBeLoaded: 'Non è stato possibile caricare le statistiche delle applicazioni.',
    appStatistics: 'Statistiche dell’applicazione',
    searchItems: 'Cercare una macchina',
    noInnercircleItems: 'Non sei membro di alcuna comunità di farm o macchine. Nessuna macchina disponibile.',
    noFavoriteItems: 'Nessuna macchina definita finora nei tuoi preferiti.',
    addFirstItem: 'Pubblica la tua prima macchina su Farmx in pochi clic.',
    captureNow: 'Cogliere',
    noAdvancedSearchResults: 'La tua ricerca non ha prodotto risultati.',
    helpToImproveFarmX: 'Preferiresti usare meglio una delle tue macchine?',
    onlineNow: 'Pubblica ora',
    useRequired: 'Si prega di inserire l’utilizzo',
    completeForm: 'Grazie riempire tutti i campi',
    itWas: 'Contatore iniziale',
    reservationEditNotPossible: 'Prenotazione annullata gratuitamente.Modifiche non possibili.',
    counterEnd: 'Contatore finale',
    use: 'Uso',
    defineLocation: 'Imposta la posizione',
    searchForAddress: 'Cerca per indirizzo',
    addressNotFoundSelectManually: 'Questo indirizzo non è stato trovato,. Clicca sulla mappa per definire la posizione.',
    setUserLocation: 'Imposta la posizione',
    itemTooFarAway: 'La posizione della macchina è troppo lontana.',
    all: 'Tutte le categorie',
    away: 'Sulla distanza',
    occupiedTo: 'Già occupato al {percent}%',
    moreSearchFields: 'Piu criteri',
    yourMailContainsAWhiteSpace: 'La tua email include uno spazio vuoto, non valido.',
    coordinatesRequired: 'Posizione non trovata',
    newAppVersionAvailable: 'Nuova versione dell’app disponibile',
    actualizeAppNow: 'E disponibile una nuova  versione dell’app. Aggiorna ora.',
    versionNumber: 'Nuova versione dell’app',
    deadApiModalTitle: 'I nostri server sono attualmente fortemente sollecitati.',
    weSolveTheProblem: 'Ti chiediamo di aspettare. Ci stiamo sforzando di rimediare a questo problema. Per favore riprova tra 5 minuti.',
    gotThat: 'Compresi'
  },
  systemNotification: {
    checkItemsTitle: 'Controlla la posizione delle tue macchine',
    checkItemsText: 'FarmX ha introdotto un nuovo sistema di coordinate per indicare con precisione la posizione delle vostre macchine.Si prega di controllare la posizione di ciascuna delle vostre macchine'
  },
  v11: {
    userBilling: 'Fatturazione esportazione',
    downloadWinbizCsv: 'CVS per Winbiz',
    debit: 'Numero di conto debitori',
    credit: 'Conto',
    debitRequired: 'Indicare il numero del conto debitori',
    creditRequired: 'Indicare il numero del conto',
    howToSelectWinBizDates: 'Indica sempre un anno intero .Ad esempio dal 01.01.2019 al 01.01.2020',
    invoiceDateRequired: 'Indicare la data di fatturazione',
    invoiceDate: 'Data di fatturazione',
    allReservations: 'Tutte le prenotazione',
    app: 'App',
    technicalInformations: 'Informazioni tecniche',
    appInformations: 'Informazioni sull’app',
    userId: 'ID utente',
    appVersion: 'Versione FarmX (Fronte e Retro)',
    nativeAppDeviceId: 'ID dispositivo ( Android IOS)',
    hasDevice: 'App identificata ( Android  IOS) ?',
    allRegisteredDeviceIds: 'ID dsipositivo registrato (API)',
    isDeviceIdFoundInRegisteredIds: 'E stato trovato il Device ID',
    allCookies: 'Tutti i cookie',
    lastStep: 'Quasi  finito',
    acceptConditions: 'Per pubblicare la macchina, si prega di accettare le seguenti condizioni',
    generalFees: 'La pubblicazione di quest a macchina è gratuita purchè non sia stata affittata dal 1 luglio 2019. La pubblicazione sarà fatturata <span class="font-weight-bold"> {costsPerItem} chf per ogni macchina nolegggiata almeno una volta all’anno.</span>',
    weSendABill: 'Per il momento non c’é bisogno di preoccuparsi per il pagamento. La fattura delle tue pubblicazioni per le macchine che hai affittato ti raggiungerà se necessario alla fine dell’anno.',
    benefitFromDiscounts: 'Godere di sconti sulla quantità',
    tillFiveItems: 'Fino a 5 macchine: {costsPerItem} chf / macchine e all’anno',
    moreThanFiveItems: 'Piu di 5 macchine: {discountPerItem} % sconto/ macchina e a l’anno',
    moreThanFiftyItems: 'Piu macchine {maxDiscount} macchine: {maxDiscount} % ribasso/ macchina e per anno',
    gtcApplyByTheWay: 'Le condizioni generali di utilizzo si applicano in tutti i casi.',
    counterCoefficientRequired: 'Per favore inserisci il fattore di conversione',
    publishItemNow: 'Pubblica ora la macchina',
  },
  v12: {
    myFarm: 'La mia fattoria',
    editAddress: 'Modificare l’indirizzo',
    onMyInvoices: 'Fatture',
    paymentWithin: 'Pagabile in',
    days: 'giorni',
    daysMandatory: 'Per favore inserisci un termine di pagamento',
    tenDaysMin: 'Il periodo di pagamento deve essere almeno di 10 giorni',
    isCancelled: 'Annullato',
    captureUse: 'Prendi l’uso',
    splitReservation: 'Reinvestire l’utilizzo a terzi',
    isCaptured: 'Inserito con successo',
    workingHours: 'Ore di lavoro',
    hourlyWage: 'Paga oraria',
    remove: 'Rimuovere',
    myStandardHourlyWage: 'Paga oraria',
    selectAddressee: 'Seleziona un cliente',
    createInvoices: 'Generare fatture',
    invoicesNotLoaded: 'Purtroppo non è stato possibile caricare le fatture.',
    invoiceCouldNotBeCreated: 'La fattura non puo essere modificata',
    vatForItems: 'IVA per macchine *',
    vatForWork: 'IVA per lavoro *',
    showVatSection: 'Aggiungere l’IVA',
    workingHoursWith: 'Orario di lavoro con',
    splitMarkFailed: 'La prenotazione non puo essere contrassegnata come distribuita',
    invoicesSuccessfullyGenerated: 'Le fatture sono state generate con successo.',
    alreadySplit: 'Già andato',
    splitAnyway: 'Ristribuire di nuovo',
    alreadySplitInformation: 'Hai già distribuito questa prenotazione una volta.',
    howDoYouLikeToContinue: 'Come vorresti continuare?',
    invoicesPayableWithin: 'Fattura pagabile in',
    downloadAllUsers: 'Tutto',
    menu: 'Menu',
    logout: 'Disconnect',
    loggingYouOut: 'Ti disconnettiamo...',
    phoneOnlyFees: 'Gli annunci di macchine che possono essere affittate solo telefonicamente saranno fatturati da FarmX come se fossero state affittate almeno una volta.',
    taxId: 'Partita IVA'
  },
  v13: {
    discounts: 'Abbuono di quantità',
    discount: 'Abbuono di quantità',
    createDiscount: 'Introdurre uno sconto sulla quantità',
    discountsLoadFailed: 'Le sconto sulla quantità non potevano essere visualizzate',
    discountLoadFailed: 'Le sconto sulla quantità non potevano essere visualizzate',
    description: 'Descrizione',
    targetDate: 'Data di riferimento (ripetizione  annuale)',
    duration: 'Durata',
    unit: 'Unità',
    machines: 'Macchine',
    discountLevels: 'Ingradimento',
    edit: 'Modificare',
    newDiscount: 'Stabilire uno sconto sulla quantità',
    updateFailed: 'Errore di aggiornamento',
    addingFailed: 'Aggiunta fallita',
    addDiscountLevel: 'Introdurre condizioni preferenziali',
    discountForm: {
      descriptionEmptyError: 'La descrizione deve contenere almeno 3 caratteri',
      descriptionLabel: 'Dimmi il nome dello sconto',
      machinesLabel: 'Quali macchine devono essere collegate a questo sconto?',
      levelStart: 'da',
      levelEnd: 'a',
      levelPrice: 'Riduzione',
      save: 'Registrazione',
      others: 'Altri',
      discountAdded: 'Scala stabilita',
      askForDeletion: 'Volete davvero rimuovere questa scala ?',
      deletionAcceptance: 'Cancellare',
      abort: 'Annulla',
      accept: 'Cancellare',
      dateNotSet: 'Manca la data di riferimento',
      mustSetDate: 'Inserire una data di riferimento',
      noMachineSelected: 'Nessuna macchina selezionata',
      mustSelectMachine: 'E necessario selezionare almeno una macchina',
      negativeValuesNotAllowed: 'I valori negativi non sono accettati',
      discountGreaterThan: 'Deve essere superiore a 0',
      UpperMustBeGreaterThanLower: 'Deve essere superiore al valore iniziale',
      mustEqualToProceeding: 'Deve corrispondere al precedente valore finale',
      required: 'Richiesto',
    },
    maintenance: 'Manutenzione',
    maintenanceLowerThreshold: 'Stato arancione da',
    maintenanceLowerThresholdRequired: 'Il valore rosso deve essere superiore al valore arancione',
    maintenanceUpperThresholdRequired: 'Il valore arancione deve essere superiore al valore rosso',
    maintenanceUpperThreshold: 'Stato rosso da',
    resetMaintenance: 'Resettare la modalità di manutenzione',
    resetMaintenanceBtn: 'Reset',
    resetMaintenanceDesc: 'Resettare la modalità di manutenzione. Scegliere la data dell’ultima manutenzione eseguita.',
    trafficLightLoadFailed: 'Non è stato possibile visualizzare gli indicatori',
    lastMaintenanceDate: 'Ultima manutenzione',
    month: 'Mese',
    itemToDiscount: 'Selezionato',
    responsibleCompensation: 'Idennità di stazionamento',
    startDate: 'Data di inizio',
    endDate: 'Data di fine',
    reservationRejected: 'La prenotazione è stata rifiutata. Modifiche non possibili.',
    rejectedReservation: 'Rifiutato',
    chfPerUnit: 'CHF/ Unità',
    customFieldsWereNotUpdated: 'I camoi nin sono stati salvati.',
    customFieldsWereNotCreated: 'I campi non sono stati creati.',
    withDiscount: 'Tariffe scaglionate',
    discountFetchingError: 'Si è verificato un errore quando si è verificato  durante le tariffe scaglionate',
    youMayGetDiscount: 'Se noleggiate questa macchina, vi sarà concessa una tariffal preferenziale per un certo uso.',
    errorWhileSignalFetching: 'Si è verificato un errore durante la richiesta.',
    downloadExcel: 'Scarica il file Excel',
    downloadCompensationExcel: 'Scarica le tariffe di parcheggio',
    timeSpan: 'Periodo',
    compensationPerUnit: 'Unità/ Indenizzo',
    totalCompensation: 'Totale pagamenti compensativi',
    sendInvoicesManually: 'Non inviare le fatture via e-mail.',
    sendInvoicesAutomatically: 'Invio automatico delle fatture una volta confermate(consigliato).',
    export: 'Esportazione',
    noBillings: 'Nessun insediamento confermato',
    noCustomInvoices: 'Nessuna singola fattura confermata',
    noIncomingInvoices: 'Non avete ricevuto alcuna fattura in questo momento',
    invoiceId: 'Numero di fattura',
    invoices: 'Fatture',
    rejectedThe: 'Rifiutato',
    state: 'Stato',
    send: 'Inviare',
    reallySend: 'Vuoi davvero inviare le fatture via e-mail a tutti i destinatari ? <b>Questo non puo`essere cancellato!</b>',
    sendInvoiceViaMail: 'Inviare le fatture via e-mail',
    errorOnCalculatingDiscounts: 'Si è verificato un errore nel calcolo del tasso decrescente!',
    alreadyBilled: 'Utilizzazione fini ad ora',
    useForBillingPeriode: 'Uso attuale',
    totalUseInBillingPeriode: 'Utilizzazione totale',
    subTotal: 'Totale parziale',
    showPrices: 'Mostra i prezzi',
    contact: 'Informazioni di contatto',
    itemLocation: 'Posizione della macchina',
    awayIn: 'percorrenza',
    mailConfirmationRequired: 'Inserisci nuovamente il tuo indirizzo e-mail.',
    mailConfirmation: 'La tua e-mail non corrisponde, controlla la voce.',
    allInputsAreRequired: '* Tutti i dati con una stella(*) sono obbligatori.',
    customId: 'Numero interno della macchina(non sarà  pubblicato)',
    internalNumber: 'Numero interno',
    autoAuthentication: 'Autentificazione in corso…',
    lastResetDate: 'Ultimo azzeramento'
  },

  invitation: {
    shareFarmX: 'Condividi FarmX',
    inviteMember: 'Invitare i membri',
    linkCopied: 'Collegamento copiato. Ora è possibile condividerlo tramite Whatsapp o i social network.',
    copyLink: 'Copiare il link',
    multipleTimesValid: 'Valido piu volte',
    singleValid: 'Valido solo una volta',
    code: 'Codice',
    generateLink: 'Generare il link',
    onlyCharAndNumbersAllowed: 'Es sind nur Zahlen und Buchstaben erlaubt.',
    min4Chars: 'Il codice deve essere lungo almeno 4 caratteri.',
    link: 'Link al sito Web',
    expireDate: 'Data di scadenza',
    codeTooShort: 'Il codice inserito è troppo breve.Deve essere lungo almeno 4 caratteri.',
    unsupportedChars: 'Il codice contiene un carttere errato. Si accettano solo numeri e lettere. Es sind nur Zahlen und Buchstaben erlaubt.',
    unknownMode: 'Errore tecnico : modo sconosciuto.',
    codeAlreadyUsed: 'Il codice inserito è già stato utilizzato nel sistema. Si prega di riprovare con un nuovo codice.',
    codeSuccessfullyGenerated: 'Il link èstato generato con successo. Ora è possibile copiarlo e condividerlo.',
    deleteLink: 'Abbiamo davvero bisogno di cancellare questo link ? Questo non puo essere cancellato.',
    linkDeleted: 'Il link è stato sostituito.',
    overwriteLinkQuestion: 'Sostituire il link esistente ?',
    overwriteLink: 'Sostituire',
    linkWillBeOverwritten: 'Il seguente link sarà sovrascritto e sostituito. Vuoi continuare?',
    linkToBeOverwritten: 'Questo link è stato sostituito',
    welcomeToFarmX: 'Benvenuti a FarmX!',
    invitedBy: 'Sei stato invitato da  {invitor}. Scegliere la procedura da seguire.',
    invitedByAnRegisterNow: 'Sei stato invitato a FarmX da {invitor}. Registrati gratis ora.',
    invitationReceived: 'Hai un codice invito ?',
    codeInvalid: 'Il codice inserito non è valido.',
    alreadyLogin: 'Ho già un conto.',
    newToFarmX: 'Sono nuovo qui. Registrati.',
    addedAsMember: 'Sei stato aggiunto con successo come membro di {invitor}.',
    copyCode: 'Copia il codice',
    codeCopied: 'Copia il codice. Ora è possibile inoltrarlo a qualcuno che non ha ancora un conto FarmX.',
    invitePeopleToFarmx: 'Invitare le persone a FarmX',
    createLinkTitle: 'Generare un link'
  },
  dataTable: {
    noDataAvailable: 'Dati non inseriti'
  },
  info: {
    incomingInvoices: 'In questa pagina troverete tutte le fatture ricevute da altri utenti FarmX. Solo il mittente puo indicare la fattura pagata <br><br>' +
      'Clicca su una fattura per scariucarla in formato PDF o copiare il link al PDF',
    simpleLink: 'Copiare il link «FarmX teilen». Inviare il link copiato a clienti, colleghi o parenti  per invitarli a FarmX.' +
      'In questo modo è possibile registrarsi facilmente su FarmX.',
    memberInvitation: 'Modificare un link nel sito «Invita Membri» per consentire alla di diventare automaticamente membro. Per questo ha ' +
      'le seguenti possibiltà di scelta: <br><br> <span class="">Code:</span> Se si lascia vuoto questo campo, il sistema emette automaticamente un codice casuale. E inoltre possibile' +
      ' inserisci  un codice personalizzato che ti verrà inviato tramite link. <br><br> <span class="">Valido una volta:</span> Con questa scelta, il link generato puo essere utilizzato una sola volta' +
      ' Questo limita l’invito  a una sola persona. <br><br> <span class="">Valido più volte: </span> Il link puo  essere utilizzato piu volte e servire piu persone.'
  },
  state: {
    confirmed: 'Confermato',
    draft: 'Bozza',
    sent: 'Inviato'
  },
  registrationForm: {
    'enterCode': 'Inserire il codice'
  },
  sMap: {
    searchItemText: 'Ricerca per macchina',
    checkCoordinates: 'Verificare le coordinate'
  },
  itemMap: {
    zipNotFound: 'Codice postale non trovato.',
    priceOnRequest: 'Prezzo su richiesta',
    selectCategory: 'Categoria'
  },
  navigationBar: {
    home: 'Home',
    about: 'Info'
  },
  pwReset: {
    pwReset: 'Reimpostare la password'
  },
  userLocationModal: {
    longitude: 'Longitudine',
    latitude: 'Latitudine'
  },
  mySettings: {
    changeMail: 'Cambia e-mail',
    newMail: 'Nuova e-mail',
    changePersonalData: "Cambia Dati"
  },
  v14: {
    archiveRentState: 'Stato',
    cancelled: 'Annullato'
  },
  v15: {
    canEditMachine: 'Il responsabile puo\' modificare le opzioni della macchina',
    employerHasNoMachine: 'L\' utente selezionato non ha ancora pubblicato una macchina',
    usageNotRecorded: 'Utilizzo non registrato',
    replaceList: 'Mostra solo la selezione',
    resetFilters: 'Resetta i filtri',
    filterByMachine: 'Filtra per macchina',
    activeFilters: 'Filtri attivi',
    reportDamage: 'Riporta danno preesistente',
    isReportingDamage: 'Comunicazione di danno preesistente',
    sendImage: 'Allega una foto',
    messageRequired: 'Il messaggio non puo\' essere vuoto',
    damageProofIsRequired: 'Allegare una foto a prova del danno',
    message: 'Messaggio',
    messageSubject: 'Oggetto',
    messageText: 'Contenuto del messaggio',
    attachedImage: 'Immagine in allegato',
    messageNotSuccessful: 'Si è verificato un problema durante l\' invio del messaggio',
    messageSuccessful: 'Messaggio inviato',
    commentCannotBeEmpty: 'Digitate un messaggio o inviate una foto',
    tooLateForMessage: 'Non è possibile inviare messaggi per prenotazioni chiuse da oltre 4 giorni',
    membersValidation: 'Conferma necessaria anche per i miei membri',
    showPrivateMachines: 'Mostra anche le macchine private',
    filterDamageReports: 'Solo le macchine danneggiate',
    showDamageReport: 'Mostra il messaggio riguardante il danno',
    searchByMachineOrId: 'Cerca macchina per nome o ID interno',
    customizeMenu: 'Personalizza menu',
    uploadImage: 'Invia immagini',
    noCommentsTitle: 'Nessun Commento',
    noCommentsSubTitle: 'Al momento non ci sono commenti su questa prenotazione',
    comments: 'Messaggi'
  },
  archiveExport: {
    renterIsMember: 'Membro',
    noData: 'Dati non disponibili'
  },
  socialMediaLinks: {
    subject: 'FarmX',
    body: 'Guarda questa macchina',
  },
  itemPublicDetails: {
    siteTitle: 'FarmX - Semplicemente affittare',
    description: 'Ottimizza i costi dei tuoi macchinari con FarmX: noleggia, riserva, pianifica, fattura.'
  },
  itemCreateView: {
    switchToAdvanced: 'Più impostazioni',
    switchToEasy: 'Meno impostazioni'
  },
  imageUploader: {
    mainImageMissing: 'Nessuna immagine principale disponibile. Carica ora.',
    noFileAvailable: 'Nessun file selezionato.',
    fileTypeNotSupported: 'Tipo di file non supportato. Carica un file immagine.',
    fileReaderApiNotSupported: 'Si è verificato un errore inaspettato: FileReader API non supportato. Si prega di contattare il supporto FarmX.',
    mainImage: 'Immagine principale',
    replaceImage: 'Carica un\'altra immagine',
    disclosureTitle: 'Divulgazione promettente - Divulgazione promettente',
    disclosureIntroduction: 'FarmX raccoglie/trasmette/sincronizza/memorizza le immagini al fine di',
    disclosureItemImages: 'presentare le vostre macchine agli affittuari in modo utile e attraente. In questo modo i locatari hanno una buona impressione della macchina che vogliono noleggiare.',
    disclosureUserImage: 'Mostrate il logo della vostra azienda sul vostro profilo agli altri utenti di FarmX e marchiate le vostre macchine con esso.',
    disclosureGtcLink: 'Privacy Policy e Terms & Conditions di FarmX',
    disclosureMoreInformation: 'Ulteriori informazioni su Google Prominent Disclosure'
  },
  itemNotReservable: {
    notReservable: 'Sfortunatamente, la macchina richiesta non può essere riservata. Motivo:',
    hint: 'Usa la ricerca avanzata per trovare un\'unità adatta.',
    itemInactive: 'La macchina è stata disattivata dal proprietario.',
    distance: 'La macchina è troppo lontana da voi. Il proprietario ha limitato la distanza massima di affitto.'
  },
  userEditInvoiceModal: {
    invalidIban: 'IBAN non valido'
  },
  captureIbanModal: {
    title: 'Inserisci il tuo IBAN',
    enterIban: 'Inserisci l\'IBAN del tuo conto',
    explanationText: 'Affinché possiamo generare fatture valide, ti preghiamo di inserire l\'IBAN del tuo conto.'
  },
  openReservations: {
    noReservations: 'Perfetto,  non hai una prenotazione da chiudere.'
  },
  itemsServices: {
    variantCreationFailed: 'Non è stato possibile creare la variante della macchina.'
  },
  itemGridEditable: {
    okClone: 'Crea variante',
    cloneVariantTitle: 'Creare una variante?',
    cloneVariantText: 'Volete affittare la macchina attuale come variante? Questo è utile, per esempio, se si vuole ' +
      'affittare una stessa macchina con condizioni diverse. Assicurati di adattare il titolo e la descrizione in ' +
      'modo significativo affinché i tuoi inquilini sappiano quale variante della macchina devono prenotare.',
    variant: 'Variante',
    cloneNotPossibleTitle: 'Creare la variante non è possibile',
    cloneNotPossibleText: 'Questa macchina ha un contatore. Pertanto, non è possibile creare alcuna variante di macchina, poiché questo porterebbe a dei conflitti contrari.'
  },
  itemEditableActions: {
    editItem: 'Modifica macchina',
    blockItem: 'Blocca o disattiva la macchina',
    createVariant: 'Creare una variante della macchina',
    deleteItem: 'Elimina la macchina'
  },
  testimonialsServices: {
    getTestimonialsFailed: 'Le testimonianze non possono essere caricate.'
  },
  testimonialsSlider: {
    rentNow: 'Noleggia una macchina ora',
    doNotShowUpAgain: 'Non mostrare altro'
  },
  translations: {
    missingTranslation: 'Traduzione assente',
    missingTranslationShort: 'T.A.',
    missingTranslationDescription: 'Traduzione non trovata nel database.'
  },
  discount: {
    targetDateMissing: 'Errore inatteso: manca la data limite (Discount.loadDiscountReservations())!'
  },
  selectMonth: {
    dataNotLoaded: 'I dati non sono stati caricati completamente. Si prega di riprovare.',
    overlappingTargetDate: 'Il periodo di fatturazione non può sovrapporsi alla data limite di qualsiasi scala tariffaria.'
  },
  appInformation: {
    localStorage: 'Stoccaggio locale'
  },
  membersService: {
    newMemberRequestFailed: 'Errore: non è stato possibile richiedere l\'iscrizione di una persona.',
    couldNotDeleteMember: 'Non è stato possibile eliminare il membro.'
  },
  members: {
    memberExplanation: 'Persone o organizzazioni che sono membri della mia organizzazione.',
    members: 'Membri',
    requests: 'Richieste in sospeso',
    customNotificationTitle: 'Messaggi push ai miei membri',
    createCustomNotification: 'Scrivere un nuovo messaggio.'
  },
  memberships: {
    memberships: 'Associazioni',
    explanation: 'Persone o organizzazioni di cui sono membro.',
    requests: 'Richieste aperte'
  },
  membershipRequests: {
    acceptMembershipModalTitle: 'Vuoi diventare un membro?',
    acceptTextI: 'Se diventate membri di {lessor}, potrete beneficiare di condizioni di noleggio migliori. Inoltre, ' +
      'le macchine di {lessor} vi verranno mostrate direttamente al momento della prenotazione.',
    acceptTextII: 'Potete concedere {lessor} il diritto di inviarvi circolari come messaggi push. Potete revocare questo diritto in qualsiasi momento.',
    allowNotifications: '{lessor} può inviarmi messaggi push.',
    denyNotifications: '{lessor} non è autorizzato a inviarmi messaggi push.',
    denyModelTitle: 'Rifiutare davvero l\'iscrizione?',
    denyModalText: 'In un\'associazione si può solitamente beneficiare di condizioni di affitto più favorevoli. Volete comunque rifiutare la richiesta?',
    denyModalOkText: 'Declino dell\'adesione',
    action: 'Azioni',
    missingCurrentMembershipRequest: 'Errore imprevisto: Missing current membership request'
  },
  memberList: {
    member: 'Nome, luogo',
    receivesCustomNotifications: 'Riceve i miei messaggi',
    doesntReceiveCustomNotifications: 'Non riceve messaggi',
    deleteMember: 'Rimuovere il membro',
  },
  membershipsService: {
    cancelledMembership: 'L\'iscrizione è stata terminata con successo.',
    saved: 'Modifica salvata',
    acceptedMembershipRequest: 'Ora sei un membro.',
    deniedMembershipRequest: 'Avete rifiutato l\'adesione.'
  },
  membershipList: {
    receiveNotifications: 'Notifiche consentite',
    bannedNotifications: 'Notifiche non consentite',
    cancelMembershipBtn: 'Cessazione dell\'adesione',
    cancelMembershipTitle: 'Interrompere l\'adesione?',
    cancelMembershipText: 'Se si interrompe l\'iscrizione, è possibile che non sia più possibile noleggiare macchine da questa società di noleggio o che le tariffe cambino.'
  },
  invitationAnswerModal: {
    mayLessorSendYouMessages: 'Consentire i messaggi di {lessor}?',
    acceptInvitation: 'Consentire',
    cancelInvitation: 'Non consentire'
  },
  customNotificationService: {
    couldNotCreate: 'Il messaggio non può essere creato',
    couldNotRetrieve: 'Non è stato possibile caricare il messaggio',
    couldNotRetrieveNotifications: 'Non è stato possibile recuperare il messaggio',
    created: 'Il messaggio è stato salvato',
    saved: 'Salvato',
    couldNotSave: 'Impossibile salvare',
    testSent: 'Messaggio di prova inviato al dispositivo',
    couldNotSendTest: 'Non è stato possibile inviare il messaggio di prova',
    couldNotSend: "Non è stato possibile inviare il messaggio",
    sent: 'Messaggio inviato con successo'
  },
  customNotificationForm: {
    createCardTitle: 'Nuovo messaggio ai miei membri',
    updateCardTitle: 'Modifica messaggio',
    missingBodyText: 'Comporre il messaggio',
    cancel: 'Annulla',
    createNew: 'Salva',
    selectLanguage: 'Seleziona la lingua',
    selectLanguageRules: "È necessario selezionare una lingua",
    pushTitle: 'Spingi il titolo',
    pushTitleRules: "È necessario inserire un titolo per il messaggio push",
    notificationTitle: "Titolo del messaggio",
    notificationTitleRules: "È necessario inserire un titolo per il messaggio",
    save: 'Salva',
    sendTest: 'invio di prova',
    send: "Invia",
    sendBtnTooltip: 'Invia il messaggio ai miei membri',
    sendTestTooltip: 'Prova a inviare il messaggio al mio dispositivo (i membri non riceveranno il messaggio)',
    logTitle: 'Registro',
    testSendDateLog: 'Ultimo invio di prova',
    sendDateLog: 'Invio',
    createdAtLog: 'Creato il',
    updatedAtLog: 'Ultima modifica il',
    noTestSend: 'Nessun invio di prova',
    noSend: 'Nessun invio',
    sendResponseTitle: 'Invio riuscito',
    sentToUserWithLanguage: 'Il messaggio push è stato inviato ai membri che hanno selezionato la lingua "{language}" nel loro profilo Farmx',
    pushDeviceCount: 'Dispositivi',
    userCount: 'Membri',
    sendTestFirst: 'Richiesto invio di prova',
    sendOkText: 'Invia',
    beforeSendModalTitle: 'Inviare un messaggio push adesso?',
    beforeSendModalText: 'Tutti i membri con la lingua "{language}" riceveranno un messaggio push da voi. Vuoi inviare il messaggio ora?',
    customNotificationTestSent: 'Il messaggio push è stato inviato allo smartphone. | Il messaggio push è stato inviato allo smartphone.'
  },
  customNotificationList: {
    sendDate: 'Inviato in data {sendDate}',
      notSent: 'Non inviato'
  },
  notificationService: {
    notificationPatchFailed: 'Il messaggio non può essere contrassegnato come (non) letto',
      couldNotRetrieveNotification: 'Non è stato possibile caricare il messaggio'
  },
  customNotificationView: {
    sender: 'Mittente del messaggio',
    sendDate: "Data di invio del messaggio"
  },
  editorLinkBtn: {
    noLinkSet: 'Inserire un link con https://',
    addLink: 'Aggiungi link'
  }
}
