import Translations from '../Translations'
import Translation from '../Translation'

export default class Category {
  constructor (category = null) {
    // handle null categories
    if (!category) return this
    this.id = category.hasOwnProperty('id') ? category.id : null

    // Type: Translations
    this.name = category.name ? category.name : new Translations()

    // parse old api hardcoded translation style
    if (category.CategorieDe || category.CategorieFr || category.CategorieIt) this.parseApiCategoryTranslations(category)
  }

  // init the translations for the category name
  parseApiCategoryTranslations (category) {
    this.name = new Translations()

    // add german translation
    /* global store */
    if (category.CategorieDe) {
      this.name.addTranslation(new Translation(category.CategorieDe, category.CategorieDe, category.CategorieDe, store.state.languageObject.getLanguageByKey('de_CH')))
    }

    // add french translation
    /* global store */
    if (category.CategorieFr) {
      this.name.addTranslation(new Translation(category.CategorieFr, category.CategorieFr, category.CategorieFr, store.state.languageObject.getLanguageByKey('fr_CH')))
    }

    // add italian translation
    /* global store */
    if (category.CategorieIt) {
      this.name.addTranslation(new Translation(category.CategorieIt, category.CategorieIt, category.CategorieIt, store.state.languageObject.getLanguageByKey('it_CH')))
    }
  }

  getName () {
    return this.name.getCurrentTranslation()
  }
}
