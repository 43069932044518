export default class BlockPeriode {
  constructor (blockPeriode = {}) {
    this.id = blockPeriode.hasOwnProperty('id') ? blockPeriode.id : null
    this.startDate = blockPeriode.startdate ? blockPeriode.startdate : null
    this.endDate = blockPeriode.enddate ? blockPeriode.enddate : null
    this.blockedByBlockingId = blockPeriode.blockedByBlocking || null
    this.blockedByReservationId = blockPeriode.blockedByReservation || null
  }

  // calc blocking time in seconds
  getBlockTime () {
    return this.endDate - this.startDate
  }

  getOccupation (start, end) {
    start = this.startDate > start ? this.startDate : start
    end = end > this.endDate ? this.endDate : end

    return end - start
  }
}
