<template>
  <div class="s-footer print-menu">
    <!-- show system notifications if any -->
    <system-notifications @hideHill="showHill = false"/>

    <!-- footer container -->
    <div class="blocker" v-if="isEditingMenu"></div>
    <v-layout class="wrap align-center user-footer hidden-md-and-up" v-if="$store.getters.isUserLoggedIn()">
      <!-- change user -->
      <v-flex class="xs8 text-left pl-6">
        <user-nav/>
      </v-flex>

      <!-- burger menu -->
      <v-flex class="xs4 text-center">
        <burger-menu/>
      </v-flex>
    </v-layout>

    <!-- feedback section -->
    <feedback-button v-if="showFeedback"/>
  </div>
</template>

<script>
import FeedbackButton from '../../feedback/FeedbackButton'
import UserNav from '../UserNav'
import BurgerMenu from '../BurgerMenu'
import SystemNotifications from '../../notifications/SystemNotifications'

export default {
  name: 's-footer',

  components: {SystemNotifications, BurgerMenu, UserNav, FeedbackButton},

  data () {
    return {
      // DEPRECATED
      showHill: true,
      isEditingMenu: false
    }
  },

  props: {
    showFeedback: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted () {
    /*global EventBus*/
    EventBus.$on('editMenu', () => {
      this.isEditingMenu = true
    })
    EventBus.$on('finishEditMenu', () => {
      this.isEditingMenu = false
    })
  },
  methods: {
    // hide hill on clicking it for some time to help userÇ
    // DEPRECATED !! CAN BE DELETED
    hideHill () {
      this.showHill = false

      // wait for 15 seconds
      setTimeout(() => {
        this.showHill = true
      }, 15000)
    }
  }
}
</script>

<style lang="scss">
.s-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}

.user-footer {
  background-color: white;
  border-top: 2px solid var(--v-primary-base);
  height: $s-footer-mobile;
}

.blocker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(33, 33, 33, 0.20);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  z-index: 3;
}
</style>
