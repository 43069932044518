export default class Language {
  constructor (language = null, key = null, iso = null, winbiz = null) {
    this.language = language
    this.key = key
    this.iso = iso
    this.winBiz = winbiz
  }

  getLanguage () {
    return this.language
  }

  getLanguageKey () {
    return this.key
  }

  getLanguageIso () {
    return this.iso
  }

  getWinbizLanguage () {
    return this.winBiz
  }
}
