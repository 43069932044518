<template>
  <div>
    <!-- modal if new version is available -->
    <modal
      medium
      ref="versionModal"
      :okText="$t('v4.actualizeApp') + ' (' + counter + ')'"
      hide-cancel
      force
      hide-cancel-corner
      @ok="reloadApp()"
    >
      <div slot="title"><span class="fal fa-glass-cheers mr-2"></span> {{$t('v10.newAppVersionAvailable')}}</div>
      <v-layout class="wrap">
        <v-flex class="xs12 text-center">
          <div class="h5">
            {{$t('v10.actualizeAppNow')}}
          </div>

          <div class="h6 mt-6" v-if="$store.state.appInstance">
            <span class="fal fa-code-branch mr-2"></span>
            {{$t('v10.versionNumber')}}: {{$store.state.appInstance.getVersion()}}
          </div>
        </v-flex>
      </v-layout>
    </modal>

    <!-- 502 error -->
    <modal
      medium
      ref="deadApiModal"
      :okText="$t('general.ok')"
      hide-cancel
    >
      <div slot="title"><span class="fal fa-truck-loading mr-2"></span> {{$t('v10.deadApiModalTitle')}}</div>

      <v-layout class="wrap">
        <v-flex class="xs12 text-center">
          <div class="h5">
            <span class="fal fa-tachometer-alt-fastest mr-2"></span>
            {{$t('v10.weSolveTheProblem')}}
          </div>
        </v-flex>
      </v-layout>
    </modal>
  </div>
</template>

<script>
  export default {
    name: 'intercepterHandler',

    data () {
      return {
        counter: 0
      }
    },

    mounted () {
      /* global EventBus */
      // catch on new app version
      EventBus.$on('App:NewVersion', () => {
        this.newAppVersion()
      })

      // catch on api dead
      EventBus.$on('App:ApiDead', () => {
        this.$refs.deadApiModal.open()
      })
    },

    beforeDestroy () {
      /* global EventBus */
      EventBus.$off('App:NewVersion')
      EventBus.$off('App:ApiDead')
    },

    methods: {
      // reload the app hard
      reloadApp () {
        window.location.reload(true)
      },

      // handle new app version
      newAppVersion () {
        this.counter = 60

        // open modal
        this.$refs.versionModal.open()

        // start countdown
        let intervalId = setInterval(() => {
          this.counter--

          if (this.counter === 0) {
            clearInterval(intervalId)
            this.reloadApp()
          }
        }, 1000)
      }
    }
  }
</script>

<style scoped>

</style>
