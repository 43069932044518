import BlockPeriode from './BlockPeriode'

export default class BlockPeriodes {
  constructor () {
    this.blockPeriodes = []
  }

  parseAPI (apiBlockPeriodes) {
    // iterate blocking periodes from api
    apiBlockPeriodes.forEach((apiBlockPeriode) => {
      this.blockPeriodes.push(new BlockPeriode({
        id: apiBlockPeriode.id,
        startdate: apiBlockPeriode.startdate,
        enddate: apiBlockPeriode.enddate
      }))
    })
  }

  // iterate all blockingPeriodes and get total time in s
  getTotalTime () {
    let totalTime = 0
    this.blockPeriodes.forEach((periode) => {
      totalTime += periode.getBlockTime()
    })

    return totalTime
  }

  getOccupation (start, end) {
    let occupation = 0
    this.blockPeriodes.forEach((reservation) => {
      occupation += reservation.getOccupation(start, end)
    })

    return occupation
  }
}
