export default class ItemReservable {
  constructor (apiData = {}) {
    this.reservable = apiData.reservable
    this.reason = apiData.reason
  }

  getReasonText () {
    /* global i18n */
    if (!this.reason) return null
    if (!this.reason.active) return i18n.t('itemNotReservable.itemInactive')
    if (!this.reason.portalReservation) return i18n.t('items.telOnly')
    if (!this.reason.inDistance) return i18n.t('itemNotReservable.distance')
    if (!this.reason.public) return i18n.t('v8.onlyForMembers')
  }
}
