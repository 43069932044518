import Languages from './Languages'
import Language from './Language'
import config from '../config'
import menuConfiguration from "@/configs/menuConfiguration";
import router from '../router/router'
import Vue from 'vue'
import Location from './Location'
import FarmXImage from '../classes/Images/Image'
const ibanTools = require('ibantools')

export default class User {
  constructor (user = {}) {
    this.id = user.hasOwnProperty('id') ? user.id : null

    // fix: if this.id already exist, do not overwrite it with null
    if (user.hasOwnProperty('uid')) this.id = user.uid

    // set standard user permission if user gid isn't set
    // fix: needs hasOwnProperty method because gid can be 0 which is a falsy value
    this.gid = user.hasOwnProperty('gid') && user.gid !== null ? user.gid : config.user.normal
    this.mail = user.mail ? user.mail : null
    this.title = user.title ? user.title : null
    this.firstName = user.firstName ? user.firstName : null
    this.lastName = user.name ? user.name : null
    this.address = user.address ? user.address : null
    this.zip = user.plz ? user.plz : null
    this.city = user.ort ? user.ort : null
    this.tel = user.tel ? user.tel : null
    this.mobile = user.mobile ? user.mobile : null
    this.iban = user.iban ? user.iban : null
    this.hourlyWage = user.hasOwnProperty('hourlyWage') ? user.hourlyWage : null
    this.taxId = user.hasOwnProperty('taxId') ? user.taxId : null
    this.modules = user.hasOwnProperty('modules') ? user.modules : null

    // Type: Language
    this.language = user.language ? new Languages().getLanguageByKey(user.language) : new Language()
    this.cantonalOperationNumber = user.cantonalOperationNumber ? user.cantonalOperationNumber : null
    this.innercirclePublic = user.innercirclePublic ? user.innercirclePublic : false
    this.img = user.img ? new FarmXImage({link: user.img}) : null

    this.mailNotification = user.hasOwnProperty('notificationType') ? this.hasMailNotification(user.notificationType) : false
    this.appNotification = user.hasOwnProperty('notificationType') ? this.hasAppNotification(user.notificationType) : false

    this.gtcAccepted = user.hasOwnProperty('gtc') ? user.gtc : null

    // payment delay for the invoices (can be falsy by 0!)
    this.paymentWithin = user.hasOwnProperty('paymentWithin') ? user.paymentWithin : null

    // location
    this.location = new Location(
      (user.coordinatesLongitude ? parseFloat(user.coordinatesLongitude) / 1000000 : null),
      (user.coordinatesLatitude ? parseFloat(user.coordinatesLatitude) / 1000000 : null)
    )

    //menu settings
    this.menuSettings = user.hasOwnProperty('menuSettings') ? user.menuSettings : menuConfiguration.defaultMenu
  }

  /**
   * API
   */
  // update user data
  async save () {
    /* global EventBus axios api i18n */
    EventBus.$emit('spinnerShow')
    // prepare user data object
    let userData = {}
    // update mail
    if (this.title) userData.title = this.title
    if (this.firstName) userData.firstName = this.firstName
    if (this.lastName) userData.name = this.lastName
    if (this.address) userData.address = this.address
    if (this.zip) userData.plz = this.zip
    if (this.city) userData.ort = this.city
    if (this.iban) userData.iban = this.iban

    try {
      let response = await axios.patch(api + 'user/' + this.id, userData)

      if (response.status === 200) { // success
        Vue.notify({
          title: i18n.t('notify.userUpdated'),
          type: 'success'
        })
      } else { // if update fails
        Vue.notify({
          title: i18n.t('notify.userUpdateFailed'),
          type: 'error'
        })
      }
    } catch {
      Vue.notify({
        title: i18n.t('notify.userUpdateFailed'),
        type: 'error'
      })
    } finally {
      EventBus.$emit('spinnerHide')
    }
    return Promise.resolve(true)
  }

  /**
   * update mail attribute
   */
  async saveMail (newMail = this.mail, showSpinner = true) {
    /* global EventBus axios api i18n */
    if (showSpinner) EventBus.$emit('spinnerShow')
    try {
      await axios.patch(api + 'user/mail/update', {
        mail: newMail
      })

      if (showSpinner) {
        Vue.notify({
          title: i18n.t('notify.userUpdated'),
          type: 'success'
        })
      }
      return Promise.resolve(this)
    } catch {
      Vue.notify({
        title: i18n.t('notify.userUpdateFailed'),
        type: 'error'
      })
      return Promise.reject(this)
    } finally {
      if (showSpinner) EventBus.$emit('spinnerHide')
    }
  }

  async saveLocation () {
    /* global EventBus axios api i18n */
    try {
      let response = await axios.patch(api + 'user/' + this.id, {
        address: this.address,
        plz: this.zip,
        ort: this.city,
        coordinatesLongitude: this.location.getLongitudeApi(),
        coordinatesLatitude: this.location.getLatitudeApi()
      })

      if (response.status === 200) { // success
        Vue.notify({
          title: i18n.t('notify.userUpdated'),
          type: 'success'
        })
        return true
      } else { // if update fails
        Vue.notify({
          title: i18n.t('notify.userUpdateFailed'),
          type: 'error'
        })
        return false
      }
    } catch {
      Vue.notify({
        title: i18n.t('notify.userUpdateFailed'),
        type: 'error'
      })
      return false
    } finally {
      EventBus.$emit('spinnerHide')
    }
  }

  /**
   * METHODS
   */

  // watch user profile
  goToUserProfile () {
    /* global store */
    store.commit('setWatchUserUid', this.getId())
    router.push({name: 'UserProfile'})
  }

  // transform notification type from api into form readable
  hasMailNotification (apiNotificationType) {
    return apiNotificationType === 1 || apiNotificationType === 3
  }

  // transform notification type from api into form readable
  hasAppNotification (apiNotificationType) {
    return apiNotificationType === 2 || apiNotificationType === 3
  }

  /**
   *  SETTERS
   */

  setCity (city) {
    this.city = city
  }

  setZip (zip) {
    this.zip = zip
  }

  /**
   * @param Boolean: gtc
   */
  setGtc (gtc) {
    this.gtcAccepted = gtc
  }

  /**
   * GETTERS
   */

  /**
   * get user string to display
   */
  getId () {
    return this.id
  }

  getUid () {
    return this.id
  }

  getGid () {
    return this.gid
  }

  getMail () {
    return this.mail
  }

  getTitle () {
    return this.title
  }

  getFirstName () {
    return this.firstName
  }

  getLastName () {
    return this.lastName
  }

  getFullName () {
    return `${this.firstName} ${this.lastName}`
  }

  getDisplayUser (showLocation = false) {
    let city = showLocation ? ', ' + this.city : ''
    return this.firstName + ' ' + this.lastName + city
  }

  getAddress () {
    return this.address
  }

  getZip () {
    return this.zip
  }

  getCity () {
    return this.city
  }

  getTel () {
    return this.tel
  }

  hasTel () {
    return this.tel
  }

  getTelLink () {
    return '<a href="tel:' + this.getTel() + '">' + this.getTel() + '</a>'
  }

  getMobile () {
    return this.mobile
  }

  getMobileLink () {
    return '<a href="tel:' + this.getMobile() + '">' + this.getMobile() + '</a>'
  }

  isIbanValid () {
    return ibanTools.isValidIBAN(this.iban)
  }

  getIban () {
    return this.iban
  }

  get iban () {
    return this._iban
  }
  // remove empty spaces to be a valid iban
  set iban (iban) {
    if (iban) {
      this._iban = iban.replace(/\s/g,'')
    } else {
      this._iban = iban
    }
  }

  getHourlyWage () {
    return this.hourlyWage
  }

  /**
   *
   * @returns Type: Language
   */
  getLanguage () {
    return this.language
  }

  getCantonalOperationNumber () {
    return this.cantonalOperationNumber
  }

  /**
   * used in my settings: is my team public?
   * @returns {null|*}
   */
  getInnercirclePublic () {
    return this.innercirclePublic
  }

  getImg () {
    return this.img
  }

  // return full img link
  getLogo (thumbnail = false) {
    return this.getImg()?.getSrc(thumbnail)
  }

  // returns the api compatible notification type (0 - 3)
  getNotificationType () {
    let mailValue = this.mailNotification ? 1 : 0
    let pushValue = this.appNotification ? 2 : 0
    return mailValue + pushValue
  }

  /**
   *
   * @returns {Location}
   */
  getLocation () {
    return this.location
  }

  /**
   * days user has time to pay
   * @returns {*}
   */
  getPaymentWithin () {
    return this.paymentWithin
  }

  getTaxId () {
    return this.taxId
  }

  /**
   * does the user has the permission for a particular module?
   * @param identifier
   * @returns {*}
   */
  hasModule (identifier) {
    return this.modules.find(module => module.identifier === identifier)
  }
}
