<template>
  <v-app>
    <navigation-bar
      v-if="!hideHeader"
      :logged-out-mode="!$store.state.appUserInstance"
    />

    <v-main>
      <!-- navigation bar -->
      <v-container
        fluid
        class="px-3 px-lg-6 pt-6 pt-lg-8"
      >
        <div v-show="showBrowserHint">
          <div class="layout">
            <x-browser-update :config="browserUpdateConfig"/>
          </div>
        </div>

        <!-- spinner while loading data -->
        <spinner/>

        <notifications position="top center" :width="'70%'"/>

        <!-- chose layout -->
        <layout-blank
          v-if="useBlankLayout"
        />
        <layout-with-menu
          v-else
        />

        <s-footer v-if="$store.state.appUserInstance && $store.state.appUserInstance.hasGtcAccepted()"/>
      </v-container>

      <!-- version alert for app -->
      <intercepterHandler/>
    </v-main>
  </v-app>
</template>

<script>
import LayoutWithMenu from './components/layout/LayoutWithMenu.vue'
import SFooter from './components/layout/footer/SFooter'
import IntercepterHandler from './components/versioning/IntercepterHandler'
import LayoutBlank from './components/layout/LayoutBlank.vue'
import NavigationBar from '@/components/layout/navbar/NavigationBar.vue'

export default {
  name: 'app',
  components: {
    NavigationBar,
    IntercepterHandler,
    SFooter,
    LayoutWithMenu,
    LayoutBlank
  },

  computed: {
    useBlankLayout () {
      return this.isInvitationRoute ||
        (this.isAuthRoute || this.$route.name === 'home' || this.$route.name ==='ItemPublicView') ||
        this.isInvoiceRoute
    },
    hideHeader () {
      return this.isInvoiceRoute || this.isInvitationRoute
    },
    isInvitationRoute () {
      return this.$route.name === 'Invitation'
    },
    isInvoiceRoute () {
      return this.$route.name === 'SentInvoice'
    },
    isAuthRoute () {
      return this.$route.path.match('auth') || this.$route.name === 'PushNotification'
    }
  },

  beforeDestroy () {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, {passive: true})
  },

  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, {passive: true})
  },

  methods: {
    onResize () {
      /*global EventBus*/
      EventBus.$emit('finishEditMenu')

    }
  },
  data () {
    return {
      showBrowserHint: true,

      // minimum browser requirements
      browserUpdateConfig: {
        vs: {
          i: 11,
          f: -4,
          o: -4,
          s: -2,
          c: 40
        },
        // Specifies browser versions to notify. Negative numbers specify how much versions behind current version to notify.
        // f:22 ---> Firefox <= 22
        // c:-5 ---> Chrome <= 35 if current Chrome version is 40.
        reminder: 1,
        // after how many hours should the message reappear
        // 0 = show all the time
        reminderClosed: 1,
        // if the user explicitly closes message it reappears after x hours
        // eslint-disable-next-line
        onshow: function (infos) {
        },
        // eslint-disable-next-line
        onclick: function (infos) {
        },
        // eslint-disable-next-line
        onclose: function (infos) {
        },
        // callback functions after notification has appeared / was clicked / closed
        l: false,
        // set a fixed language for the message, e.g. "en". This overrides the default detection.
        test: false,
        // true = always show the bar (for testing)
        text: '',
        // custom notification text (html)
        // Placeholders {brow_name} will be replaced with the browser name, {up_but} with contents of the update link tag and {ignore_but} with contents for the ignore link.
        // Example: Your browser, {brow_name}, is too old: <a{up_but}>update</a> or <a{ignore_but}>ignore</a>.
        text_de: 'Ihr Browser, {brow_name}, ist zu alt für FarmX. <br /> <a{up_but}>updaten</a> oder <a{ignore_but}>ignorieren</a>',
        text_fr: 'Ihr Browser, {brow_name}, ist zu alt für FarmX. <br /> <a{up_but}>updaten</a> oder <a{ignore_but}>ignorieren</a>',
        // custom notification text for language "xx"
        // e.g. text_de for german and text_it for italian
        newwindow: true,
        // open link in new window/tab
        url: null,
        // the url to go to after clicking the notification
        noclose: true,
        // Do not show the "ignore" button to close the notification
        nomessage: false,
        // Do not show a message if the browser is out of date, just call the onshow callback function
        jsshowurl: '//browser-update.org/update.show.min.js',
        // URL where the script, that shows the notification, is located. This is only loaded if the user actually has an outdated browser.
        container: document.body,
        // Element where the notification will be injected.
        api: 4
        // This is the version of the browser-update api to use. Please do not remove.
      }
    }
  }
}
</script>

<style lang="scss">
@import "sass/main";
</style>
