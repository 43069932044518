import CustomField from './CustomField'
import Vue from 'vue'
import AxiosHelper from '../AxiosHelper'

export default class CustomFields {
  constructor () {
    this.customFields = []

    // flag to indicate, the custom fields have been loaded (even if the array might be empty)
    this.loaded = false
  }

  /**
   * fetch custom fields from api
   */
  async fetch (itemsArray, type, force = false) {
    if (itemsArray.length && !this.loaded || (force && itemsArray.length)) {
      /* global EventBus axios api i18n */
      EventBus.$emit('spinnerShow')

      try {
        let response = await axios.get(api + 'customFields/' + type +
         await AxiosHelper.serializeArray(itemsArray, 'id', 'entity_ids')
       )

        this.loaded = true
        await this.parseCustomFields(response.data, type)
      } catch (e) {
        Vue.notify({
          title: i18n.t('notify.error'),
          type: 'error'
        })
      } finally {
        EventBus.$emit('spinnerHide')
      }
    }
  }

  /**
   * parse raw api data
   * @param newCustomFields
   * @returns {PromiseConstructor}
   */
  parseCustomFields (entities, type) {
    this.customFields = []

    // iterate entities
    for (let i = 0; i < entities.length; i++) {
      let customFields = entities[i].customFields

      // iterate custom fields in item object
      for (let b = 0; b < customFields.length; b++) {
        this.customFields.push(new CustomField({
          id: entities[i].id,
          key: customFields[b].key,
          value: customFields[b].value,
          type: type
        }))

      }
    }
  }

  /**
   * update an array with custom fields
   * @param customFieldsArray
   * @returns {Promise<PromiseConstructor>}
   */
  async save () {
    if (this.customFields.length) {
      /* global EventBus axios api i18n */
      EventBus.$emit('spinnerShow')

      // prepare custom field array to save or update
      let customFields = []

      for (let i = 0; i < this.customFields.length; i++) {
        let tmpCustomField = this.customFields[i]
        customFields.push({
          key: tmpCustomField.getKey(),
          entity_id: tmpCustomField.getId(),
          entity: tmpCustomField.getType(),
          value: tmpCustomField.getValue()
        })
      }

      try {
        let response = await axios.post(api + 'customFields/', customFields)

        if (response.status !== 200) {
          Vue.notify({
            title: i18n.t('v13.customFieldsWereNotUpdated'),
            type: 'error'
          })
        }
      } catch (e) {
        Vue.notify({
          title: i18n.t('v13.customFieldsWereNotUpdated'),
          type: 'error'
        })
      } finally {
        EventBus.$emit('spinnerHide')
      }
    }
  }

  /**
   * SETTER
   */

  /**
   * @param key
   * @param value
   * @returns {Promise<void>}
   */
  async setCustomField (key, value, type, id) {
    let customField = await this.getCustomField(key)

    if (customField) customField.setValue(value) // custom field already exists. set new value
    else { // custom field does not exist. create and save new one
      this.customFields.push(new CustomField({key: key, value: value, type: type, id: id}))
    }
  }

  addCustomField (customFieldInstance) {
    this.customFields.push(customFieldInstance)
  }

  /**
   * GETTER
   */

  /**
   * get custom field by key
   * @param key
   * @returns {T}
   */
  getCustomField (key) {
    return this.customFields.find(customField => customField.getKey() === key)
  }

  // get the array
  getCustomFields () {
    return this.customFields
  }
}
