import Category from './Category'
import Translation from '../Translation'
import Translations from '../Translations'

export default class Categories {
  constructor () {
    this.categories = []
  }

  parseApiData (apiCategories = []) {
    this.categories = []
    apiCategories.forEach((tmpCategory) => {
      this.categories.push(new Category(tmpCategory))
    })
    return this
  }

  /**
   * add a new category instance to the array
   */
  addCategory (categoryInstance) {
    this.categories.unshift(categoryInstance)
  }

  /**
   * add a category instance with "all"
   */
  addAllCategory () {
    /* global i18n */
    // prepare translations for category
    let translationsInstance = new Translations()

    // only necessary to add the current app translation
    translationsInstance.addTranslation(
      new Translation(
        i18n.t('v10.all'),
        i18n.t('v10.all'),
        i18n.t('v10.all'),
        /* global store */
        store.state.languageObject.getCurrentLanguageInstance()
      )
    )

    // create category instance
    let categoryInstance = new Category({
      id: null,
      name: translationsInstance
    })

    // add category instance to categories instance
    this.addCategory(categoryInstance)
  }

  /**
   * @returns {Array}
   */
  getCategories () {
    return this.categories
  }
}
