export default class RedirectMemory {
  constructor ({routeTo, params = {}}) {
    this.routeTo = routeTo
    this.params = params
  }

  getRouteTo () {
    return this.routeTo
  }
  getParams () {
    return this.params
  }
}
