import Modal from './vuestic-modal/VuesticModal.vue'
import Scrollbar from './vuestic-scrollbar/VuesticScrollbar.vue'
import Tabs from './vuestic-tabs/VuesticTabs.vue'
import Wizard from './vuestic-wizard/VuesticWizard.vue'

const VuesticComponentsPlugin = {
  install (Vue) {
    Vue.component(Modal.name, Modal)
    Vue.component(Scrollbar.name, Scrollbar)
    Vue.component(Tabs.name, Tabs)
    Vue.component(Wizard.name, Wizard)
  }
}

export default VuesticComponentsPlugin
