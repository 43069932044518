import Vue from 'vue'
import Discount from './Discount'
import AxiosHelper from '../../AxiosHelper'
import Item from '../../Item'
/**
 * the model for discounts
 */
export default class Discounts {

  /**
   * init discounts
   */
  constructor (discounts = []) {
    /**
     * all discounts
     * @type {Array}
     */
    this.discounts = discounts
  }

  /**
   * get all the discounts from the api
   * if the year param is passed, it also fetches the billed reservations in the past
   * @returns {Promise<PromiseConstructor>}
   */
  async loadDiscounts(spinner = true, billingStart = null) {
    /* global EventBus axios api i18n */
    if (spinner) EventBus.$emit('spinnerShow')
    this.discounts = []
    try {
      let response = await axios.get(api + 'module/discount')

      if (response.status === 200) {
        await this.parseApiItems(response.data)

        if (billingStart) await this.loadDiscountReservations(billingStart)
      } else {
        Vue.notify({
          title: i18n.t('v13.discountsLoadFailed'),
          text: response.status,
          type: 'error'
        })
      }
    } catch (e) {
      Vue.notify({
        title: i18n.t('v13.discountsLoadFailed'),
        type: 'error'
      })
    } finally {
      if (spinner) EventBus.$emit('spinnerHide')
    }
  }

  // load discountReservations for each discount
  // discountReservations are already billed reservations in the past
  async loadDiscountReservations (billingStart) {
    // iterate discounts
    await Promise.all(
      await this.discounts.map(async (discount) => {
        await discount.loadDiscountReservations(billingStart)
      })
    )
  }

  /**
   * parse loaded api discount into class instances
   * @param apiDiscounts
   * @returns {PromiseConstructor}
   */
  parseApiItems (apiDiscounts) {
    // iterate all items
    for (let i = 0; i < apiDiscounts.length; i++) {
      this.discounts.push(new Discount(apiDiscounts[i]))
    }
  }

  /**
   * loading discounts for multiple items
   * @param itemIds
   * @returns {Promise<PromiseConstructor>}
   */
  async loadDiscountsByItems (itemsInstance) {
    if (itemsInstance && itemsInstance.items && itemsInstance.items.length) {
      /* global EventBus axios api i18n */
      EventBus.$emit('spinnerShow')

      try {
        let response = await axios.get(api + 'module/discounts/items' + await AxiosHelper.serializeArray(itemsInstance.getItems(), 'id', 'items'))

        if (response.status === 200) this.parseDiscountsByItems(response.data)
       else {
          Vue.notify({
            title: i18n.t('v13.discountFetchingError'),
            text: response.status,
            type: 'error'
          })
        }
      } catch (e) {
        Vue.notify({
          title: i18n.t('v13.discountFetchingError'),
          type: 'error'
        })
      } finally {
        EventBus.$emit('spinnerHide')
      }
    }
  }

  /**
   * parse discounts fetched by item id
   * @param itemsWithDiscounts
   * @returns {PromiseConstructor}
   */
  parseDiscountsByItems (itemsWithDiscounts) {
    this.discounts = []

    // iterate items
    for (let itemIndex = 0; itemIndex < itemsWithDiscounts.length; itemIndex++) {
      let item = itemsWithDiscounts[itemIndex]
      let discountsOfItem = item.module_discount_itemLinks

      // iterate discounts of this item
      for (let discountIndex = 0; discountIndex < discountsOfItem.length; discountIndex++) {
        let apiDiscount = discountsOfItem[discountIndex]
        let foundDiscount = this.getDiscountById(apiDiscount.discountId)

        // was this discount already pushed
        if (foundDiscount) {
          foundDiscount.addItemLink(new Item({id: item.id}))
        } else { // push new discount
          // rewrite id
          apiDiscount.module_discount_discount.id = apiDiscount.discountId

          // create discount instance out of the raw api data
          let discountInstance = new Discount(apiDiscount.module_discount_discount)

          // add item link
          discountInstance.addItemLink(new Item({id: item.id}))
          this.discounts.push(discountInstance)
        }
      }
    }
  }

  /**
   * get all discounts which were prefetched using func loadDiscount
   * @returns {Array}
   */
  getDiscounts () {
    return this.discounts
  }

  /**
   * search for a discount instance by discount id
   * @param id
   * @returns {null|*}
   */
  getDiscountById (id) {
    // iterate discounts
    for (let i = 0; i < this.discounts.length; i++) {
      if (this.discounts[i].getId() === id) return this.discounts[i]
    }

    return null
  }

  /**
   * does this item has any discount ?
   * @param itemId
   * @returns {null|*}
   */
  getDiscountsByItemId (itemId) {
    return this.discounts.filter(discount => discount.containsItemId(itemId))
  }

  /**
   * Returns a Date if any discount available else null
   * @return {*|null}
   */
  getTargetDate () {
    return this.discounts.length ? this.discounts[0].targetDate : null
  }
}
