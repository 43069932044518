export default class NumberHelper {
  static secureParseFloat (input) {
    try {
      if (isNaN(input)) {
        return parseFloat(input.replace(/,/g, '.'))
      }
      return parseFloat(input.toString().replace(/,/g, '.'))
    } catch (error) {
      return null
    }
  }
}
