export default {
  modules: {
    discount: 'discount',
    usageSignal: 'usageSignal',
    compensation: 'compensation'
  },
  customFields: {
    keys: {
      compensation: 'compensation'
    },
    types: {
      item: 'item'
    }
  }
}
