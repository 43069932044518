<template>
  <div>
    <!-- phone only modal -->
    <modal
      small
      :force="false"
      ref="telOnlyModal"
      hideCancel
      :okText="$t('v4.close')">
      <div slot="title">{{$t('reservation.telOnlyModalTitle')}}</div>
      <div class="h4" v-if="telOnlyUser">
        <div class="row justify-content-center pb-2">
          <div class="col-auto">
            {{telOnlyUser.getDisplayUser(true)}}
          </div>
        </div>
        <div class="row justify-content-center pb-1 mt-2">
          <div class="col-auto">
            <span class="fal fa-mobile mr-2">
            </span>
            <span v-html="telOnlyUser.getMobileLink()"></span>
          </div>
        </div>
        <div class="row justify-content-center pb-2 mt-2" v-if="telOnlyUser.hasTel()">
          <div class="col-auto">
            <span class="fal fa-phone mr-2">
            </span>
            <span v-html="telOnlyUser.getTelLink()"></span>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import User from '../../classes/User'

export default{
  name: 'tel-only-information',

  data () {
    return {
      telOnlyUser: null
    }
  },

  methods: {
    // load contact data of tel only user
    openTelOnlyModal (item) {
      /* global EventBus axios api */
      EventBus.$emit('spinnerShow')
      axios.get(api + 'user/' + item.getResponsible().getId())
      .then((response) => {
        this.telOnlyUser = new User(response.data[0])

        // show modal
        this.$refs.telOnlyModal.open()
      })
      .catch((error) => {
        this.$notify({
          title: this.$t('notify.contactDataCouldNotBeLoaded'),
          text: error.response.status,
          type: 'error'
        })
      })
      .finally(() => {
        EventBus.$emit('spinnerHide')
      })
    }
  }
}
</script>
