<template>
  <v-layout class="wrap">
    <v-flex class="xs12 clickable h4 mb-0 " @click.prevent="toggleMenu()">

      <!-- open/close menu -->
      <!-- {{$t('v12.menu')}} -->
      <span class="fal fa-bars ml-2"></span>
    </v-flex>
  </v-layout>
</template>

<script>
  export default {
    name: 'BurgerMenu',

    methods: {
      toggleMenu () {
        /* global EventBus */
        EventBus.$emit('toggleMenu')
      }
    }
  }
</script>

<style scoped>

</style>
