import axios from 'axios'
import Vue from 'vue'
import User from '../../../classes/User'
import IbanHelper from '../../../classes/Helper/IbanHelper'

export class UsersServices {
  /**
   * Load a single user
   * @param userId
   * @param admin
   * @return {Promise<boolean|User>}
   */
  static async getOne ({userId, admin = false, responsible = false}) {
    /* global EventBus */
    EventBus.$emit('spinnerShow')
    try {
      /* global api i18n */
      const response = await axios.get(`${api}${admin ? 'admin/' : ''}user/${userId}`)
      const user = new User(response.data[0])
      return user
    } catch (error) {
      const errorTitle = responsible ? i18n.t('v2.responsibleCouldNotBeLoaded') : i18n.t('v8.userCouldNotBeenLoaded')
      Vue.notify({
        title: errorTitle,
        text: error,
        type: 'error'
      })
      return false
    } finally {
      EventBus.$emit('spinnerHide')
    }
  }

  /**
   * Update user object with custom payload
   * @param userId
   * @param payload
   * @return {Promise<boolean>}
   */
  static async patchUser (userId, payload) {
    // only patch valid iban of app user if provided any
    const iban = payload.iban
    if (iban && !IbanHelper.checkIbanOfAppUser()) {
      return false
    }
    EventBus.$emit('spinnerShow')
    try {
      await axios.patch(`${api}user/${userId}`, payload)
      EventBus.$emit('spinnerHide')
      return true
    } catch (e) {
      this.$notify({
        title: i18n.t('notify.userUpdateFailed'),
        type: 'error'
      })
      EventBus.$emit('spinnerHide')
      return false
    }
  }
}
