<template>
  <div>
    <div
      class="systemNotification blue white--text"
      v-if="currentSystemNotification && !currentSystemNotification.isRead()">
      <v-layout class="wrap pl-2 pr-6 py-6">
        <!-- notification title & text -->
        <v-flex class="">
          <span class="h5 mb-0">
            <span class="fal fa-info-circle mr-2"></span>
            {{currentSystemNotification.getTitle()}}
          </span>

          <span class="h6" :class="{'ml-2': $vuetify.breakpoint.mdAndUp}">
            {{currentSystemNotification.getText()}}
          </span>
        </v-flex>

        <!-- mark notification as read -->
        <v-flex
          class="text-right clickable"
          @click="currentSystemNotification.readNotification()"
        >
          <div class="h5 mb-0">
            <span class="fal fa-check mr-2"/>
            {{$t('v10.gotThat')}}
          </div>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
  import SystemNotification from '../../classes/Notifications/SystemNotification'

  export default {
    name: 'SystemNotifications',

    data () {
      return {
        currentSystemNotification: null
      }
    },

    mounted () {
      // No system notification is set at the moment as it is uncommented.
      // this.setCurrentSystemNotification()
    },

    methods: {
      // create new system notification
      setCurrentSystemNotification () {
        this.currentSystemNotification = new SystemNotification(
          8,
          this.$t('systemNotification.checkItemsTitle'),
          this.$t('systemNotification.checkItemsText'),
          this.$cookies
        )

        // check if there is a unread system notification and emit to hide the hill
        if (this.currentSystemNotification && !this.currentSystemNotification.isRead()) this.$emit('hideHill')
      }
    }
  }
</script>

<style scoped>

  .systemNotification {
    width: 100%;
  }
</style>
