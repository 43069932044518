import Translations from '../Translations'
import Translation from '../Translation'

export default class Unit {
  constructor (unit) {
    if (!unit) return this
    this.id = unit.id
    this.name = new Translations()
    this.parseApiUnitTranslations(unit)
  }

  parseApiUnitTranslations (unit) {
    // add german translation
    /* global store */
    this.name.addTranslation(
      new Translation(
        unit.itemUnitNameDe,
        unit.itemUnitShortDe,
        unit.descriptionDe,
        store.state.languageObject.getLanguageByKey('de_CH')
      )
    )

    // add french translation
    /* global store */
    this.name.addTranslation(
      new Translation(
        unit.itemUnitNameFr,
        unit.itemUnitShortFr,
        unit.descriptionFr,
        store.state.languageObject.getLanguageByKey('fr_CH')
      )
    )

    // add italian translation
    /* global store */
    this.name.addTranslation(
      new Translation(
        unit.itemUnitNameIt,
        unit.itemUnitShortIt,
        unit.descriptionIt,
        store.state.languageObject.getLanguageByKey('it_CH')
      )
    )
  }

  getId () {
    return this.id
  }

  getName () {
    return this.name.getCurrentTranslation()
  }
}
