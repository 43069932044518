<template>
  <div
    class="wizard"
    :class="computedLayout"
    v-orientation-handler="{ layout: wizardLayout,  breakPoint: orientationBreakPoint }">

    <div
      v-if="computedLayout === 'horizontal'"
      class="indicator-container indicator-container-horizontal">
      <simple-horizontal-indicator v-if="wizardType === 'simple'" :steps="steps" :currentStep="currentStep" :completed="wizardCompleted"></simple-horizontal-indicator>
      <rich-horizontal-indicator v-if="wizardType === 'rich'" :steps="steps" :currentStep="currentStep" :completed="wizardCompleted"></rich-horizontal-indicator>
    </div>

    <div v-if="computedLayout === 'vertical'" class="indicator-container indicator-container-vertical">
      <rich-vertical-indicator v-if="wizardType === 'rich'" :steps="steps" :currentStep="currentStep" :completed="wizardCompleted"></rich-vertical-indicator>
      <simple-vertical-indicator v-if="wizardType === 'simple'" :steps="steps" :currentStep="currentStep" :completed="wizardCompleted"></simple-vertical-indicator>
    </div>

    <div class="wizard-body">
      <div class="wizard-body-step" v-for="step in steps" v-show="isStepShown(step)" :key="step.slot">
        <slot :name="step.slot" class="step-content"></slot>
      </div>

      <div class="wizard-body-step" v-show="wizardCompleted">
        <slot :name="wizardCompletedSlotName" class="step-content"></slot>
      </div>


      <v-row
        v-if="!wizardCompleted && showButtons"
        class="justify-center flex-sm-row mt-4"
      >
        <v-col
          v-if="backEnabled" v-show="showNextButton(false)"
          class="col-auto align-self-center"
        >
          <v-btn
            large
            color="primary"
            outlined
            @click="goBack()"
          >
            <span class="fal fa-chevron-left mr-2"></span>
            {{$t('general.back')}}
          </v-btn>
        </v-col>

        <v-col
          v-if="!isLastStep()" v-show="showNextButton(true) && showButtons"
          class="col-auto align-self-center"
        >
          <v-btn
            large
            outlined
            color="primary"
            @click="goNext()"
          >
            {{$t('general.next')}}
            <span class="fal fa-chevron-right ml-2"></span>
          </v-btn>
        </v-col>

        <v-col class="col-auto align-self-center" v-if="currentStep == steps.length - 1 && showButtons">
          <v-btn
            large
            outlined
            color="primary"
            @click="completeWizard()"
          >
            <span class="fal fa-check mr-2"/>
            {{lastStepLabel}}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import SimpleHorizontalIndicator from './indicators/SimpleHorizontalIndicator.vue'
  import RichHorizontalIndicator from './indicators/RichHorizontalIndicator.vue'
  import RichVerticalIndicator from './indicators/RichVerticalIndicator.vue'
  import SimpleVerticalIndicator from './indicators/SimpleVerticalIndicator.vue'
  import WizardOrientationHandler from './WizardOrientationHandler'

  export default {
    name: 'vuestic-wizard',
    props: {
      hasExit: {
        type: Boolean,
        default: false
      },
      steps: {},
      wizardType: {
        type: String,
        default: 'simple'
      },
      wizardLayout: {
        type: String,
        default: 'horizontal'
      },
      lastStepLabel: {default: 'Confirm'},
      onNext: {},
      onBack: {}
    },
    data () {
      return {
        currentStep: 0,
        wizardCompleted: false,
        wizardCompletedSlotName: 'wizardCompleted',
        orientationBreakPoint: 767, // TODO: into config,
        computedLayout: this.wizardLayout,
        showButtons: true
      }
    },
    components: {
      SimpleHorizontalIndicator,
      RichHorizontalIndicator,
      RichVerticalIndicator,
      SimpleVerticalIndicator
    },
    directives: {
      orientationHandler: WizardOrientationHandler
    },
    computed: {
      currentSlot () {
        let slotName = this.steps[this.currentStep].slot
        if (this.wizardCompleted) {
          slotName = this.wizardCompletedSlotName
        }
        return slotName
      },
      backEnabled () {
        return this.currentStep !== 0 || this.hasExit
      }
    },

    mounted () {
      /* global EventBus */
      this.$on('wizardLayoutChange', this.updateLayout)

      EventBus.$on('vuesticWizardHideButtons', () => {
        this.showButtons = false
      })
    },

    beforeDestroy () {
      /* global EventBus */
      EventBus.$off('vuesticWizardHideButtons')
    },

    methods: {
      showNextButton (isNextButton) {
        let tmpStep = true
        if (isNextButton) {
          tmpStep = this.steps[this.currentStep].hideNextButton
        } else {
          tmpStep = this.steps[this.currentStep].hidePrevButton
        }

        if (!tmpStep) {
          return true
        } else {
          return false
        }
      },
      updateLayout (layout) {
        this.computedLayout = layout
      },
      goNext () {
        this.currentStepOnNext()
        if (!this.isLastStep() && this.isCurrentStepValid()) {
          this.currentStep++
        }
      },
      goBack () {
        this.currentStepOnBack()
        if (this.currentStep > 0) {
          this.currentStep--
        }
      },
      completeWizard () {
        /* global EventBus */
        EventBus.$emit('vuesticWizardCompleteWizard')
      },
      isLastStep () {
        return this.currentStep === this.steps.length - 1
      },
      currentStepOnNext () {
        let step = this.steps[this.currentStep]
        if (step.onNext) {
          step.onNext()
        }
      },
      currentStepOnBack () {
        let step = this.steps[this.currentStep]
        if (step.onBack) {
          step.onBack()
        }
      },
      isCurrentStepValid () {
        if (this.steps[this.currentStep].isValid()) {
          return true
        } else {
          return false
        }
      },
      isStepShown (step) {
        return step.slot === this.currentSlot && !this.wizardCompleted
      }
    }
  }
</script>

<style lang="scss" scoped>
  $wizard-body-vl-step-h-padding: 7%;
  $wizard-indicator-vl-width: 20rem;
  $wizard-body-vl-min-height: 20rem;

  $wizard-body-hl-step-h-padding: 0;

  $wizard-body-step-v-padding: 0;
  $wizard-body-step-item-margin-bottom: 10px;



  .wizard {
    position: relative;
    width:  100%;
    display: flex;

    &.horizontal {
      flex-direction: column;
    }

    &.vertical {
      flex-direction: row;
    }
  }


  .wizard-body{
    position: relative;
  }
  .wizard-body-step{
    > * {
      display: flex;
      flex-direction: column;
      align-items: center;

      > * {
        margin-bottom: $wizard-body-step-item-margin-bottom;
      }

      > *:last-child {
        margin-bottom: 0;
      }
    }
  }

  .wizard-body-actions{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: baseline;

    .btn-container {
      margin: $wizard-body-step-item-margin-bottom  $wizard-body-step-item-margin-bottom/2 0  $wizard-body-step-item-margin-bottom/2;
    }
  }

  .vertical {
    .wizard-body {
      min-height: $wizard-body-vl-min-height;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: $wizard-body-step-v-padding  $wizard-body-vl-step-h-padding;
    }

    .indicator-container {
      flex-basis: $wizard-indicator-vl-width;
      max-width: $wizard-indicator-vl-width;
      flex-grow: 0;
      flex-shrink: 0;
      position: relative;
    }
  }

  .horizontal {
    .wizard-body {
      padding: $wizard-body-step-v-padding $wizard-body-hl-step-h-padding;
    }
  }
</style>
