export default class CustomField {
  constructor (params) {
    this.id = params.id ? params.id : null // this is the id of an item or a reservation etc. it's NOT the id of the custom field!
    this.type = params.type ? params.type : null
    this.key = params.key ? params.key : null
    this.value = params.value ? params.value : null
  }

  setId (id) {
    this.id = id
  }

  setValue (value) {
    this.value = value
  }

  /**
   * GETTERS
   */
  getId () {
    return this.id
  }

  getType () {
    return this.type
  }

  getKey () {
    return this.key
  }

  getValue () {
    return this.value
  }
}
