<template>
  <!-- hackaround, try not to break old tabbing system with events...-->
  <v-tabs
    v-model="tab"
    fixed-tabs
    centered
    show-arrows
  >
    <v-tab
      v-for="name in names"
      :key="name"
      :href="`#${name}`"
      v-on:click="setActive(name)"
      class="px-1"
    >
      {{ name }}
    </v-tab>

    <v-tab-item
      v-for="name in names"
      :key="name"
      :value="name"
      :class="{active: name === tab}"
    >
      <v-card flat>
        <slot :name="name"></slot>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
  export default {
    name: 'vuestic-tabs',
    props: ['names'],

    created () {
      /* global EventBus */
      EventBus.$on('changeTab', (tab, customAction = true) => {
        this.setActive(tab, customAction)
      })
    },

    beforeDestroy () {
      EventBus.$off('changeTab')
    },

    methods: {
      setActive (name, customAction = true) {
        this.tab = name
        if (customAction) {
          /* global EventBus */
          EventBus.$emit('tabChanged', this.tab)
        }
      },

      getActiveTab () {
        return this.tab
      }
    },
    data () {
      return {
        // active tab
        tab: null
      }
    }
  }
</script>

<style lang="scss">
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    .nav-item.active {
      border-bottom: var(--v-primary-base) 2px solid;
    }

    .nav {
      flex-wrap: nowrap;
      overflow-x: scroll;
    }
  }
</style>
