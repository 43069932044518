import router from '../../router/router'
import routes from '../../router/routes'

export default class Menu {
  /**
   * @param params > String: icon, String: title (x.y), String: route, Menu: subMenu, Int: minGid
   */
  constructor (params) {
    this.icon = params.icon ? params.icon : null
    this.title = params.title ? params.title : null

    this.route = params.routeName ? this.getRouteByName(params.routeName) : null
    this.minGid = params.hasOwnProperty('minGid') ? params.minGid : null

    // only display menu entry if app is mobile
    this.onlyMobile = params.onlyMobile ? params.onlyMobile : false

    this.name = params.name ? params.name : null
    this.isActive = params.active ? params.active : false
    this.parentMenu = params.parentMenu ? params.parentMenu : null
  }

  getTitle () {
    /* global i18n */
    return i18n.t(this.title)
  }

  getIcon () {
    return this.icon
  }

  getRouteByName (routeName) {
    return routes.routes.find(tmpRoute => tmpRoute.name === routeName)
  }

  getRoute () {
    return this.route
  }

  isOnlyMobile () {
    return this.onlyMobile
  }

  // route user to the url of the menu entry
  goToRoute () {
    router.push({name: this.route.name})

    // close mobile menu
    /* global EventBus */
    EventBus.$emit('closeMenu')
  }
}
