<template>
  <div>
    <div class="clickable mb-0" @click="$refs.changeUser.open()">
      <!-- danger icon if other user -->
      <span v-if="$store.state.appUserInstance.hasOtherUser()" class="text-danger fal fa-exclamation-triangle pr-1"></span>
      <!-- user name -->
      {{$store.state.appUserInstance.getRealUser().getDisplayUser()}}

      <!-- switch user logo -->
      <span class="fa fa-user ml-1"/>
    </div>

    <change-user
      v-if="$store.state.appUserInstance"
      ref="changeUser"
    />
  </div>
</template>

<script>
  import ChangeUser from '../changeUser/ChangeUser.vue'

  export default {
    name: 'user-nav',

    components: {
      ChangeUser
    }
  }
</script>

<style lang="scss">

</style>
