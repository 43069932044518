import Language from './Language'

export default class Languages {
  constructor () {
    this.languages = []
    this.initLanguages()
  }

  // set all languages
  initLanguages () {
    this.languages.push(new Language('de', 'de_CH', 'de-ch', 'D'))
    this.languages.push(new Language('fr', 'fr_CH', 'fr-ch', 'F'))
    this.languages.push(new Language('it', 'it_CH', 'it-ch', 'I'))
  }

  // set language of the browser
  initBrowserLanguage () {
    let browserLanguage = window.navigator.language
    let languageInstance = this.getLanguageByLanguage(browserLanguage.substr(0, 2))
    if (languageInstance) this.setLanguageKey(languageInstance.getLanguageKey())
  }

  // set new language by key
  setLanguageKey (languageKey) {
    /* global i18n */
    i18n.locale = languageKey
  }

  // set new language by string
  setLanguage (language) {
    i18n.locale = language
  }

  /**
   * GETTERS
   */

  // get available languages
  getLanguages () {
    return this.languages
  }

  getCurrentLanguageInstance () {
    /* global i18n */
    return this.languages.find((language) => language.key === i18n.locale)
  }

  // returns current language in iso format
  getCurrentIsoLanguage () {
    return this.getCurrentLanguageInstance().iso
  }

  // returns current language in key format
  getCurrentKeyLanguage () {
    /* global i18n */
    return this.getCurrentLanguageInstance().key
  }

  // searches for language object with particular language key
  getLanguageByKey (key) {
    return this.languages.find((languageInstance) => languageInstance.getLanguageKey() === key)
  }

  getLanguageByLanguage (language) {
    return this.languages.find((languageInstance) => languageInstance.getLanguage() === language)
  }
}
