import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify(
  {
    icons: {
      iconfont: 'fa'
    },
    theme: {
      options: {
        customProperties: true
      },
      themes: {
        light: {
          primary: '#006837',
          secondary: '#8cc63f',
          error: '#e34a4a',
          accent: '#FFEB3B',
          success: '#006837'
        }
      }
    }
});


