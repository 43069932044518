<template>
    <div>
      <!-- desktop version -->
      <v-layout class="wrap sidebar-container" v-if="$vuetify.breakpoint.mdAndUp">
        <v-card class="w-100">
          <v-card-text class="pa-0">
            <menu-items/>
          </v-card-text>
        </v-card>
      </v-layout>


      <!-- mobile version -->
      <v-expand-transition  v-if="$vuetify.breakpoint.smAndDown">
        <div class="mobile-container" v-if="mobileOpen">
          <v-row class="justify-center mt-12 ma-0">
            <v-col class="col-12 px-0">
              <menu-items mobileMode/>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
    </div>
</template>

<script>
  import MenuItems from './MenuItems'
  export default {
    name: "menu-container",
    components: {MenuItems},

    data() {
      return {
        mobileOpen: false
      }
    },

    mounted () {
      EventBus.$on('closeMenu', () => {
        this.mobileOpen = false
      })

      EventBus.$on('editMobileMenu', () => {
        this.mobileOpen = true
      })


      /* global EventBus */
      EventBus.$on('toggleMenu', () => {
        this.mobileOpen = !this.mobileOpen

        if (this.mobileOpen) {
          this.$vuetify.goTo(0, {
            duration: 500,
            easing: 'easeOutCubic',
            offset: 0
          })
        }
      })
    },

    beforeDestroy () {
      /* global EventBus */
      EventBus.$off('closeMenu')
      EventBus.$off('toggleMenu')
      EventBus.$off('editMobileMenu')
    }
  }
</script>

<style scoped>
  .mobile-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 999;
    overflow-y: scroll;
  }
</style>
