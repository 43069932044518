export default class Translation {
  constructor (name, short, description, language) {
    this.name = name
    this.short = short
    this.description = description
    this.language = language
  }

  /**
   * GETTERS
   */

  getName () {
    return this.name
  }

  getShort () {
    return this.short
  }

  getLanguage () {
    return this.language
  }

  getDescription () {
    return this.description
  }
}
