<template>
  <!-- TODO: workaround: div v-if only need for vue-picture-input. Known Bug: https://github.com/alessiomaffeis/vue-picture-input/issues/70 -->
  <div v-if="show">
    <v-dialog
      v-model="show"
      :max-width="size"
      :persistent="force"
      :scrollable="scrollable"
    >
      <v-card>
        <!--Header-->
        <v-card-title
          v-if="!hideHeader"
          class="px-3"
        >
          <div class="modal-header w-100">
            <slot name="header">
              <div class="modal-title w-100">
                <v-row class="align-center">
                  <!-- slot for title -->
                  <v-col class="col-10 no-word-break">
                    <slot name="title"></slot>
                  </v-col>

                  <!-- close button -->
                  <v-col class="col-2 text-right">
                    <div
                      class="h2 mb-0 clickable"
                      @click="cancel()"
                      v-if="!hideCancelCorner"
                    >
                      <span class="fal fa-times error--text"/>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </slot>
          </div>
        </v-card-title>

        <!-- body -->
        <v-card-text
          class="px-3"
        >
          <div :class="customModalBodyClass">
            <slot></slot>
          </div>
        </v-card-text>

        <!--Footer-->
        <v-card-actions
          v-if="showFooter"
          class="max-w-100 pb-4"
        >
          <slot name="footer">
            <v-row
              :class="[hideCancel || hideOk ? 'justify-center' : 'justify-space-around']"
            >
              <v-col class="col-auto">
                <v-btn
                  v-if="!hideCancel"
                  :color="cancelClass"
                  :large="!small"
                  outlined
                  @click="cancel"
                  :id="testCancelId"
                >
                  {{cancelText || $t('general.cancel')}}
                </v-btn>
              </v-col>
              <v-col class="col-auto">
                <v-btn
                  v-if="!hideOk"
                  :large="!small"
                  outlined
                  :color="okClass"
                  @click="ok"
                  :id="testOkId"
                >
                  {{okText || $t('general.ok')}}
                </v-btn>
              </v-col>
            </v-row>
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'vuestic-modal',
  props: {
    hideOk: {
      type: Boolean,
      default: false
    },
    hideCancel: {
      type: Boolean,
      default: false
    },

    // hide cancel button in the top right
    hideCancelCorner: {
      type: Boolean,
      default: false
    },

    showFooter: {
      type: Boolean,
      default: true
    },
    transition: {
      type: String,
      default: 'modal'
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },

    // persistent modal
    force: {
      type: Boolean,
      default: false
    },
    okText: {
      type: String,
      default: undefined
    },
    cancelText: {
      type: String,
      default: undefined
    },
    okClass: {
      type: String,
      default: 'primary'
    },
    cancelClass: {
      type: String,
      default: 'secondary'
    },

    // adds a custom css class to the modal body
    customModalBodyClass: {
      type: String,
      default: '',
      required: false
    },

    // don't close modal after clicking ok
    persistOnOk: {
      type: Boolean,
      default: false
    },

    // hide title section
    hideHeader: {
      type: Boolean,
      default: false,
      required: false
    },

    // make modal scrollable
    scrollable: {
      type: Boolean,
      default: true,
      required: false
    },

    // pass an element id for testing purposes
    testOkId: {
      type: String,
      required: false
    },
    testCancelId: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      show: false
    }
  },
  mounted () {
    /* global EventBus */
    EventBus.$on('cancelUpdate', () => {
      this.show = false
    })
  },
  computed: {
    size () {
      if (this.large) {
        return 1000
      } else if (this.small) {
        return 500
      } else {
        return 700
      }
    }
  },

  methods: {
    ok () {
      this.$emit('ok')
      if (!this.persistOnOk) {
        this.show = false
      }
    },

    cancel (emit = true) {
      // only emit if true
      if (emit) this.$emit('cancel')
      this.show = false
    },

    open () {
      this.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-header {
    height: $modal-header-height;
    border-bottom: $modal-header-border;
    display: flex;
    align-items: center;
  }
</style>
