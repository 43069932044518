import Image from './Image'

export default class ItemImages {
  constructor () {
    this.images = []
  }

  parseApi (apiData = []) {
    this.images = []
    for (let tmpImage of apiData) {
      if (tmpImage) {
        this.images.push(new Image(tmpImage))
      }
    }
    return this
  }

  /**
   * Returns an array of images. If no image available, return a placeholder image.
   * @return {Image[]|[]|null}
   */
  getImages (fallback = false, withMainImage = true) {
    if (!withMainImage) {
      return this.images.filter((image) => !image.isMainImage)
    }
    if (this.images.length) {
      return this.images
    }
    if (fallback) {
      // return a placeholder image if no img is available
      return [
        this.getFallbackImage()
      ]
    }
    return []
  }

  getFallbackImage () {
    return new Image(
      {
        link: require('@/assets/imgs/logoSimple.png'),
        isFullUrl: true
      }
    )
  }

  /**
   * Set main image
   * @param base64String
   */
  setMainImage (image) {
    if (this.images.length) {
      this.images[0] = image
    } else {
      this.images.push(image)
    }
  }

  /**
   *
   * @param fallback
   * @returns {Image|undefined|*}
   */
  getMainImage (fallback = false) {
    const mainImage = this.images.find((image) => {
      return image.isMainImage
    })
    if (mainImage) {
      return mainImage
    }
    return fallback ? this.getFallbackImage() : undefined
  }

  /**
   * If image id already exists, replace. Otherwise, add a new image to the array
   * @param image
   */
  setOrAddImage (image) {
    const imageIndex = this.images.findIndex((tmpImage) => tmpImage.id === image.id)
    if (imageIndex > -1) { // new image to append
      this.images.push(image)
      return 'added'
    } else {
      this.images[imageIndex] = image
      return 'updated'
    }
  }

  /**
   * Search image by a link and replace it
   * @param image
   * @param link
   * @return {boolean}
   */
  replaceImageByLink (image, link) {
    const imageIndex = this.images.findIndex((tmpImage) => !!tmpImage.link && tmpImage.link === link)
    if (imageIndex > -1) {
      this.images[imageIndex] = image
      return true
    }
    return false
  }

  /**
   * Delete image by link
   * @param imgLink
   * @return {boolean}
   */
  removeImageByLink (link) {
    const imageIndex = this.images.findIndex((tmpImage) => !!tmpImage.link && tmpImage.link === link)
    if (imageIndex > -1) {
      this.images.splice(imageIndex, 1)
      return true
    }
    return false
  }

  removeMainImage () {
    const imageIndex = this.images.findIndex((image) => !!image.isMainImage)
    if (imageIndex > -1) {
      this.images.splice(imageIndex, 1)
    }
  }
}
