export default class AxiosHelper {

  /**
   * serialize an array with objects to a comma separated list and create the axios get-parameter
   * @param arrayToSerialize
   * @param key
   * @param paramKey
   * @returns {Promise<string>}
   */
  static async serializeArray (arrayToSerialize, key, paramKey) {
    let serializedArray = '?' + paramKey + '='

    // iterate array
    for (let i = 0; i < arrayToSerialize.length; i++) {
      serializedArray = serializedArray + eval('arrayToSerialize[i].' + key)
      if (i < (arrayToSerialize.length - 1)) serializedArray = serializedArray + ','
    }

    return serializedArray
  }
}
