export default {
  general: {
    confirmed: 'Confirmé',
    total: 'Total',
    yes: 'Oui',
    no: 'Non',
    from: 'De',
    to: 'À',
    at: 'À',
    and: 'Et',
    cancel: 'Annuler',
    ok: 'Ok',
    add: 'Ajouter',
    'delete': 'Supprimer',
    edit: 'Modifier',
    save: 'Enregistrer',
    reallyDelete: 'Supprimer?',
    tableSearch: 'Recherche dans la tabelle',
    rowsPerPageLabel: 'Ligne par page',
    search: 'Recherche',
    name: 'Nom',
    surname: 'Nom de famille',
    lastname: 'Nom de famille',
    firstname: 'Prénom',
    address: 'Adresse',
    plz: 'NPA',
    city: 'Lieu',
    categories: 'Catégorie',
    back: 'Retour',
    prev: 'Précédent',
    next: 'Suivant',
    date: 'Date',
    time: 'Heure',
    machine: 'Machine',
    language: 'Langue',
    logo: 'Logo',
    phone: 'Téléphone',
    mobile: 'Mobile',
    reservation: 'Réservation',
    renter: 'Locataire',
    lessor: 'Prestataire',
    print: 'Imprimer',
    farMeSlug: 'Quand louer devient un réflexe',
    confirm: 'Confirmer',
    use: 'Utilisation',
    description: 'Description',
    me: 'Moi'
  },
  menu: {
    incomingInvoices: 'Factures entrantes',
    dashboard: 'Réservations',
    items: 'Mes machines',
    billing: 'Décompte',
    myTeam: 'Mon team',
    members: 'Membres',
    employee: 'Collaborateurs',
    settings: 'Mes paramètres',
    reservations: 'Historique',
    newBilling: 'Nouveau décompte',
    publishedBillings: 'Confirmée',
    draftBillings: 'Brouillons',
    notifications: 'Notifications',
    reservationRequests: 'Demandes de réservation',
    discounts: 'Rabais',
    memberships: 'Appartenances',
  },
  localString: 'fr-CH',
  notifications: {
    overviewWidgetTitle: 'Notifications',
    all: 'Toutes les notifications '
  },
  changeUser: {
    changeToMe: 'Continuer en tant qu\' {user} ',
    permissionDenied: 'Le compte d\'utilisateur actuel ne vous autorise pas l\'accès à cette page.',
    changeUserModalTitle: 'Changer d\'utilisateur',
    changeUserModalExplanation: 'Avec quel utilisateur souhaitez-vous continuer?',
    selectUser: 'Recherche d\'utilisateur',
    changeUser: 'Changer d\'utilisateur'
  },
  invoice: {
    invoiceReceived: 'payée',
    pdfIsNotCreatedYet: 'Facture indisponible en PDF pour le moment. Réessayez ultérieurement.',
    copyPdfLink: 'Copier le lien PDF',
    downloadPdf: 'Télécharger le PDF',
    newDescription: 'Description',
    newAmount: 'Montant (CHF)',
    addCustomEntryModalTitle: 'Saisie d\'une nouvelle entrée de facture',
    receiver: 'Destinataire',
    sender: 'Expéditeur',
    invoice: 'Facture',
    invoiceNr: 'Facture N°',
    use: 'Utilisation',
    balance: 'Solde',
    pricePerUnit: 'Prix/Unité.',
    totalExklTaxes: 'Total sans TVA.',
    taxrate: 'TVA.',
    totalInklTaxes: 'Total TVA incl.',
    payConditions: 'Prière d\'acquitter le montant dû. Délai 30 jours net.',
    iban: 'IBAN',
    addOwnEntry: 'Ajouter une entrée',
    appUserMissing: 'Erreur inattendue : "AppUserInstance not available".',
    missingIban: 'IBAN non valide',
    provideIban: 'Complétez l\'IBAN dans vos paramètres.'
  },
  billing: {
    publishedCustomInvoiceTitle: 'Factures simples confirmées',
    addReceiver: 'Destinataire de facture',
    createNewCustomInvoiceModalTitle: 'Nouvelle facture simple',
    customInvoiceTitle: 'Brouillon facture simple',
    addNewCustomInvoice: 'Facture simple',
    isPdfReady: 'Statut PDF',
    invoicePdfIsReady: 'Prêt',
    pdfIsGenerating: 'En cours...',
    invoicePaid: 'Payée?',
    pdfIsNotCreatedYet: 'Facture indisponible en PDF pour le moment. Réessayez ultérieurement.',
    publishedBillingsTitle: 'Décompte confirmé',
    reallyConfirm: 'Etes-vous sûr?',
    reallyConfirmBillingExplanation: 'Vous êtes sur le point de confirmer le décompte. Les factures ne peuvent alors plus être traitées. ' +
      '<b>L\'action ne peut être annulée!</b>',
    showInvoices: 'Visualiser',
    showInvoice: 'Modifier',
    confirmBilling: 'Envoyer par mail',
    confirmInvoice: 'Confirmer',
    whatToDoModalTitle: 'Que voulez-vous faire?',
    deleteBilling: 'Supprimer',
    deleteInvoice: 'Supprimer',
    noSelectedReservations: 'Vous n\'avez pas choisi le mois de facturation.',
    selectedReservationsModalTitle: 'Vos réservations sélectionnées',
    showAllSelectedReservations: 'Afficher toutes les réservations pour la période sélectionnée.',
    noDraftBillings: 'Vous n\'avez aucune facture en mode brouillon.',
    billingDeleteExplanaition: 'Le décompte et les factures s\'y trouvant seront supprimés. Cette opération ne pourra être modifiée ultérieurement. Vous pouvez en tout temps créer un nouveau décompte.',
    draftState: 'Brouillon',
    receiver: 'Destinataire',
    billingDetailsTitle: 'Détails décompte',
    showMoreDetails: 'Afficher les détails du décompte',
    invoicesHeaderText: 'Décompte',
    Mwst: 'TVA',
    period: 'Période',
    billingNameTable: 'Décompte',
    doNotCorrect: '! Corriger avec le montant manquant!',
    newBillingError: 'Une erreur s\'est produite lors de la génération du décompte ',
    newBillingSuccessfull: 'Le décompte a été généré avec succès.',
    giveBillingMwst: 'Voulez-vous inclure la TVA dans le décompte?',
    billingMwst: 'TVA %',
    billingNameTitle: 'Nom du décompte',
    giveBillingAName: 'Donnez un nom à ce décompte',
    endUse: 'Utilisation définitive par le locataire',
    counterStart: 'Etat du compteur avant utilisation',
    counterEnd: 'Etat du compteur après utilisation',
    correct: 'Corriger le compteur',
    solveConflictModalTitle: 'Résoudre conflit de compteur',
    missingAmount: 'Montant manquant',
    draftBillingsTitle: 'Brouillon de décomptes',
    openReservationsToClose: 'Certaines réservations de la période de décompte souhaitée, n\'ont pas d\'utilisation indiquée. Vous devez d\'abord les clôturer.',
    renter: 'Locataire',
    reservationDate: 'Réservation',
    billingNameExplanaition: 'Changez le nom du décompte',
    billingName: 'Nom du décompte',
    complete: 'Générer le décompte',
    wantToConfirmCreationOfNewBilling: 'Souhaitez vous générer le décompte du {billingFrom} jusqu\'au {billingTo}?',
    noConflictingReservations: 'Très bien! Aucune réservation ne génère de conflit de compteur.',
    noOpenReservations: 'Très bien! Il n\'y a aucune réservation ouverte dans la période choisie. Vous pouvez continuer.',
    conflictingReservations: 'Conflit de compteur',
    timePeriod: 'Choisir une période',
    openReservations: 'Clôturer les réservations ouvertes',
    youHaveNoInvoiceReservations: 'Vous avez {count} réservations, qui doivent être facturées.',
    newBilling: 'Créer un décompte',
    totalChfWithoutDiscount: 'Total sans rabais (chf)',
    searchInvoice: 'Recherche par nom, prénom ou ville'
  },
  paginator: {
    loadMore: 'Charger plus',
    perPage: 'Eléments par page'
  },
  setAmount: {
    wantToSplitReservationTitle: 'Sûr?',
    wantToSplitReservationText: 'Souhaitez-vous vraiment répartir la réservation? Cette opération ne peut être modifiée ultérieurement!',
    splitReservation: 'Répartir la réservation',
    startCounterShort: 'Compteur début',
    endCounterShort: 'Compteur fin',
    amount: 'Insérer l\'utilisation',
    amountShort: 'Utilisation',
    hasNoCounterExplain: 'Combien avez-vous utilisé la machine?',
    endCounter: 'Fin réservation: Etat du compteur',
    startCounter: 'Début réservation: Etat du compteur',
    setCounters: 'Prière d\'insérer l\'état du compteur avant et après l\'utilisation dans les cases en-bas.',
    modalTitle: 'Insérer l\'utilisation',
    negativeValuesNotAllowed: 'Les valeurs négatives ne peuvent pas être saisies.',
    commentRequired: 'Dites au prestataire pourquoi vous n\'avez pas utilisé la machine.'
  },
  user: {
    machines: 'Parc machines',
    profile: 'Profil d\'utilisateur'
  },
  settings: {
    passwordIsNotUid: 'Le mot de passe ne peut être composé d\'un(e): courriel, prénom, nom, adresse, NPA, lieu ou no téléphone',
    passwordHasSmallLetter: 'Le mot de passe doit contenir au minimum une lettre minuscule.',
    passwordHasCapitalLetter: 'Le mot de passe doit contenir au minimum une lettre majuscule.',
    passwordHasSpecialCharacter: 'Le mot de passe doit contenir au minimum un caractère spécial.',
    passwordHasNumber: 'Le mot de passe doit contenir au minimum un chiffre.',
    passwordEqual: 'Les mots de passes doivent correspondre',
    passwordLength: 'Le mot de passe doit contenir au minimum 8 caractères.',
    password1: 'Insérez votre nouveau mot de passe',
    password2: 'Confirmez votre mot de passe',
    changePassword: 'Changer le mot de passe',
    company: 'Genre d\'exploitation',
    myMembersArePublic: 'Ma liste de membres est publique',
    mwst: 'TVA',
    iban: 'IBAN',
    moreOptions: 'Paramètres',
    headerTitle: 'Mes paramètres'
  },
  login: {
    autoLogin: 'Login automatique à l\'avenir (Pour ce faire nous enregistrons des cookies).',
    successfull: 'Vous vous êtes inscrit avec succès'
  },
  dashboard: {
    reservationIsNotConfirmed: 'En attente de la confirmation de réservation',
    noRequests: 'Vous n\'avez actuellement pas de demandes de réservation',
    closeOpenReservation: 'Clôturer la réservation',
    closeOpenReservationFirstModalTitle: 'Réservation ouverte',
    closeOpenReservationFirstInformation: 'Vous devez d\'abord clôturer la réservation',
    startReservation: 'Début de réservation',
    endReservation: 'Fin de réservation',
    toDebit: 'A la charge de',
    reservationSplitModalTitle: 'Répartir la réservation',
    splitReservation: 'Ou répartir la réservation',
    doYouWantToSendThisProposition: 'Voulez-vous envoyer cette proposition?',
    newReservationProposition: 'Faites une nouvelle proposition',
    deleteRequestModalTitle: 'Refuser la réservation?',
    reallyDeleteRequest: 'Voulez-vous vraiment refuser la réservation?',
    rejectRequestButton: 'Refuser',
    acceptRequestButton: 'Confirmer',
    editRequestButton: 'Nouvelle proposition',
    detailRequestModalTitle: 'Que voulez-vous faire?',
    reservationRequestsTitle: 'Demandes de réservation',
    youHaveOverlappingTimeSlots: 'Votre choix n\'est pas valable, car il chevauche d\'autres réservations.',
    editReservation: 'Modifier',
    reservationEditModalTitle: 'Traiter la réservation',
    doYouWantToDeleteTheReservation: 'Devons-nous réellement résilier la réservation?',
    reservationDeleteModalTitle: 'Réellement résilier?',
    cancel: 'Résilier',
    previousRenter: 'Locataire précédent',
    followingRenter: 'Locataire suivant',
    machineInstructions: 'Mode d\'emploi',
    reservationDetailsModalTitle: 'Détails de la réservation',
    endReservationModalTitle: 'Clôturer la réservation?',
    endReservationModalBody: 'Voulez-vous à présent rendre la machine et clôturer la réservation? Le locataire suivant va évent. être averti.',
    extendReservationModalTitle: 'Prolonger la réservation?',
    extendReservationModalBody: 'Devons-nous essayer de prolonger d\'une heure la réservation?',
    noUpcomingReservations: 'Vous n\'avez pas de réservations en attente',
    bringBackUntil: 'Ramener jusqu\'à',
    openReservations: 'Réservations ouvertes - Clôturez SVP!',
    yourCurrentReservations: 'Votre réservation en cours',
    upcommingReservations: 'Réservations en attente',
    makeReservation: 'Réserver une machine',
    closeReservation: 'Clôturer la réservation',
    machine: 'Machine',
    owner: 'Prestataire',
    resFrom: 'Réservation de',
    resTo: 'Réservation à',
    resUse: 'Utilisation',
    reservationRequestsFromLessor: 'Demandes de réservation (Je prends en location)',
    reservationRequestsFromRenter: 'Demandes de réservation (Je mets en location)',
    membershipRequests: 'Devenir membre',
    customNotifications: 'Message aux membres'
  },
  employee: {
    headerSearch: 'Ajouter collaborateur',
    myEmployee: 'Mes collaborateurs',
    deleteEmployeeExplanaition: 'Le collaborateur est exclu de votre team et perd ses différents droits comme p. ex. réserver une machine pour vous.',
    addModalTitle: 'Ajouter collaborateur à votre team?',
    addModalBody: 'Cette personne possèdera différents droits si vous l\'ajoutez à votre team. Elle pourra notamment réserver des machines à votre nom.'
  },
  reservation: {
    telOnly: 'Seulement par téléphone',
    telOnlyModalTitle: 'Contact du prestataire',
    gotToUserProfilLink: 'Visualiser compte utilisateur',
    failExplanation: 'Quelqu\'un vous à probablement devancé lors de la réservation. Réessayez avec une autre date',
    success: 'Réservation réussie',
    fail: 'Echec de réservation!',
    reservationCorrect: 'Réservation correcte?',
    getReservations: 'Réservations en cours de chargement',
    nextWithConnectedItem: 'Suivant',
    nextWithoutConnectedItem: 'Suivant sans combinaison',
    removeConnectedItem: 'Désactiver',
    addConnectedMachine: 'Combiner',
    connectedMachineTitle: 'Cette machine se combine facilement!',
    myTeamsTab: 'Communautés de machines',
    myFavoritesTab: 'Favoris',
    advancedSearch: 'Recherche avancée',
    myMachines: 'Mes machines',
    category: 'Catégorie',
    fromAdvanced: 'De',
    toAdvanced: 'A',
    distance: 'Rayon',
    myLocation: 'De ma position dans un rayon de',
    searchList: 'Parcourir la liste',
    tooBigRadiusAndSelectCategory: 'Choisissez une catégorie. Le rayon ne peut excéder 100 km.',
    noItemsFound: 'Aucune machine à afficher.',
    location: 'Emplacement de la machine',
    price: 'Prix',
    reservation: 'Réserver',
    addItemAsFavorite: 'Ajouter la machine aux favoris (cliquer)',
    removeItemFromFavorite: 'Supprimer la machine des favoris (cliquer)',
    searchForItems: 'Cherchez une machine que vous désirez louer',
    owner: 'Prestataire',
    showReservation: 'Voir la réservation'

  },
  calendar: {
    reservationsOn: 'Réservations le: ',
    reservedBy: 'Réservée par ',
    weekDayNames: {
      mo: 'lu',
      tu: 'ma',
      we: 'me',
      th: 'je',
      fr: 've',
      sa: 'sa',
      su: 'di'
    },
    monthNames: {
      january: 'janvier',
      february: 'février',
      march: 'mars',
      april: 'avril',
      may: 'mai',
      june: 'juin',
      july: 'juillet',
      august: 'août',
      september: 'septembre',
      october: 'octobre',
      november: 'novembre',
      december: 'decembre'
    }
  },
  items: {
    telOnly: 'Cette machine peut être réservée uniquement par téléphone',
    myMachines: 'Mes machines',
    addNew: 'Mettre en location',
    title: 'Titre',
    addImgTitle: 'Ajouter une photo',
    itemDescription: 'Description de la machine',
    categories: 'Choisir la catégorie *',
    rules: 'Imposer des règles',
    maxDistance: 'Distance max. de location (km)',
    maxTime: 'Temps de réservation max. (h)',
    reservationValidation: 'Je dois confirmer chaque réservation',
    hasCounter: 'La machine dispose d\'un compteur',
    location: 'Emplacement de la machine',
    teamOnly: 'Mettre en location ma machine uniquement à mes membres ',
    priceTeam: 'Prix pour mes membres',
    priceGlobal: 'Prix pour non-membres',
    pricePerUnit: 'Prix à l\'unité *',
    active: 'Statut de la machine',
    showOptions: 'Afficher plus d\'options',
    disableOptions: 'Masquer les options',
    connectMachinesTitle: 'Combiner cette machine',
    connectMachineSelect: 'Mettre en location cette machine en combinaison avec...',
    reallyDelete: 'Supprimer?',
    reallyDeleteDescription: 'Cette machine sera supprimée et retirée de votre profil ! Ceci ne peut pas être annulé.',
    deactivateItem: 'L\'annonce va être désactivée',
    updateItem: 'Actualiser la machine',
    table: {
      name: 'Nom',
      title: 'Titre',
      address: 'Adresse'
    },
    largerThanZeroRequired: 'Doit être supérieur à 0'
  },
  teams: {
    addHeader: 'Ajouter un membre',
    myTeam: 'Mes membres',
    searchPlaceholder: 'Chercher par prénom, nom, NPA ou lieu',
    searchResultsHeader: 'Résultats de recherche',
    reallyAddTeam: 'Voulez-vous ajouter le team de cette personne à votre team?',
    addTeamExplanaition: 'Si vous effectuez cette opération, les membres du team de cette personne seront ajoutés à votre team. Cette opération ne peut être modifier ultérieurement!',
    deleteMemberExplanaition: 'Vous supprimez ainsi la personne de votre team. Vous pourrez lui envoyer une nouvelle demande d\'adhésion ultérieurement.',
    reallyAddTitle: 'La demande doit être confirmée par la personne.',
    reallyAddBody: 'La personne doit d\'abord confirmer l\'adhésion que vous avez demandée avant que celle-ci ne devienne active. Si vous l\'ajoutez, cette personne pourra louer vos machines et bénéficier de vos tarifs membres si existants.',
    memberPrivate: 'La liste des membres est masquée'
  },
  notify: {
    invoicePublished: 'La facture a été éditée',
    invoiceNotPublished: 'La facture n\'a pas pu être éditée',
    invoiceSuccessfullyDeleted: 'La facture a été supprimée',
    invoiceNotDeleted: 'Nous n\'avons pas pu supprimer la facture',
    customEntryDeleted: 'L\'emplacement a été supprimé',
    customEntryNotDeleted: 'L\'emplacement n\'a pas pu être supprimé',
    endCounterIsSmallerThanStartCounter: 'Le compteur fin ne peut pas être plus petit que le compteur début',
    amountHasToBeHigherThanZero: 'Le montant doit être plus grand que 0',
    toHighAmount: 'Le montant est trop grand',
    isLastEndCounter: 'Vous ne pouvez pas répartir davantage la réservation',
    selectUser: 'Choisissez une personne',
    endCounterToHigh: 'Le montant du compteur est trop élevé',
    allreadyBilled: 'La réservation a déjà été facturée et ne peut plus être répartie.',
    splittingSuccess: 'La répartition de la réservation a été effectuée avec succès',
    splittingFailed: 'La répartition de la réservation a échoué.',
    newMessage: 'Vous avez de nouveaux messages!',
    messagesNotLoaded: 'Les messages n\'ont pas pu être chargés',
    contactDataCouldNotBeLoaded: 'Les coordonnées n\'ont pas pu être chargées',
    newRequestSent: 'Votre nouvelle proposition a été envoyée',
    requestDeclined: 'La réservation a été refusée',
    selectTimeSpan: 'Vous devez définir une période',
    minimumOneDay: 'Vous devez définir une date',
    reservationsCouldNotBeLoaded: 'Nous n\'avons pas pu charger la réservation.',
    requestSuccessfullyConfirmed: 'La réservation a été confirmée avec succès.',
    reservationWasSucessfullyUpdated: 'Nous avons pu modifier votre réservation avec succès',
    reservationwasNotUpdated: 'Nous n\'avons malheureusement pas pu modifier votre réservation',
    reservationDeleted: 'Nous avons résilié la réservation.',
    counterCanNotBeChangedBecauseOfReservations: 'Le compteur ne peut pas être modifié en raison de réservations existantes.',
    billingSuccessfullyPublished: 'Le décompte a été confirmé.',
    customInvoiceEntrySuccessfullAdded: 'La facture a été complétée avec votre élément.',
    invoiceGenerationFailed: 'Nous n\'avons pas pu générer la facture.',
    monthIsNotInPast: 'Seuls les mois précédant le mois actuel peuvent être sélectionnés.',
    monthHasToBeCoherent: 'Les mois sélectionnés doivent se suivre.',
    isNotSelectable: 'Ce mois ne peut pas être sélectionné car il est déjà clôturé',
    yearNotLoaded: 'Nous n\'avons pas pu charger les données de l\'année souhaitée',
    deleteBillingSuccess: 'Nous avons supprimé le décompte avec succès',
    deleteBillingFail: 'Le décompte n\'a pas pu être supprimé. Une erreur s\'est produite.',
    aMonthHasToBeSelected: 'Vous devez au minimum choisir un mois.',
    hasConflicts: 'Vous devez d\'abord résoudre les conflits de compteur.',
    closeOpenReservationsFirst: 'Vous devez d\'abord clôturer toutes les réservations ouvertes. Pour cela, cliquez sur une réservation.',
    reservationEnd: 'Nous avons résilié la réservation',
    amountSetSuccessfully: 'L\'entrée a réussi ',
    amountSetNotSuccessfull: 'Nous n\'avons malheureusement pas pu clôturer la réservation',
    passwordChangeFailed: 'Nous n\'avons pas pu modifier le mot de passe',
    passwordChanged: 'Nous avons modifié votre mot de passe',
    userDataNotLoaded: 'Les données d\'utilisateur n\'ont pas pu être modifiées',
    userUpdateFailed: 'Nous n\'avons pas pu enregistrer les modifications',
    userUpdated: 'Les modifications ont été enregistrées',
    reservationExtendMaxTime: 'La durée de la réservation de la machine est limitée. Vous devez créer une nouvelle réservation.',
    reservationExtendHasFollowingReservation: 'La machine a été réservée par une autre personne après vous.',
    reservationExtendNeedValidation: 'La prolongation de la réservation doit auparavant être confirmée par son prestataire.',
    reservationExtendTitle: 'La réservation n\'a pas pu être prolongée',
    unkonwnError: 'Désolé! Une erreur inconnue est apparue',
    reservationExtended: 'Nous avons prolongé votre réservation.',
    upcomingReservationsNotLoaded: 'Les prochaines réservations n\'ont pas pu être chargées',
    openReservationsLoadFail: 'Nous n\'avons malheureusement pas pu charger vos réservations ouvertes',
    unwantedReservations: 'Nous n\'avons pas pu annuler une de vos réservations. Vérifiez vos réservations.',
    overlappingSlots: 'Vous avez sélectionné des périodes déjà occupées. Action impossible',
    teamAdded: 'Les membres ont été ajoutés à votre liste de membres',
    teamNotAdded: 'Ce team n\'a pas pu être ajouté',
    imageDeleted: 'Nous avons supprimé la photo',
    imageNotDeleted: 'Nous n\'avons pas pu supprimer la photo',
    searchNotSuccessfull: 'Votre recherche n\'a donné aucun résultat.',
    error: 'Erreur: ',
    noTeam: 'Aucun membre du team trouvé',
    memberDeleted: 'Exclusion du membre du team réussie',
    memerDeleteFailed: 'Le membre du team n\'a pas pu être exclu',
    itemAdded: 'La machine a été ajoutée',
    itemNotAdded: 'La machine n\'a pas pu être ajoutée',
    itemUpdated: 'Les modifications ont été enregistrées',
    itemNotUpdated: 'La machine n\'a pas pu être actualisée',
    itemsLoadFail: 'Aucune machine n\'a pu être chargée',
    itemLoadFailed: 'Nous n\'avons pas pu charger la machine',
    categoriesLoadFail: 'La catégorie n\'a pas pu être chargée',
    tooLargeRadius: 'Le rayon de recherche sélectionné est trop grand',
    startdateGreaterThanEnddate: 'La date initiale est après la date finale',
    itemDelted: 'La machine a été supprimée',
    employeesNotLoaded: 'Les collaborateurs n\'ont pas pu être chargés',
    employeeNotDeleted: 'Le collaborateur n\'a pas pu être supprimé de votre liste des collaborateurs',
    employeeNotAdded: 'Ce collaborateur n\'a pas pu être ajouté',
    imagesNotLoaded: 'Les photos n\'ont pas pu être chargées',
    imageNotUploaded: 'Nous n\'avons pas pu télécharger la photo',
    imageUploaded: 'La photo a été téléchargée',
    favoriteAdded: 'Cette machine appartient désormais à vos favoris',
    failFavoriteAdd: 'Nous n\'avons malheureusement pas pu enregistrer la machine dans vos favoris',
    favoriteRemoved: 'Nous avons supprimé la machine de vos favoris',
    failFavoriteRemove: 'La machine n\'a pa pu être supprimée de vos favoris',
    connectedItemsNotLoaded: 'Un problème est apparu lors du chargement des combinations de machines'
  },
  spinner: {
    loadingReservationsOfTheYear: 'Nous chargeons l\'année souhaitée',
    getPreviousCounter: 'Nous cherchons le précédent état du compteur pour vous',
    loadingUserData: 'Profil d\'utilisateur en cours de chargement',
    imageUpload: 'Photo en cours de téléchargement. Patience SVP.',
    pleaseWait: 'Patience SVP',
    login: 'Connexion.',
    dataLoading: 'Les données sont en cours de chargement.',
    search: 'Nous cherchons pour vous',
    itemDelete: 'La machine sera supprimée',
    deleteEmployee: 'Le collaborateur sera supprimé de votre liste',
    addEmployee: 'Le collaborateur sera ajouté à votre liste',
    addFavorite: 'La machine sera ajoutée à vos favoris',
    removeFavorite: 'Nous supprimons la machine de vos favoris',
    deleteImage: 'Nous supprimons la photo. Un instant SVP.'
  },
  multiselect: {
    noResult: 'Désolé! Aucun résultat trouvé.',
    selectLabel: 'Ajouter',
    deselectLabel: 'Désélectionner',
    selectedLabel: 'Sélectionné'
  },
  pictureInput: {
    drag: 'Cliquez ici pour télécharger une photo <br> ou tirer l\'image ici',
    plus: '+',
    upload: 'Ajouter une photo'
  },
  _comment: {
    'last time sent for translation': '05.08.2018'
  },
  v2: {
    dayIsInPast: 'Vous ne pouvez pas réserver dans le passé',
    invoiceMarked: 'La facture a été marquée comme étant payée',
    invoiceNotMarked: 'La facture n\'a pas pu être marquée comme étant payée',
    setPrices: 'Définir les prix',
    assuranceLabel: 'Entrer le type d\'assurance',
    assurance: 'Machine assurée?',
    manual: 'Manuel d\'utilisation',
    itemInformations: 'Plus d\'informations',
    immediatelyReservation: 'Immédiatement louable?',
    noAssurance: 'Aucune assurance spécifiée',
    unlimitedMaxReservationTime: 'Aucune limite',
    unlimitedMaxReservationDistance: 'Aucune limite',
    maxReservationTimeLimitReached: 'La machine a un temps de réservation max.',
    minimumSelectOneDay: 'Veuillez choisir au moins un jour',
    maxReservationLimitExeeded: 'Vous dépassez le temps max. de la réservation.',
    ofTotalAmount: 'Au total {totalAmount} {unit} réparti',
    splitNow: 'Répartir la réservation',
    splitReservationModalTitle: 'Répartir la réservation?',
    reallySplitReservationModalText: 'Voulez-vous répartir cette réservation avec d\'autres agriculteurs?',
    responsible: 'Responsable',
    responsibleCouldNotBeLoaded: 'Le responsable de la machine n\'a pas pu être chargé',
    noItemsResponsibleFor: 'Vous ne possédez pas de machines tierces dont vous êtes responsable..',
    responsibleMenu: 'Responsable',
    myItemsMenu: 'Mes machines',
    itemsMenu: 'Machines',
    responsibleWidgetHeader: 'Responsable des machines',
    autologinFailed: 'Nous n\'avons pas pu vous connecter automatiquement..',
    baseFee: 'Tarif de base par réservation',
    baseFeeShort: 'Tarif de base',
    paid: 'Payé?',
    counterCoefficient: 'Facteur de conversion',
    counterState: 'Etat du compteur',
    counterStatEquals: 'Etat du compteur 1 correspond à',
    capture: 'Relevé',
    toClose: 'Pour clôturer',
    conflicts: 'Conflits',
    missingAmountTableHeader: 'Montant manquant',
    conflictingReservationsWidgetHeader: 'Conflits de compteur',
    resetPwTitle: 'Réinitialiser le mot de passe',
    welcomeOnFarme: 'Bienvenu sur FarmX',
    mail: 'E-Mail',
    password: 'Mot de passe',
    login: 'Login',
    mobileNumber: 'Mobile',
    rent: 'Mettre en location',
    lease: 'Prendre en location',
    clickedDayHasToBeLinked: 'Les jours sélectionnés doivent se suivre!',
    timeFor: 'Temps pour le',
    allDay: 'Jour entier',
    higherTimeNotPossible: 'Vous avez atteint l\'heure de réservation maximale.',
    minDayTimeLimitReached: 'Vous ne pouvez pas aller en dessous de ce temps.',
    startReservationOn: 'Début de la réservation le',
    endReservationOn: 'Fin de la réservation le',
    reservationTime: 'Temps de réservation: {days} jours et {hours} heures',
    maxReservationTimeExeeded: 'La durée maximale de réservation est dépassée de {hours} heures!',
    isReservationRequestCorrect: 'Voulez-vous envoyer la nouvelle proposition de réservation?',
    notificationType: 'Notifications par e-mail',
    mailCode: 'Entrez le code de l\'e-mail ici',
    setNewPassword: 'Définissez votre nouveau mot de passe',
    mailCodeExplain: 'Nous vous avons envoyé un e-mail avec un lien de sécurité. ' +
      'Ouvrez-le pour définir un nouveau mot de passe. Conseil : vérifiez également vos spams si vous n\'avez pas reçu ' +
      'de message de notre part.',
    pwAlreadyResetted: 'Le mot de passe a déjà été réinitialisé ! Vérifiez vos courriels.',
    mailOrMobileWrong: 'E-mail ou numéro de téléphone incorrect',
    loginProblem: 'La connexion n\'a pas réussi. Assurez-vous que vos informations soient correctes.',
    editCounterNotPossibleAlreadyBilled: 'Le compteur ne peut plus être modifié car la réservation a déjà été facturée.',
    commentHere: 'Tapez votre message ici',
    sendMessageToOwner: 'Envoyer un message au prestataire',
    senderWroteToReservation: 'a écrit à propos de cette réservation',
    messageToReservation: 'Message du locataire',
    counterUnits: 'Unités de compteur ',
    setCounterForOtheruser: 'Vous êtes sur le point d\'entrer l\'utilisation de {firstName} {name}.',
    withinRadius: 'Dans un rayon de',
    stayLoggedIn: 'Rester connecté',
    formNotComplete: 'Vous devez remplir tous les champs marqués d\'un *',
    plzDoesNotExist: 'Ce code postal n\'existe pas.',
    plzNotValid: 'Ce code postal n\'est pas valable.'
  },
  v3: {
    unitsNotLoaded: 'Les unités n\'ont pas pu être chargées',
    yourFeedback: 'Donnez votre avis sur FarmX',
    feedBackTitle: 'FarmX est en phase de test',
    foundABug: 'Avez-vous trouvé une erreur ? Ou souhaitez-vous nous envoyer une suggestion d\'amélioration ?',
    dontHesitate: 'N\'hésitez pas à nous contacter.',
    thanksForTheSupport: 'Merci de votre soutien!'
  },
  v4: {
    reservationOverview: 'Aperçu des réservations par des tiers',
    actualizeApp: 'Actualiser l\'app',
    reservationValidation: 'Je souhaite confirmer les demandes de réservations',
    confirmedReservation: 'Réservation confirmée',
    unconfirmedReservation: 'Réservation non confirmée',
    hint: 'Indication',
    hintExplanaition: 'La demande de réservation ne peut pas être confirmée. Une autre réservation existe déjà pour la période souhaitée.',
    reservationOverviewNotLoaded: 'L\'aperçu des réservations n\'a pas pu être chargé.',
    noReservations: 'Aucune réservation',
    showMore: 'Afficher davantage',
    setStartCounterModalTitle: 'Saisir le compteur début',
    startCounterRequired: 'Saisissez la valeur du compteur.',
    startCounterCaptured: 'Compteur enregistré avec succès.',
    extend: 'prolonger',
    countDownNotPossible: 'La prolongation doit être d\'au moins une demi-heure (0.5).',
    countUpNotPossible: 'Vous ne pouvez pas prolonger davantage la réservation.',
    hasNextReservation: '{user} a réservé la machine le {date} à {time}.',
    hasMaxTime: 'La machine peut être louée maximum {time} heures.',
    extendNotPossible: 'Prolongation impossible',
    close: 'fermer',
    moreDetails: 'Autres données',
    cantonalOperationNumber: 'N° cantonal d\'exploitation',
    startCounterOnlyExplanation: 'Saisissez le compteur de départ avant l\'utilisation de la machine',
    changeReservation: 'Modifier',
    noManual: 'Pas d\'instructions disponibles',
    noPreviousRenter: 'Pas de locataire précédent',
    noFollowingRenter: 'Pas de locataire suivant',
    locationNotFound: 'Adresse introuvable sur la carte.',
    editLocation: 'Adapter la localisation sur la carte (Fonctionnalité en développement)',
    counterDiffer: 'Votre saisie diffère du compteur finale de la réservation précédente.',
    today: 'aujourd\'hui',
    selectStartDate: 'Saisissez la date de début',
    selectEndDate: 'Saisissez la date de fin',
    reservations: 'Réservations',
    blockMachine: 'Bloquer la machine',
    reservationsCouldNotBeenLoaded: 'Les réservations n\'ont pas pu être chargées.',
    blockedByOwner: 'Bloqué',
    hasReservationsInTheMiddle: 'Votre sélection ne peut être interrompue par d\'autres réservations. Nous avons adapté votre sélection.',
    doYouWantBlockTheItem: 'Voulez-vous bloquer la machine?',
    blockingTime: 'Période de blocage: {days} jours et {hours} heures',
    doYouWantToDeactivateItem: 'Activer l\'annonce?',
    activateExplanation: 'est pour le moment désactivée et ne peut être réservée. Voulez-vous activer l\'annonce?',
    blockTime: 'Bloquer la période sélectionnée',
    deactivateUndefined: 'Désactiver pour une période indéterminée',
    activateItem: 'activer',
    itemIsActuallyDeactivated: 'L\'annonce est pour le moment désactivée.',
    deleteBlocking: 'Mettre fin au blocage',
    deleteWholeBlockingPeriod: 'Suppression de la période de blocage',
    blockingDeleted: 'Le blocage a été supprimé'
  },
  v5: {
    menuAdmin: 'Administrateur',
    captureCustomer: 'Saisir',
    eMailRequired: 'Veuillez indiquer votre adresse E-Mail',
    isNotEmail: 'Adresse E-Mail non valable',
    register: 'S\'enregistrer',
    nameRequired: 'Indiquez votre nom de famille',
    firstNameRequired: 'Indiquez votre prénom',
    mailRequired: 'E-Mail *',
    firstNameFormRequired: 'Prénom *',
    nameFormRequired: 'Nom *',
    addressRequired: 'Indiquez votre adresse',
    addressFormRequired: 'Adresse *',
    zipRequired: 'Indiquez votre numéro postal',
    zipFormRequired: 'NPA *',
    cityRequired: 'Indiquez votre localité',
    cityFormRequired: 'Localité *',
    pwRequired: 'Indiquez un mot de passe',
    setPassword1: 'Mot de passe *',
    setPassword2: 'Répétez le mot de passe *',
    mobileRequired: 'Indiquez votre numéro de mobile.',
    mobileFormRequired: 'Mobile *',
    telForm: 'Fixe',
    ibanForm: 'Coordonnées IBAN (pour facturation)',
    newUserSuccessfullyRegistered: 'L\'utilisateur a été correctement enregistré. Vous pouvez à présent vous loguer et confirmer votre n° de mobile.',
    addNewCustomer: 'Ajouter un nouvel utilisateur',
    selectLanguage: 'Choisir la langue *',
    languageRequired: 'Veuillez sélectionner la langue souhaitée par l\'utilisateur.',
    registration: 'Vous n\'avez pas encore de compte FarmX? Enregistrez-vous gratuitement!',
    resetPwHelpText: 'Mot de passe oublié? Indiquez votre E-Mail et votre mobile. Nous vous enverrons ensuite un email pour réinitialiser votre mot de passe .',
    newPassword: 'Nouveau mot de passe',
    userAlreadyExist: 'Ce nom d\'utilisateur existe déjà!',
    pwTooWeak: 'La sécurité du mot de passe est trop faible!',
    invalidZip: 'Ce NPA n\'existe pas!',
    reCaptchaNotValid: 'Il semble que vous essayez de piéger notre application.',
    registerSuccess: 'Enregistré avec succès. Vous pouvez à présent vous loguer.',
    wrongCredentials: 'L\'E-Mail ou le mot de passe n\'est pas correct.',
    userLocked: 'Votre compte d\'utilisateur a été bloqué. Veuillez vous adresser au support.',
    mobileVerificationModalTitle: 'Confirmez votre identité',
    mobileVerificationCodeRequired: 'Veuillez saisir votre code SMS.',
    mobileVerificationCodeFormLabel: 'Saisir votre code SMS ici',
    validateMobileNumber: 'vérifier',
    wrongMobileToken: 'Code SMS erroné',
    checkMobileToken: 'Veuillez vérifier si le code saisi est juste',
    addNewItem: 'Ajouter une nouvelle annonce',
    itemTitle: 'Nom (par ex. John Deere 6410) *',
    itemTitleRequired: 'Ajoutez le titre de l\'annonce.',
    itemDescription: 'Description (par ex. 110 CV, relevage frontal, ...) *',
    itemDescriptionRequired: 'Veuillez indiquez un descriptif pour votre annonce.',
    selectLabel: 'Choisir la ou les catégories *',
    categoryRequired: 'Sélectionner au moins une catégorie',
    privateAmount: 'Prix pour membres (CHF) *',
    privateAmountRequired: 'Indiquez le prix pour vos membres (CHF).',
    globalItemPriceRequired: 'Indiquez le prix pour les non-membres (CHF)',
    globalItemPrice: 'Prix pour non-membres (CHF) *',
    unitRequired: 'Sélectionnez l\'unité',
    selectVat: 'Taux de TVA *',
    selectVatRequired: 'Sélectionnez le taux de TVA applicable',
    location: 'Localisation',
    prices: 'Prix',
    options: 'Options',
    conflictsNotLoaded: 'Les conflits n\'ont pas pu être affichés',
    user: 'Utilisateur',
    solveConflict: 'résoudre',
    conflictInfo: 'Cliquez sur les valeurs de compteurs pour les corriger. Cliquez ensuite sur "résoudre" pour régler le conflit de compteur.',
    countConflicts: '1 conflit | {count} conflits',
    reallySolveConflict: 'Les compteurs affichent une différence!',
    reallySolveConflictText: 'Voulez-vous régler le conflit malgré la différence?',
    enterAmount: 'Veuillez sélectionner le taux applicable',
    descriptionRequired: 'Veuillez entrer un descriptif pour le libellé de la facture',
    selectDate: 'Définir la date',
    minFee: 'Prix minimum par réservation (CHF)',
    cancellationPoliciesCouldNotBeenLoaded: 'Les conditions d\'annulation n\'ont pas pu être chargées. Veuillez réessayer plus tard.',
    vatPriceExplanation: 'Les prix s\'entendent toujours TVA incluse.'
  },
  v6: {
    minFeePerReservation: 'Montant min./réserv.',
    cancellationPolicy: 'Conditions d\'annulation',
    noMemberTarif: 'Tarif non-membres',
    memberTarif: 'Tarif membres',
    minFeeTimeLeft: 'Vous pouvez annuler la réservation encore pendant environ {timeLeft}.',
    minFeeHasToBePaid: 'La réservation ne pourra plus être annulée. Un montant minimal de {minFee} CHF vous sera facturé.',
    minFeeShort: 'Montant minimum',
    cancelledThe: 'Annulé le',
    totalVat: 'Total TVA',
    from: 'de',
    customer: 'client',
    vat: 'Taux de TVA',
    editCustomer: 'Modifier',
    supporCustomer: 'Support',
    editVat: 'TVA',
    items: 'Machines',
    editUnits: 'Unités',
    titleRequired: 'Indiquez une formule de salutations (Madame, Monsieur)',
    firstNameFormRequired: 'Civilité (Madame, Monsieur) *',
    pleaseConfirmMobile: 'Veuillez confirmer votre n° de mobile',
    farmxConfirmationReason: 'Sur FarmX, l\'identité de chaque utilisateur est vérifiée. Grâce à cela, FarmX vous protège des personnes ayant des intentions frauduleuses.',
    enterSmsCode: 'Veuillez saisir ici le code que nous vous avons envoyé par SMS',
    mobileTokenResent: 'Le code vient d\'être envoyé une nouvelle fois. Merci de patienter quelques instants.',
    mobileTockenResendingFailed: 'Le code n\'a pas pu être envoyé. Veuillez vérifier votre n° de mobile',
    resendCode: 'Envoyer à nouveau le code SMS',
    mobileCorrect: 'Le n° de mobile est-il correct?',
    testThreeMonthForFree: 'Testez FarmX gratuitement et sans engagement durant 3 mois.',
    page: 'Page',
    overview: 'Aperçu',
    manageCustomers: 'Gérer les clients',
    inputRequired: 'Saisie nécessaire',
    searchForUsers: 'Aucune correspondance trouvée. Dans le champ de recherche, saisissez soit le nom complet, le prénom complet, le NPA ou la localité',
    customerSingular: 'Client',
    missingPermission: 'Accès refusé'
  },
  v7: {
    signInAsCustomer: 'Se connecter en tant que client',
    signInReason: 'Raison du login',
    loginReasonRequired: 'Indiquez une raison pour vous loguer',
    ticketNumber: 'N° du ticket',
    ticketNumberRequired: 'Vous devez indiquer un n° de ticket pour vous loguer',
    tooManyRequests: 'Accès refusé. Vous vous êtes connecté trop souvent sous cet utilisateur.',
    loginAsCustomerSuccessfully: 'Connexion réussie en tant que client. Veuillez continuer l\'édition dans une nouvelle fenêtre.',
    copyUrlModalTitle: 'Copier l\'URL',
    urlCopiedSuccessfully: 'L\'URL a été copié dans le presse-papier',
    copyLink: 'Copiez l\'URL dans une nouvelle fenêtre de votre navigateur pour vous connecter.',
    fifteenMinutes: 'Ce lien est valable durant <span class="font-weight-bold">15 minutes</span>.'
  },
  v8: {
    toContinue: 'Pour poursuivre, vous devez',
    closeWindow: 'Fermer cette fenêtre',
    getNewLink: 'Demander un nouveau lien pour cet utilisateur dans votre onglet administrateur.',
    securityReasons: 'Cette mesure est nécessaire pour des raisons de sécurité.',
    clickRecaptcha: 'Veuillez confirmer que vous n\'êtes pas un robot.',
    gtcCouldNotBeenLoaded: 'Les CGU n\'ont malheureusement pas pu être chargées. Veuillez rééssayer ultérieurement.',
    acceptGtc: 'J\'ai lu les CGU et la politique des cookies de FarmX et les approuve.',
    showAgb: 'Les lire',
    acceptGtcRequired: 'Veuillez approuver les Conditions générales d\'utilisation CGU de FarmX.',
    gtcModalTitle: 'CGU FarmX',
    agreeGtc: 'Approuver',
    rejectGtc: 'Refuser',
    gtcCouldNotBeAccepted: 'Une erreur est intervenue lors de l\'acceptation des CGU. Merci de rééssayer ultérieurement.',
    welcomeToFarmX: 'Bienvenue',
    setPassword: 'Définissez un mot de passe',
    why: 'Pourquoi',
    sendSmsCode: 'Envoyer le code-SMS',
    downloadExcel: 'Extraire les données sous forme Excel',
    selectRadius: 'Indiquez le rayon sous 100 km',
    freeTestingTillEndOfJune: 'FarmX est gratuit pour les locataires',
    onlyForMembers: 'Location de la machine limitée aux membres ou la machine trop loin de chez vous',
    siteIsLoading: 'Chargement de la page',
    passwordHasNumberOrSpecialCharacter: 'Le mot de passe doit contenir au moins un chiffre ou un caractère spécial.',
    hasSmallAndCapitalLetter: 'Le mot de passe doit contenir au moins une majuscule et une minuscule.',
    tabUpcomingRent: 'Planning',
    tabAll: 'Archives',
    selectDateToGetOverview: 'Choissisez une période pour visulaliser l\'aperçu des réservations.',
    loginStatus: 'Status Registration',
    entireWordNeeded: 'Insérer un mot complet (ex. Bienne ou Berger).',
    userAlreadyAdded: 'Cet utilisateur a déjà été ajouté à votre liste.',
    userCouldNotBeenLoaded: 'Cet utilisateur n\'a pas pu être ajouté.',
    senderHasWrittenNotification: '{sender} vous a envoyé un message le {date} à {time}',
    endReservationProcess: 'Fermer'
  },
  v9: {
    overtake: 'Prise en main',
    itemReturn: 'Retour',
    captureUse: 'Terminer l\'utilisation',
    deviceRegisteredSuccessfully: 'Les notifications push ont été configurées sur votre app.',
    deviceCouldNotBeRegistered: 'Une erreur inattendue s\'est produite lors de l\'enregistrement de votre app.',
    appNotification: 'Notifications Push (Smartphone)',
    chooseYourPw: 'Veuillez définir un mot de passe.',
    startTimeMustBeSmallerThanEndTime: 'L\'heure de début ne peut pas être plus tardive que l\'heure de fin.',
    endTimeMustBeBiggerThanStartTime: 'L\'heure de fin ne peut pas être plus tôt que l\'heure de début',
    earlierNotPossible: 'Pas possible plus tôt.',
    laterNotPossible: 'Pas possible plus tard!',
    startBlockingPeriodOn: 'Début du blocage',
    endBlockingPeriodOn: 'Fin du blocage',
    blockingWasDeleted: 'Blocage annulé',
    overlapingSelection: 'Vous ne pouvez pas sélectionner durant une période déjà réservée ou bloquée. Veuillez modifier votre période de réservation.',
    generalDatabaseError: 'Un problème est survenu avec la base de données. Veuillez vous adresse au support info-fr@farmx.ch',
    dataTooLongForColumn: 'La saisie a échoué car trop longue. Il s’agit d’un problème. Veuillez en informer le support info-fr@farmx.ch',
    userTriesToEditSomeBodeyElsesReservation: 'Vous tentez de modifier une réservation qui ne vous concerne pas. Il s’agit d’un problème. Veuillez en informer le support info-fr@farmx.ch',
    userNameDoesNotExist: 'Ce nom d\'utilisateur n\'existe pas. Il s’agit d’un problème. Veuillez en informer le support info-fr@farmx.ch',
    updateFailed: 'Cette réservation n\'a pas pu être actualisée. Il s’agit d’un problème. Veuillez en informer le support info-fr@farmx.ch',
    updatedInactiveFieldAsNonAdmin: 'Vous tentez de modifier une réservation concernant une machine retirée. Il s’agit d’un problème. Veuillez en informer le support info-fr@farmx.ch',
    timeLimitReached: 'Vous dépassez la limite de réservation de cette machine. Veuillez rééssayer une nouvelle fois ou veuillez en informer le support info-fr@farmx.ch',
    triedToExtendReservation: 'Une erreur technique est apparue. Veuillez vous adresser au support info-fr@farmx.ch',
    triedToUpdateAlreadyBilledReservation: 'Vous ne pouvez pas modifier de réservations déjà closes, veuillez vous adresser au support info-fr@farmx.ch',
    validationNeededFirst: 'La réservation doit d\'abord être confirmée. Veuillez attendre la réponse du propriétaire ou du responsable de la machine.',
    reservationIsInPast: 'Vous ne pouvez pas saisir de réservation sur une période écoulée. Il s’agit d’un problème. Veuillez en informer le support info-fr@farmx.ch',
    maxDistanceLimitExeeded: 'La machine dépasse la distance maximale de réservation. Veuillez vous adresser au propriétaire ou au responsable de la machine.',
    waitForValidation: 'La réservation doit encore être approuvée par le propriétaire/responsable de la machine. Vous recevrez une réponse dans les 24 heures.',
    tokenExpiredSentMail: 'Le lien n\'est plus valable, pour des raisons de sécurité. Nous vous avons renvoyé un lien sur votre adresse email.',
    tokenExpired: 'Le lien est échu.'
  },
  v10: {
    bookNow: 'Réserver maintenant',
    appStatisticsCouldNotBeLoaded: 'Les statistiques de l\'application n\'ont pas pu être chargées.',
    appStatistics: 'Statistiques de l\'application',
    searchItems: 'Rechercher une machine',
    noInnercircleItems: 'Vous n\'êtes membre d\'aucune exploitation ou communauté de machine. Aucune machine disponible.',
    noFavoriteItems: 'Aucune machine définie dans vos favoris jusqu\'ici.',
    addFirstItem: 'Publiez votre première machine sur FarmX en quelques clics.',
    captureNow: 'Saisir',
    noAdvancedSearchResults: 'Votre recherche n\'a donné aucun résultat.',
    helpToImproveFarmX: 'Möchten Sie stattdessen eine Ihrer Maschinen besser auslasten?',
    onlineNow: 'Publier maintenant',
    useRequired: 'Veuillez saisir l\'utilisation.',
    completeForm: 'Merci de remplir tous les champs',
    itWas: 'Compteur initial',
    reservationEditNotPossible: 'Réservation annulée sans frais. Modification impossible.',
    counterEnd: 'Compteur final',
    use: 'Utilisation',
    defineLocation: 'Définir l\'emplacement',
    searchForAddress: 'Recherche par adresse',
    addressNotFoundSelectManually: 'Cette adresse n\'a pas pu être trouvée. Cliquez sur la carte pour définir l\'emplacement.',
    setUserLocation: 'Définissez l\'emplacement',
    itemTooFarAway: 'L\'emplacement de la machine est trop éloigné.',
    all: 'Toutes les catégories',
    away: 'd\'éloignement',
    occupiedTo: 'Déjà occupée à {percent} %',
    moreSearchFields: 'Davantage de critères',
    yourMailContainsAWhiteSpace: 'Votre email comprend un espace vide, non valide.',
    coordinatesRequired: 'Emplacement introuvable',
    newAppVersionAvailable: 'Nouvelle version de l\'app disponible',
    actualizeAppNow: 'Une nouvelle version de l\'app est disponible. Actualisez-la maintenant.',
    versionNumber: 'Nouvelle version de l\'app',
    deadApiModalTitle: 'Nos serveurs sont actuellement fortement sollicités.',
    weSolveTheProblem: 'Nous vous prions de patienter. Nous nous efforçons de remédier à ce problème. Veuillez rééssayer dans 5 minutes.',
    gotThat: 'Compris'
  },
  systemNotification: {
    checkItemsTitle: 'Vérifiez l\'emplacement de vos machines',
    checkItemsText: 'FarmX a introduit un nouveau système de coordonnées pour indiquer précisément l\'emplacement de vos machines. Veuillez vérifier l\'emplacement de chacune de vos machines.'
  },
  v11: {
    userBilling: 'Export facturation',
    downloadWinbizCsv: 'CSV pour Winbiz',
    debit: 'N° compte débiteurs',
    credit: 'Compte',
    debitRequired: 'Indiquez le n° de compte débiteurs',
    creditRequired: 'Indiquez le n° de compte',
    howToSelectWinBizDates: 'Toujours indiquer une année complète. Par exemple du 01.01.2019 au 01.01.2020',
    invoiceDateRequired: 'Indiquez la date de facturation',
    invoiceDate: 'Date de facturation',
    allReservations: 'Toutes les réservations',
    app: 'App',
    technicalInformations: 'Informations techniques',
    appInformations: 'Informations de l\'app',
    userId: 'ID-utilisateur',
    appVersion: 'Version FarmX (Front- und Backend)',
    nativeAppDeviceId: 'Device-ID (Android / IOS)',
    hasDevice: 'App identifiée (Android / IOS)?',
    allRegisteredDeviceIds: 'Device-IDs enregistrée (API)',
    isDeviceIdFoundInRegisteredIds: 'Le Device-ID a-t-il été trouvé (Funktion):',
    allCookies: 'Toutes les Cookies',
    lastStep: 'Presque terminé!',
    acceptConditions: 'Pour publier la machine, veuillez accepter les conditions suivantes',
    generalFees: 'La publication de cette machine est gratuite tant qu\'elle n\'a pas fait l\'objet d\'une location à partir du 1er juillet 2019. La publication vous sera facturée <span class="font-weight-bold"> {costsPerItem} chf pour chaque machine mise en location au moins une fois dans l\'année.</span>',
    weSendABill: 'Pour le moment, pas besoin de vous soucier du paiement. La facture de vos publications pour les machines que vous aurez louées vous parviendra cas échéant en fin d\'année.',
    benefitFromDiscounts: 'Profitez de rabais de quantité',
    tillFiveItems: 'Jusqu\'à 5 machines effectivement louées: {costsPerItem} chf / machine et par an',
    moreThanFiveItems: 'Plus de 5 maschines effectivement louées: {discountPerItem} % rabais / machine et par an',
    moreThanFiftyItems: 'Plus de {maxDiscount} machines effectivement louées: {maxDiscount} % rabais / maschine et par an',
    gtcApplyByTheWay: 'Les conditions générales d\'utilisation de FarmX Sàrl s\'appliquent dans tous les cas.',
    counterCoefficientRequired: 'Veuillez entrer le facteur de conversion',
    publishItemNow: 'Publier maintenant ma machine'
  },
  v12: {
    myFarm: 'L\'exploitation',
    editAddress: 'Modifier l\'adresse',
    onMyInvoices: 'Factures',
    paymentWithin: 'Payable dans les',
    days: 'jours',
    daysMandatory: 'Veuillez entrer un délai de paiement.',
    tenDaysMin: 'Le délai de paiement doit être d\'au moins 10 jours.',
    isCancelled: 'Annulé',
    captureUse: 'Saisir l\'utilisation',
    splitReservation: 'Refacturer l\'utilisation à des tiers',
    isCaptured: 'Saisie effectuée avec succès',
    workingHours: 'Heures de travail',
    hourlyWage: 'Salaire horaire',
    remove: 'Supprimer',
    myStandardHourlyWage: 'Salaire horaire',
    selectAddressee: 'Sélectionnez un client',
    createInvoices: 'Générer les factures',
    invoicesNotLoaded: 'Les factures n\'ont malheureusement pas pu être chargées.',
    invoiceCouldNotBeCreated: 'la facture n\'a pas pu être éditée',
    vatForItems: 'TVA pour machines *',
    vatForWork: 'TVA pour main-d\'oeuvre *',
    showVatSection: 'Ajouter la TVA',
    workingHoursWith: 'Temps de travail avec',
    splitMarkFailed: 'la réservation n\'a pas pu être marquée comme répartie',
    invoicesSuccessfullyGenerated: 'les factures ont été générées avec succès.',
    alreadySplit: 'Déjà réparti',
    splitAnyway: 'Répartir à nouveau',
    alreadySplitInformation: 'Vous avez déjà réparti une fois cette réservation.',
    howDoYouLikeToContinue: 'Comment voulez-vous poursuivre?',
    invoicesPayableWithin: 'Facture payable dans les',
    downloadAllUsers: 'Tout',
    menu: 'Menu',
    logout: 'Se déconnecter',
    loggingYouOut: 'Nous vous déconnectons...',
    phoneOnlyFees: 'Les annonces pour les machines qui ne peuvent être louées que par téléphone seront facturées par FarmX comme si elles avaient été louées au moins une fois.',
    taxId: 'n° TVA'
  },
  v13: {
    discounts: 'Rabais de quantité',
    discount: 'Rabais de quantité',
    createDiscount: 'Introduire des rabais de quantités',
    discountsLoadFailed: 'Les rabais de quantité n\'ont pas pu être affiché',
    discountLoadFailed: 'Les rabais de quantité n\'ont pas pu être affiché',
    description: 'Description',
    targetDate: 'Date de référence (répétition annuelle)',
    duration: 'Durée',
    unit: 'Unité',
    machines: 'Machines',
    discountLevels: 'Rabais',
    edit: 'Modifier',
    newDiscount: 'Etablir des rabais de quantité',
    updateFailed: 'Echec de la mise à jour',
    addingFailed: 'Echec de l\'ajout',
    addDiscountLevel: 'Introduire des conditions préférentielles',
    discountForm: {
      descriptionEmptyError: 'La description doit comporter au moins 3 caractères',
      descriptionLabel: 'Donnez un intitulé au rabais',
      machinesLabel: 'Quelles machines doivent être liées à ce rabais?',
      levelStart: 'De',
      levelEnd: 'à',
      levelPrice: 'Réduction',
      save: 'Enregistrer',
      others: 'Autres',
      discountAdded: 'Conditions préférentielles établies',
      askForDeletion: 'Voulez-vous vraiment supprimer ces conbditions préférentielles?',
      deletionAcceptance: 'Supprimer',
      abort: 'Annuler',
      accept: 'Supprimer',
      dateNotSet: 'La date de référence manque',
      mustSetDate: 'Introduisez une date de référence',
      noMachineSelected: 'Aucune machine sélectionnée',
      mustSelectMachine: 'Vous devez sélectionner au moins une machine',
      negativeValuesNotAllowed: 'Les valeurs négatives ne sont pas acceptées',
      discountGreaterThan: 'Doit être plus élevé que 0',
      UpperMustBeGreaterThanLower: 'Doit être plus élevé que la valeur de départ',
      mustEqualToProceeding: 'Doit correspondre à la valeur de fin précédente',
      required: 'Requis',
    },
    maintenance: 'Entretien',
    maintenanceLowerThreshold: 'Statut orange dès',
    maintenanceLowerThresholdRequired: 'La valeur rouge doit être supérieure à la valeur orange',
    maintenanceUpperThresholdRequired: 'la valeur orange doit être plus élevée que la valeur orange',
    maintenanceUpperThreshold: 'Statut rouge dès',
    resetMaintenance: 'Réinitialiser le mode entretien',
    resetMaintenanceBtn: 'Réinitialiser',
    resetMaintenanceDesc: 'Réinitiliser le mode entretien. Choisissez la date du dernier entretien effectué.',
    trafficLightLoadFailed: 'Les indicateurs n\'ont pas pu être affichés',
    lastMaintenanceDate: 'Dernier entretien',
    month: 'Mois',
    itemToDiscount: 'Sélectionné',
    responsibleCompensation: 'Indemnité de stationnement',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    reservationRejected: 'La réservation a été refusée. Modification pas possible.',
    rejectedReservation: 'Refusé',
    chfPerUnit: 'CHF / Unité',
    customFieldsWereNotUpdated: 'Les champs n\'ont pas pu être enregistrés.',
    customFieldsWereNotCreated: 'Les champs n\'ont pas pu être créés.',
    withDiscount: 'Echelonnement des tarifs',
    discountFetchingError: 'Une erreur est survenue lors de l\'échelonnement des tarifs',
    youMayGetDiscount: 'Si vous louez cette machine, un tarif préférentiel vous sera octroyé à partir d\'une certaine utilisation.',
    errorWhileSignalFetching: 'Une erreur est intervenue lors de la demande.',
    downloadExcel: 'Télécharger le  fichier Excel',
    downloadCompensationExcel: 'Télécharger les indemnités de stationnement',
    timeSpan: 'Période',
    compensationPerUnit: 'Indemnité / Unité',
    totalCompensation: 'Total des indemnités',
    sendInvoicesManually: 'Ne pas envoyer les factures par email.',
    sendInvoicesAutomatically: 'Envoyer automatiquement les factures une fois confirmées (recommandé).',
    export: 'export',
    noBillings: 'Aucun décompte confirmé',
    noCustomInvoices: 'Acune facture individuelle confirmée',
    noIncomingInvoices: 'Vous n\'avez reçu aucune facture pour le moment',
    invoiceId: 'N° de facture',
    invoices: 'Factures',
    rejectedThe: 'Refusé',
    state: 'Statut',
    send: 'Envoyer',
    reallySend: 'Voulez-vous vraiment envoyer les factures par email à tous les destinataires? <b>Cela ne peut être annulé!</b>',
    sendInvoiceViaMail: 'Envoyer les factures par email',
    errorOnCalculatingDiscounts: 'Une erreur est intervenue dans le calcul du tarif dégressif!',
    alreadyBilled: 'Utilisation jusqu\'ici',
    useForBillingPeriode: 'Utilisation actuelle',
    totalUseInBillingPeriode: 'Utilisation totale',
    subTotal: 'Sous-total',
    showPrices: 'Afficher les prix',
    contact: 'Contact',
    itemLocation: 'Emplacement de la machine',
    awayIn: 'éloignement',
    mailConfirmationRequired: 'Veuillez indiquez à nouveau votre adresse email.',
    mailConfirmation: 'Répétez votre adresse email *',
    mailsDiffer: 'Votre email ne correspond pas, veuillez vérifier la saisie.',
    allInputsAreRequired: '* Toutes les données avec une étoile (*) sont obligatoires.',
    customId: 'N° interne de la machine (ne sera pas publié)',
    internalNumber: 'N° interne',
    autoAuthentication: 'Authentification en cours...',
    lastResetDate: 'Dernière réinitialisation'
  },
  invitation: {
    shareFarmX: 'Partager FarmX',
    inviteMember: 'Inviter des membres',
    linkCopied: 'Lien copié. Vous pouvez à présent le partager via Whatsapp ou les réseaux sociaux.',
    copyLink: 'lien',
    multipleTimesValid: 'Valable plusieurs fois',
    singleValid: 'Valable une seule fois',
    code: 'Code',
    generateLink: 'Générer le lien',
    onlyCharAndNumbersAllowed: 'Seules des chiffres ou lettres sont acceptés.',
    min4Chars: 'Le code doit être composé d\'au moins 4 caractères.',
    link: 'Lien',
    expireDate: 'Date d\'échéance',
    codeTooShort: 'Le code introduit est trop court. Il doit comporter au minimum 4 caractères.',
    unsupportedChars: 'Le code contient un caractère erroné. Seuls les chiffres et les lettres sont acceptés.',
    unknownMode: 'Erreur technique: mode inconnu.',
    codeAlreadyUsed: 'Le code saisi est déjà utilisé dans le système. Merci de réessayer avec un nouveau code.',
    codeSuccessfullyGenerated: 'Le lien a été généré avec succès. Vous pouvez à présent le copier et le partager.',
    deleteLink: 'Devons-nous vraiment effacer ce lien? Cela ne peut être annulé.',
    linkDeleted: 'Le lien a été effacé.',
    overwriteLinkQuestion: 'Remplacer le lien existant?',
    overwriteLink: 'Remplacer',
    linkWillBeOverwritten: 'Le lien suivant sera écrasé et remplacé. Voulez-vous poursuivre?',
    linkToBeOverwritten: 'Ce lien a été remplacé',
    welcomeToFarmX: 'Bienvenue sur FarmX!',
    invitedBy: 'Vous avez été invité par {invitor}. Choisissez le procédure à suivre.',
    invitedByAnRegisterNow: 'Vous avez été invité sur FarmX par {invitor}. Enregistrez-vous gratuitement dès à présent.',
    invitationReceived: 'Disposez vous d\'un code d\'invitation?',
    codeInvalid: 'Le code saisi n\'est pas valable.',
    alreadyLogin: 'J\'ai déjà un compte.',
    newToFarmX: 'Créer un compte (gratuit)',
    addedAsMember: 'Vous avez été ajouté avec succès comme membre de {invitor}.',
    copyCode: 'Copier le code',
    codeCopied: 'Copier le code. Vous pouvez à présent le transmettre à une personne qui ne possède pas encore de compte FarmX.',
    invitePeopleToFarmx: 'Inviter des personnes sur FarmX',
    createLinkTitle: 'Générer un lien'
  },
  dataTable: {
    noDataAvailable: 'Pas de données saisies'
  },
  info: {
    incomingInvoices: 'Sur cette page se trouvent toutes les factures reçues de la part d\'autres utilisateurs FarmX. Seul l\'expéditeur peut indiquer la facture comme payée. <br><br>' +
      'Cliquez sur une facture pour la télécharger en PDF ou copiez le lien pour le PDF.',
    simpleLink: 'Copier le lien «Partager FarmX». Envoyez le lien copié à des clients, collègues ou prochers pour les inviter sur FarmX.' +
      'Vous pouvez ainsi vous enregistrer facilement sur FarmX.',
    memberInvitation: 'Editez un lien dans la partie «Inviter des membres» pour permettre à la personne de devenir automatiquement membre. Vous avez pour cela ' +
      'les possibilités suivantes à choix: <br><br> <span class="">Code:</span> Si vous laissez ce champ libre, le système vous édite automatiquement un code aléatoire. Vous pouvez également' +
      ' introduire un code personnalisé qui sera envoyé par lien. <br><br> <span class="">Einmalig gültig:</span> Avec ce choix, le lien généré peut n\'être utilisable qu\'une seule fois.' +
      ' Cela limite ainsi l\'invitation à une seule personne. <br><br> <span class="">Mehrmals gültig: </span> Le lien peut être utilisé plusieurs fois et servir à plusieurs personnes.'
  },
  state: {
    confirmed: 'Confirmé',
      draft: 'Brouillon',
      sent: 'Envoyé'
  },
  registrationForm: {
    'enterCode': 'Saisir le code'
  },
  sMap: {
    searchItemText: 'Recherche de machines',
    checkCoordinates: 'Vérifier les coordonnées'
  },
  itemMap: {
    zipNotFound: 'Le code postal n\'a pas été trouvé.',
    priceOnRequest: 'Prix sur demande',
    selectCategory: 'Catégorie'
  },
  navigationBar: {
    home: 'Home',
    about: 'Info'
  },
  pwReset: {
    pwReset: 'Réinitialiser le mot de passe'
  },
  userLocationModal: {
    longitude: 'Longitude',
    latitude: 'Latitude'
  },
  mySettings: {
    changeMail: 'Changer d\'e-mail',
    newMail: 'Nouvel e-mail',
    changePersonalData: "Changer les données"
  },
  v14: {
    archiveRentState: 'État',
    cancelled: 'Statut'
  },
  v15: {
    canEditMachine: 'La personne responsable peut modifier les données de base.',
    usageNotRecorded: 'L\'utilisation n\'a pas été enregistrée',
    employerHasNoMachine: 'Aucune machine disponible.',
    replaceList: 'Appliquer le filtre',
    resetFilters: 'Réinitialisation du filtre',
    filterByMachine: 'Filtrez les machines',
    activeFilters: 'Filtres actifs',
    reportDamage: 'Signale les dommages préexistants',
    isReportingDamage: 'Notification de dommages préexistants',
    sendImage: 'Joindre une photo',
    messageRequired: 'Le message ne peut pas être vide',
    damageProofIsRequired: 'Joindre une photo comme preuve des dommages',
    message: 'Message',
    messageSubject: 'Sujet',
    messageText: 'Contenu du message',
    attachedImage: 'Image ci-jointe',
    messageNotSuccessful: 'Un problème est survenu lors de l\'envoi du message',
    messageSuccessful: 'Message envoyé',
    commentCannotBeEmpty: 'Tapez un message ou envoyez une photo',
    tooLateForMessage:'Il n\'est pas possible d\'envoyer des messages pour des réservations fermées pendant plus de 4 jours.',
    membersValidation: 'Une confirmation est nécessaire pour mes membres',
    showPrivateMachines: 'Afficher aussi les machines privées',
    filterDamageReports: 'Filtre endommagées',
    showDamageReport: 'Afficher le message de dommages',
    searchByMachineOrId: 'Machine de recherche par nom ou ID interne',
    customizeMenu: 'Personnaliser le menu',
    uploadImage:'Envoyer des images',
    noCommentsTitle:'pas de messages',
    noCommentsSubTitle:'pour le moment, il n\'y a pas de messages concernant cette réservation',
    comments:'Messages'
  },
  archiveExport: {
    renterIsMember: 'Membre',
    noData: 'Données non disponibles'
  },
  socialMediaLinks: {
    subject: 'FarmX',
    body: 'Regarde cette machine'
  },
  itemPublicDetails: {
    siteTitle: 'FarmX - Louer simplement',
    description: 'Optimisez vos coûts machines: locations, reservations, plannings, decomptes.'
  },
  itemCreateView: {
    switchToAdvanced: 'Plus d\'options',
    switchToEasy: 'Moins de paramètres'
  },
  imageUploader: {
    mainImageMissing: 'Aucune image principale n\'est disponible. Télécharger maintenant.',
    noFileAvailable: 'Aucun fichier sélectionné.',
    fileTypeNotSupported: 'Type de fichier non pris en charge. Téléchargez un fichier image.',
    fileReaderApiNotSupported: 'Une erreur inattendue s\'est produite : L\'API FileReader n\'est pas supportée. Veuillez contacter le support FarmX.',
    mainImage: 'Image principale',
    replaceImage: 'Télécharger une autre image',
    disclosureTitle: 'Divulgation claire - Prominent Disclosure',
    disclosureIntroduction: 'FarmX collecte/transmet/synchronise/stocke des images pour',
    disclosureItemImages: 'présenter vos machines aux locataires de manière utile et attrayante. Les locataires ont ainsi une bonne impression de la machine qu\'ils souhaitent louer.',
    disclosureUserImage: 'Afficher le logo de votre entreprise sur votre profil aux autres utilisateurs de FarmX et l\'utiliser pour identifier (marquer) vos machines.',
    disclosureGtcLink: 'Privacy Policy et Terms & Conditions de FarmX',
    disclosureMoreInformation: 'Plus d\'informations sur Google Prominent Disclosure'
  },
  itemNotReservable: {
    notReservable: 'Malheureusement, la machine souhaitée ne peut pas être réservée. Raison:',
    hint: 'Utilisez la recherche avancée pour trouver un appareil qui vous convient.',
    itemInactive: 'La machine a été désactivée par son propriétaire.',
    distance: 'La machine est trop éloignée de vous. Le propriétaire a limité la distance maximale de location.'
  },
  userEditInvoiceModal: {
    invalidIban: 'IBAN non valide'
  },
  captureIbanModal: {
    title: 'Veuillez saisir votre IBAN',
    enterIban: 'Saisir l\'IBAN de votre compte',
    explanationText: 'Pour que nous puissions générer des factures valables, veuillez saisir le numéro IBAN de votre compte.'
  },
  openReservations: {
    noReservations: 'Parfait, vous n\'avez pas de réservation à clore.'
  },
  itemsServices: {
    variantCreationFailed: 'La variante machine n\'a pas pu être créée.'
  },
  itemGridEditable: {
    okClone: 'Créer une variante',
    cloneVariantTitle: 'Créer une variante?',
    cloneVariantText: 'Vous souhaitez mettre la machine actuelle en location en tant que variante? ' +
      'C\'est utile, par exemple, si vous souhaitez louer la même machine avec des conditions différentes. ' +
      'Veillez à adapter le titre et la description de manière pertinente afin que vos locataires sachent quelle ' +
      'variante de la machine ils doivent réserver.',
    variant: 'Variante',
    cloneNotPossibleTitle: 'Créer une variante n\'est pas possible',
    cloneNotPossibleText: 'Cette machine a un compteur. C\'est pourquoi il n\'est pas possible de créer une variante de machine, car cela entraînerait des conflits de compteurs.'
  },
  itemEditableActions: {
    editItem: 'Éditer la machine',
    blockItem: 'Bloquer ou désactiver la machine',
    createVariant: 'Créer une variante de la machine',
    deleteItem: 'Supprimer la machine'
  },
  testimonialsServices: {
    getTestimonialsFailed: 'Les témoignages n\'ont pas pu être chargés.'
  },
  testimonialsSlider: {
    rentNow: 'Mettre en location',
    doNotShowUpAgain: 'Ne plus afficher'
  },
  translations: {
    missingTranslation: 'Traduction absente',
    missingTranslationShort: 'T.A.',
    missingTranslationDescription: 'Traduction non trouvée dans la base de données.'
  },
  discount: {
    targetDateMissing: 'Erreur inattendue : la date de référence est manquante (Discount.loadDiscountReservations()) !'
  },
  selectMonth: {
    dataNotLoaded: 'Les données n\'étaient pas entièrement chargées. Veuillez réessayer.',
    overlappingTargetDate: 'La période de facturation ne doit chevaucher la date de référence d\'aucun échelonnement tarifaire.'
  },
  appInformation: {
    localStorage: 'Stockage local'
  },
  membersService: {
    newMemberRequestFailed: 'Erreur : La personne n\'a pas pu être demandée comme membre.',
    couldNotDeleteMember: 'Le membre n\'a pas pu être supprimé.'
  },
  members: {
    memberExplanation: 'Personnes ou organisations qui sont membres de mon association.',
    members: 'Membres',
    requests: 'Demandes en attente',
    customNotificationTitle: 'Messages push à mes membres',
    createCustomNotification: 'Écrire un nouveau message.'
  },
  memberships: {
    memberships: 'Appartenances',
    explanation: 'Personnes ou organisations dont je suis membre.',
    requests: 'Demandes ouvertes'
  },
  membershipRequests: {
    acceptMembershipModalTitle: 'Voulez-vous accepter l\'appartenance ?',
    acceptTextI: 'En devenant membre de {lessor}, vous pourrez peut-être bénéficier de meilleures conditions de location. ' +
      'De plus, les machines de {lessor} vous seront directement présentées lors de votre réservation.',
    acceptTextII: 'Vous pouvez accorder à {lessor} le droit de vous envoyer des bulletins d\'information sous forme de messages push. Vous pouvez révoquer ce droit à tout moment.',
    allowNotifications: '{lessor} peut m\'envoyer des messages push.',
    denyNotifications: '{lessor} n\'a pas le droit de m\'envoyer des messages push.',
    denyModelTitle: 'Vraiment refuser l\'appartenance ?',
    denyModalText: 'En tant que membre, vous pouvez généralement bénéficier de conditions de location plus avantageuses. Vous souhaitez néanmoins refuser la demande ?',
    denyModalOkText: 'Refuser l\'appartenance',
    action: 'Actions',
    missingCurrentMembershipRequest: 'Erreur inattendue: Missing current membership request.'
  },
  memberList: {
    member: 'Nom, lieu',
    receivesCustomNotifications: 'Reçoit mes messages',
    doesntReceiveCustomNotifications: 'Ne reçoit pas de messages',
    deleteMember: 'Supprimer membre',
  },
  membershipsService: {
    cancelledMembership: 'Appartenance terminée avec succès.',
    saved: 'Modification enregistrée.',
    acceptedMembershipRequest: 'Vous êtes maintenant membre.',
    deniedMembershipRequest: 'Vous avez refusé l\'adhésion.'
  },
  membershipList: {
    receiveNotifications: 'Notifications autorisées',
    bannedNotifications: 'Notifications arrêtées',
    cancelMembershipBtn: 'Mettre fin à l\'appartenance',
    cancelMembershipTitle: 'Mettre fin à l\'appartenance?',
    cancelMembershipText: 'Si vous mettez fin à votre affiliation, il se peut que vous ne puissiez plus louer les machines de ce loueur ou que les tarifs changent.'
  },
  invitationAnswerModal: {
    mayLessorSendYouMessages: 'Autoriser les messages de {lessor} ?',
    acceptInvitation: 'Autoriser',
    cancelInvitation: 'Ne pas autoriser'
  },
  customNotificationService : {
    couldNotCreate : 'Le message n\'a pas pu être créé',
    couldNotRetrieve : 'Le message n\'a pas pu être chargé',
    couldNotRetrieveNotifications : 'Les messages n\'ont pas pu être récupérés',
    created : 'Le message a été sauvegardé',
    saved : 'Sauvé',
    couldNotSave : 'Échec de la sauvegarde',
    testSent : 'Message de test envoyé à votre appareil',
    couldNotSendTest : 'Le message de test n\'a pas pu être envoyé',
    couldNotSend : 'Le message n\'a pas pu être envoyé',
    sent : 'Message envoyé avec succès'
  },
  customNotificationForm : {
    createCardTitle : 'Nouveau message à mes membres',
    updateCardTitle : 'Modifier le message',
    missingBodyText : 'Veuillez rédiger le message',
    cancel : 'Annuler',
    createNew : 'Enregistrer',
    selectLanguage : 'Choisir la langue',
    selectLanguageRules : 'Vous devez choisir une langue',
    pushTitle : 'Titre à pousser',
    pushTitleRules : 'Vous devez saisir un titre pour le message push',
    notificationTitle : 'Titre du message',
    notificationTitleRules : 'Vous devez saisir un titre pour le message',
    save : 'enregistrer',
    sendTest : 'envoi de test',
    send : 'envoyer',
    sendBtnTooltip : 'Envoyer un message à mes membres',
    sendTestTooltip : 'Envoyer le message à titre de test sur mon appareil (les membres ne reçoivent pas de message)',
    logTitle : 'Journal',
    testSendDateLog : 'Dernier envoi de test',
    sendDateLog : 'envoi',
    createdAtLog : 'créé le',
    updatedAtLog : 'Dernière modification le',
    noTestSend : 'Pas d\'envoi test',
    noSend : 'Pas d\'envoi',
    sendResponseTitle : 'Envoi réussi',
    sentToUserWithLanguage : 'Le message push a été envoyé à vos membres qui ont choisi la langue "{language}" dans leur profil Farmx',
    pushDeviceCount : 'Appareils',
    userCount : 'Membres',
    sendTestFirst : 'Envoi de test requis',
    sendOkText : 'Envoyer',
    beforeSendModalTitle : 'Envoyer le message push maintenant ?',
    beforeSendModalText : 'Tous vos membres dont la langue est "{language}" recevront un message push de votre part. Voulez-vous envoyer le message maintenant ?',
    customNotificationTestSent: 'Le message push a été envoyé sur votre smartphone. | Le message push a été envoyé à vos smartphones.'
  },
  customNotificationList : {
    sendDate : 'Envoyé le {sendDate}',
    notSent : 'Non envoyé'
  },
  notificationService : {
    notificationPatchFailed : 'Le message n\'a pas pu être marqué comme (in)lu',
    couldNotRetrieveNotification : 'Le message n\'a pas pu être chargé'
  },
  customNotificationView : {
    sender : 'Expéditeur du message',
    sendDate : 'Date d\'envoi'
  },
  editorLinkBtn: {
    noLinkSet: 'Veuillez saisir un lien avec https://',
    addLink: 'Ajouter le lien'
  }
}
