<template>
  <v-row
    class="mx-0 grey lighten-4 black--text"
  >
    <!-- has reservations in timespan: for advanced search -->
    <v-col
      v-if="searchStart && searchEnd && item && item.occupiedRatio(searchStart, searchEnd)"
      class="col-12 py-1 mb-md-n1 px-1 px-md-2 orange white--text"
    >
      <span class="fal fa-exclamation-triangle mr-1"/>
      {{$t('v10.occupiedTo', {percent: item.occupiedRatio(searchStart, searchEnd)})}}
    </v-col>

    <v-col class="col-12 px-1 px-md-2 pt-6">
      <!-- public price -->
      <item-prices
        v-if="item && item.canISeeTheMachine()"
        :item-instance="item"
        :discounts="discounts"
        private-price-classes="font-weight-black"
        public-price-classes="font-weight-black"
        discount-classes="font-weight-black red--text"
        min-fee-classes="d-none"
        :align-discount-beneath="$vuetify.breakpoint.smAndUp"
      />
    </v-col>

    <!-- distance & address -->
    <v-col class="col-12 pb-2 px-1 px-md-2">
      <div
        v-if="item.getDistance()"
      >
        <span class="fal black--text fa-map-signs mr-2"/>
        <span>
          {{item.getDistance()}} km {{$t('v13.awayIn')}} {{ item.getCity() }}
        </span>
      </div>

      <!-- responsible/owner -->
      <div v-if="item.getResponsibleOrOwner().getFirstName()">
        <span class="fal fa-user mr-2"></span>
        <span>{{$t('v13.contact')}}:</span> {{item.getResponsibleOrOwner().getDisplayUser(true)}}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Item from '../../../classes/Item'
import ItemPrices from '../../items/ItemPrices'
import Discounts from '../../../classes/Modules/Discounts/Discounts'

export default {
  name: 'ItemsGridInfo',
  components: {ItemPrices},
  props: {
    item: {
      type: Item,
      required: true
    },
    discounts: {
      type: Discounts,
      required: true
    },
    // search periode (for advanced search)
    searchStart: {
      type: Number,
      required: false,
      default: null
    },
    searchEnd: {
      type: Number,
      required: false,
      default: null
    }
  }
}
</script>
