import config from "@/config";
import User from "@/classes/User";
import router from '../router/router'
import store from "@/store/store"
import moment from 'moment'

const myCommentsColor = 'var(--v-primary-base)'
const repliesColor = 'var(--v-secondary-base)'

export default class ReservationComment {
  constructor (comment, reservationInstance) {
    this.id = comment.id
    this.senderId = comment.senderId
    this.reservationId = comment.reservationId
    this.commentText = comment.commentText
    this.commentImages = comment.images
    this.commentDate = comment.updatedAt
    this.reservationInstance = reservationInstance
    this.reportDamage = comment.reportDamage
    this.user = new User(comment.User)
  }

  getImagesLinks () {
    let links = []
    for (const image of this.commentImages) {
      links.push(config.imageApi + image.link + '.jpg')
    }
    return links
  }

  getCommentColor () {
    if (this.isThisMyComment()) return myCommentsColor
    return repliesColor
  }

  getUser () {
    return this.user
  }

  isThisMyComment () {
    return store.state.appUserInstance.id === this.senderId
  }

  getFormattedName () {
    if (this.isThisMyComment()) return 'general.me'
    return this.user.getFullName()
  }

  getFormattedDate (shortenYear = false) {
    /*global i18n*/
    const toDay = new Date()
    const commentDate = new Date(this.commentDate)
    //comment time in format HH:MM
    const commentTime = commentDate.toTimeString().slice(0, 5)
    //comment date in format DD.MM.YYYY
    let prefix = commentDate.toLocaleDateString().replaceAll("/", ".")
    //only use last 2 digits of the year if shortenYear = true was passed
    if (shortenYear) prefix = prefix.slice(0, 4) + prefix.slice(6)
    //if comment has today date replace the date with "Today"
    if (toDay.toDateString() === commentDate.toDateString()) prefix = i18n.t('v4.today')
    return `${prefix} ${commentTime}`
  }

  getCommentText () {
    return this.commentText
  }

  // information as string to export in excel
  getCommentAsString () {
    if (!this.hasText()) {
      return null
    }
    const dateString = moment(this.commentDate).format('DD.MM.YYYY HH:mm')
    const user = this.isThisMyComment() ? i18n.t('general.me') : this.getUser().getFullName()
    return `${user}, ${dateString}: ${this.getCommentText()}`
  }

  hasText () {
    return (this.commentText && this.commentText !== 'null');
  }

  hasImages () {
    return this.commentImages.length > 0
  }

  static routeToComments (reservationId) {
    router.push({name: 'ReservationComments', params: {id: reservationId}})
  }
}


