<template>
  <div
    v-if="buttonActive || page !== 1"
    class="row pt-12 align-center"
  >
    <!-- back button -->
    <div class="col-4 text-right">
      <v-btn
        icon
        outlined
        color="primary"
        class="ml-0 mr-0"
        :disabled="page === 1"
        @click="loadPreviousPage()"
      >
        <span class="fal fa-angle-left"></span>
      </v-btn>
    </div>

    <!-- page -->
    <div class="col-4 text-center">
      <div class="h5 mb-2">
        {{ page }}<span v-if="totalPages">/{{ totalPages }}</span>
      </div>
    </div>

    <!-- next button -->
    <div class="col-4 text-left">
      <v-btn
        icon
        outlined
        color="primary"
        class="ml-0 mr-0"
        :disabled="page === totalPages"
        @click="loadMore()"
      >
        <span class="fal fa-angle-right"></span>
      </v-btn>
    </div>

  </div>
</template>

<script>
export default {
  name: 'paginator',

  data () {
    return {
      buttonActive: true,
      perPage: this.$store.state.paginationLimit,
      page: '',
      pageStandard: 1
    }
  },
  props: {
    totalItems: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      required: false,
      default: null,
    },
  },

  computed: {
    totalPages () {
      if (this.totalItems > 0) {
        if (this.totalItems < this.$store.state.paginationLimit) {
          this.hideButton()
        } else this.showButton()
        return Math.ceil(this.totalItems / this.$store.state.paginationLimit)
      }
      return null
    }
  },
  watch: {
    currentPage (newValue) {
      this.page = newValue
    },
    totalItems (newVal) {
      if (newVal < this.$store.state.paginationLimit) {
        this.hideButton()
      } else this.showButton()
    }
  },
  mounted () {
    this.page = this.currentPage ? this.currentPage : this.pageStandard
    /*global EventBus*/
    EventBus.$on('paginatorHideButton', () => this.hideButton())
    EventBus.$on('paginatorShowButton', () => this.showButton())
  },

  methods: {
    // load more items
    loadMore () {
      this.page++
      this.$emit('Parent:loadMore', false, this.page, this.perPage)
    },

    loadPreviousPage () {
      this.page--
      this.$emit('Parent:loadMore', false, this.page, this.perPage)
    },

    resetPaginator () {
      this.buttonActive = true
      this.page = this.pageStandard
    },

    hideButton () {
      this.buttonActive = false
    },

    showButton () {
      this.buttonActive = true
    },

    getPage () {
      return this.page
    },

    setPage (page) {
      this.page = page
    }
  }
}
</script>

<style>

</style>
