<template>
  <div>
    <modal
      medium
      :force="false"
      ref="changeUserModal"
      :cancelText="$t('changeUser.changeToMe', {user: $store.state.appUserInstance.getDisplayUser(false)})"
      :okText="$t('changeUser.changeUser')"
      @ok="changeUser()"
      @cancel="resetMyUser()"
    >
      <div slot="title">{{$t('changeUser.changeUserModalTitle')}}</div>

      <!-- show dropdown field to select other user -->
      <v-row class="justify-center">
        <v-col class="col-12 col-md-8">
          <v-select
            v-model="selectedUser"
            :items="employer"
            return-object
            :label="$t('changeUser.selectUser')"
            outlined
          >
            <!-- custom items -->
            <template slot="item" slot-scope="data">
              {{ data.item.User.firstName }} {{ data.item.User.name }}, {{ data.item.User.ort }}
            </template>

            <!-- custom selected item -->
            <template slot="selection" slot-scope="data">
              {{ data.item.User.firstName }} {{ data.item.User.name }}, {{ data.item.User.ort }}
            </template>
          </v-select>
        </v-col>
      </v-row>
    </modal>
  </div>
</template>

<script>

export default {
  name: 'change-user',

  data () {
    return {
      selectedUser: null,
      employer: []
    }
  },

  mounted () {
    // initialize employer
    this.getEmployer()
  },

  methods: {
    // using my logged in user
    resetMyUser () {
      // delete other user app user instance
      this.$store.state.appUserInstance.resetOtherUser()

      // delete selected user array
      this.selectedUser = null

      // reset axios header with my user uid
      axios.defaults.headers.common['employer'] = this.$store.state.appUserInstance.getId()

      // re-init the menu to show employee only menu items
      EventBus.$emit('initMenu')

      // start at the dashboard
      this.gotToDashboard()
    },

    // change the active user
    changeUser () {
      // change user only, if another user was selected
      if (this.selectedUser) {
        // set the other user to the store
        this.$store.state.appUserInstance.setOtherUser(this.selectedUser.User)

        // change employer header for further requests
        axios.defaults.headers.common['employer'] = this.selectedUser.User.uid

        // re-init the menu to show employee only menu items
        /* global EventBus */
        EventBus.$emit('initMenu')

        // start at the dashboard
        this.gotToDashboard()
      }
    },

    gotToDashboard () {
      // start at the dashboard => if already at the dashboard: reload data
      if (this.$route.path === '/dashboard/dashboard') {
        // reload dashboard data
        /* global EventBus */
        EventBus.$emit('Dashboard:reloadDashboard')
      } else {
        this.$router.push({name: 'Dashboard'}).catch(() => {})
      }
    },

    // show change user modal
    open () {
      this.$refs.changeUserModal.open()
    },

    // get custom label for multiselect
    customLabel (option) {
      return `${option.User.firstName} ${option.User.name}, ${option.User.ort}`
    },

    // load all employer from database
    getEmployer () {
      /* global EventBus axios api */
      EventBus.$emit('spinnerShow')

      axios.get(api + 'employer')
      .then((response) => {
        this.employer = []
        response.data.forEach(tmpEmployer => {
          this.employer.push({User: tmpEmployer.myEmployer[0]})
        })
      })
      .catch((error) => {
        this.$notify({
          title: this.$t('notify.error'),
          text: error.response.status,
          type: 'error'
        })
      })
      .finally(() => {
        EventBus.$emit('spinnerHide')
      })
    }
  }
}
</script>
