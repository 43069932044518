export default class Location {
  constructor (longitude = null, latitude = null, pseudoLongitude = null, pseudoLatitude = null) {
    this.longitude = longitude
    this.latitude = latitude
    this.pseudoLongitude = pseudoLongitude
    this.pseudoLatitude = pseudoLatitude
  }

  /**
   * METHODS
   */
  hasCoordinates () {
    return !!(this.longitude && this.latitude) || !!(this.pseudoLongitude && this.pseudoLatitude)
  }

  /**
   * SETTERS
   */

  setLongitude (longitude) {
    this.longitude = longitude
  }

  setLatitude (latitude) {
    this.latitude = latitude
  }

  /**
   * GETTERS
   */
  getLongitude () {
    return this.longitude
  }

  getLatitude () {
    return this.latitude
  }

  getPseudoLongitude () {
    return this.pseudoLongitude
  }

  getPseudoLatitude () {
    return this.pseudoLatitude
  }

  // get longitude for api
  getLongitudeApi () {
    return this.longitude * 1000000
  }

  getLatitudeApi () {
    return this.latitude * 1000000
  }
}
