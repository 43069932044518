<template>
  <div>
    <div class="feedback-footer">
      <div class="row justify-content-center p-1" @click="$router.push({name: 'Feedback'})">
        <div class="col-auto pr-6">
          <h3 class="white--text mb-0 pl-6">
            <span class="fal fa-comment"></span>
          </h3>
        </div>
        <div class="col-auto">
          <h6 class="white--text text-left pt-2">{{$t('v3.yourFeedback')}}</h6>
        </div>
      </div>
    </div>
    <div class="feedback-button">

    </div>
  </div>
</template>

<script>
export default {
  name: 'feedback-button'
}
</script>

<style lang="scss">
.feedback-footer {
  width: 100%;
  height: 100%;
  background-color: map-get($grey, darken-1);
  opacity: 0.8;
  z-index: 999;
  cursor: pointer;
}
</style>
