// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import store from './store/store'
import router from './router/router.js'
import { sync } from 'vuex-router-sync'
import Axios from 'axios'
import Notifications from 'vue-notification'
import Datetime from 'vue-datetime'
import cookies from 'vue-cookies'
import XBrowserUpdate from 'x-browser-update'
import VueI18n from 'vue-i18n'
import messages from './i18n/translations.js'
import vuetify from './plugins/vuetify';
import moment from 'moment-timezone'
import config from './config.js'
import VueMatomo from 'vue-matomo'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import VueMeta from 'vue-meta'

/**
 * import classes
 */
import PermissionHandler from './classes/PermissionHandler'

/**
 * import css files
 */
import 'vue-datetime/dist/vue-datetime.css'

/**
 * global components
 */
import VuesticPlugin from './components/vuestic-components/vuestic-components-plugin'
import Modal from './components/vuestic-components/vuestic-modal/VuesticModal.vue'
import FarmeSpinner from './components/seccom-components/farmeSpinner/farmeSpinner'
import Paginator from './components/seccom-components/paginator/Paginator.vue'
import ItemGrid from './components/reservations/ItemGrid.vue'

/**
 * Sentry
 */
if (config.sentryDSN && config.sentrySR) {
  Sentry.init({
    Vue,
    dsn: config.sentryDSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [config.frontendBaseUrl, /^\//],
      }),
    ],
    tracesSampleRate: config.sentrySR,
  });
}

Vue.component('spinner', FarmeSpinner)
Vue.component('Modal', Modal)
Vue.component('paginator', Paginator)
Vue.component('item-grid', ItemGrid)

// using moment in each component
Object.defineProperty(Vue.prototype, '$moment', { value: moment })

/** Matomo **/
Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://piwik.seccom.ch',
  siteId: 5,

  // Changes the default .js and .php endpoint's filename
  // Default: 'piwik'
  trackerFileName: 'piwik',

  // Overrides the autogenerated tracker endpoint entirely
  // Default: undefined
  // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

  // Enables automatically registering pageviews on the router
  router: router,

  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,

  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: false,

  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,

  // Whether or not to log debug information
  // Default: false
  debug: true
})



// make axios global
global.axios = Axios

// workaround for finally() in axios => not supported in edge and safari. now it does ;)
// require('promise.prototype.finally').shim()

// Define API URL
let api = config.api
let billingApi = config.billingApi
let imageApi = config.imageApi
let imageUploadApi = config.imageUploadApi
global.api = api
global.billingApi = billingApi
global.imageApi = imageApi
global.imageUploadApi = imageUploadApi
global.store = store

// Event Bus
global.EventBus = new Vue()

// register plugins
Vue.use(VuesticPlugin)
Vue.use(Notifications)
Vue.use(Datetime)
Vue.use(cookies)
Vue.use(XBrowserUpdate)
Vue.use(VueI18n)
Vue.use(VueMeta)

// set secure, only https works
// more info: https://github.com/cmp-cc/vue-cookies
Vue.$cookies.config(null, null, null, true)

/* language initialization */
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'de_CH', // set locale
  fallbackLocale: 'de_CH',
  messages // set locale messages
})

global.i18n = i18n

// initialize the store language for the first time
store.commit('initializeLanguage')

// Vue config
Vue.config.ignoredElements = [
  'page'
]

sync(store, router)

router.beforeEach((to, from, next) => {
  // redirect from home to log-in, if user can auto-perform login
  const hasAuthtoken = cookies.isKey('authtoken')|| localStorage.key('authtoken') || to.params.authtoken
  if (to.name === 'home' && hasAuthtoken) {
    next({name: 'Login'})
  }
  // show preloader
  /* global EventBus */
  EventBus.$emit('showPreloader')
  // does the user has the permission to enter the to route. If not, redirect object is returned
  const redirect = PermissionHandler.hasPermission(to.meta)
  if (redirect === true) { // redirect with route object (name: 'RoutName')
    next()
  } else { // no route object
    next(redirect)
  }
})

router.afterEach(() => {
  // mediaHandler()

  /* global EventBus */
  EventBus.$emit('hidePreloader')
})

/**
 * FILTER
 */
Vue.filter('date', (date, format = 'DD.MM.YYYY') => {
  return moment(date).format(format)
})

Vue.filter('currency', (amount) => {
  return parseFloat(amount).toFixed(2) + ' CHF'
})

/** VUE **/
/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  i18n,
  router,
  store,
  vuetify
})
