<template>
  <div>
    <!-- only show star, if it's me and not an employer => fix 17.02.2019 -->
    <span
      v-if="!item.isFavorite() && !$store.state.appUserInstance.hasOtherUser()"
      class="clickable"
      @click.capture.stop="markAsFavorite()"
    >
      <span class="fa fa-2x fa-star fa-star-o"/>
    </span>
    <span
      v-if="item.isFavorite() && !$store.state.appUserInstance.hasOtherUser()"
      @click.capture.stop="unmarkAsFavorite(item)"
    >
      <span class="fa fa-2x fa-star"/>
    </span>
  </div>
</template>

<script>
import Item from '../../../classes/Item'
import { ItemsServices } from '../../../services/api/items/ItemsServices'

export default {
  name: 'ItemsGridFavorite',
  props: {
    item: {
      type: Item,
      required: true
    }
  },
  methods: {
    async markAsFavorite () {
      const success = await ItemsServices.markAsFavorite(this.item.getId())
      if (success) {
        this.item.setFavorite(true)
      }
    },
    async unmarkAsFavorite () {
      const success = await ItemsServices.unmarkAsFavorite(this.item.getId())
      if (success) {
        this.item.setFavorite(false)
      }
    }
  }
}
</script>
