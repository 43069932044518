export default class Image {
  constructor ({id, link, isFullUrl = false, base64, imageFile, isMainImage, name}) {
    this.baseUrl = global.imageApi
    this.id = id
    this.name = name
    this.link = link
    // used for new images to be uploaded
    this.base64 = base64
    this.imageFile = imageFile
    // mark image to be the main image (api fuck)
    this.isMainImage = isMainImage
    // if url has not to be merged with base url and image type ending.
    this.isFullUrl = isFullUrl
  }

  getSrc (thumbnail = true) {
    if (this.link) {
      if (this.isFullUrl) {
        return this.link
      }
      if (thumbnail) {
        return `${this.baseUrl}${this.link}_thumbnail.jpg`
      } else {
        return `${this.baseUrl}${this.link}.jpg`
      }
    }
    if (this.base64) return this.base64
  }
}
