/**
 * contains a current system notification
 */
export default class SystemNotification {
  constructor (id, title, text, cookies) {
    this.id = id
    this.title = title
    this.text = text
    this.cookies = cookies

    this.cookiesKey = 'systemNotification_' + this.id

    this.isCookieRead = this.cookies.isKey(this.cookiesKey)
  }

  // has the cookie already been set (rode by user)
  isRead () {
    return this.isCookieRead
  }

  // set notification to be read
  readNotification () {
    // save in cookies
    this.cookies.set(this.cookiesKey, true, -1)

    // set class variable for reactivity
    this.isCookieRead = true
  }

  /**
   * GETTERS
   */
  getId () {
    return this.id
  }

  getTitle () {
    return this.title
  }

  getText () {
    return this.text
  }
}
