<template>
  <div>
    <!-- logo -->
    <div
      class="farmx-logo px-4 pt-6 ml-3 ml-lg-6 clickable"
      @click="clickLogo()"
    >
      <img style="max-width: 100%;" src="../../../assets/imgs/farmxLogo.png" />
    </div>

    <!-- wrapper -->
    <div class="navigation-wrapper">
      <v-row class="align-center mx-0 mt-0">
        <!-- logged in mode -->
        <v-col
          v-if="!loggedOutMode"
          class="col-12 px-lg-6 d-flex align-center justify-end item-container"
        >
            <!-- user name -->
            <user-nav
              class="hidden-sm-and-down ml-6 mb-0"
            />

            <!-- notification -->
            <span
              @click="$router.push({name: 'Notifications'})"
              class="clickable ml-6 h5 mb-0"
            >
            <span class="fa fa-bell"/>
          </span>

            <!-- share farmx -->
            <div
              v-if="currentAppUserGid !== null && currentAppUserGid <= 15"
              @click="$router.push({name: 'Share'})"
              class="ml-6 clickable h5 mb-0"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="primary--text">
                    <span class="fa fa-share-alt"/>
                  </span>
                </template>
                {{$t('invitation.shareFarmX')}}
              </v-tooltip>
            </div>

            <!-- logout -->
            <div
              class="ml-6 hidden-sm-and-down"
            >
              <span class="clickable h5 mb-0" @click="$router.push({name: 'Logout'})">
                <span class="fa fa-sign-out"/>
              </span>
            </div>
        </v-col>
        <!-- logged out user -->
        <v-col
          v-else
          class="col-12 px-lg-6 d-flex align-center justify-end item-container"
        >
          <div
            class="mr-6 clickable"
            @click="openInfo()"
          >
            <span class="fal fa-info-circle mr-1"/>
            {{$t('navigationBar.about')}}
          </div>
          <div
            v-if="$route.name === 'Login'"
            @click="$router.push({name: 'home'})"
            class="clickable pr-md-5"
          >
            <span class="fal fa-house mr-1"/>
            {{$t('navigationBar.home')}}
          </div>
          <!-- login page -->
          <div
            v-else
            @click="$router.push({name: 'Login'})"
            class="clickable pr-md-5"
          >
            <span class="fal fa-user mr-1"/>
            {{$t('v2.login')}}
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import UserNav from '../UserNav'
  import config from '../../../config'

  export default {
    name: 'NavigationBar',
    components: { UserNav },
    props: {
      loggedOutMode: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    computed: {
      currentAppUserGid () {
        let appUser = this.$store.getters.getAppUserInstance()
        if (appUser && appUser.getRealUser()) {
          return appUser.getRealUser().getGid()
        } return null
      }
    },

    data() {
      return {
        config: config
      }
    },

    methods: {
      openInfo () {
        window.open('https://info.farmx.ch')
      },
      clickLogo () {
        if (this.$store.getters.isUserLoggedIn()) {
          this.$router.push({name: 'Dashboard'}).catch(() => {})
        } else {
          this.$router.push({name: 'home'}).catch(() => {})
        }
      }
    }
  }
</script>

<style lang="scss">
  .navigation-wrapper {
    left: 0;
    top: 0;
    width: 100%;
    background-image: url('~@/assets/imgs/header.jpg');
    background-size: cover;
    background-position: left center;
    height: $navigation-wrapper;
  }

  .item-container {
    background-color: rgba(255, 255, 255, 0.9);
    height: 2.5rem!important;
    max-height: 2.5rem !important;
  }

  .farmx-logo {
    position: absolute;
    top: 0px;
    left: 0px;
    max-width: $sidebar-width;
    background: white;
    height: 80px;
    box-shadow: $widget-box-shadow;
    border-radius: 0 0 $radius * 2 $radius *2;
    z-index: 1;
  }

  /* breakpoint md and down */

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    .navigation-wrapper {
      height: $navigation-wrapper-mobile;
    }

    .farmx-logo {
      width: $sidebar-width / 1.5;
      height: $top-mobile-nav-height / 1.5;
    }
  }
</style>
