<template>
  <div class="farme-spinner" v-show="isActive">
    <v-progress-linear indeterminate class="mt-0 mb-0"></v-progress-linear>
  </div>
</template>

<script>
export default {
  name: 'farme-spinner',
  data () {
    return {
      isActive: false,
      text: ''
    }
  },

  mounted () {
    /* global EventBus */
    EventBus.$on('spinnerShow', (text = this.$t('spinner.pleaseWait')) => {
      this.show()
      this.text = text
    })

    EventBus.$on('spinnerHide', () => {
      this.hide()
    })
  },

  beforeDestroy () {
    /* global EventBus */
    EventBus.$off('spinnerShow')
    EventBus.$off('spinnerHide')
  },

  methods: {
    show () {
      this.isActive = true
    },

    hide () {
      this.isActive = false
    }
  }
}
</script>

<style lang="scss">
  .farme-spinner {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 100%;
  }
</style>
