import Translations from './Translations'
import moment from 'moment-timezone'

export default class Vat extends Translations {
  constructor (id, rates, translations) {
    super(translations)

    this.id = id
    this.rates = rates
  }

  /**
   * GETTERS
   */

  getId () {
    return this.id
  }

  // get short term of vat
  getShort () {
    return this.getCurrentTranslation().getShort()
  }

  // returns vat name in current language with the vat rate
  getName (timestampMs = new Date().getTime(), showStartDate = false) {
    // get the name of the vat
    let vatName = this.getCurrentTranslation().getName()

    // search the rate
    let rate = this.getRate(timestampMs)

    let formattedStartDate = showStartDate ? (' ' + i18n.t('v6.from') + ' ' + moment(rate.startDate * 1000).format('DD.MM.YYYY')) : ''

    /* global i18n */

    return vatName + ' (' + rate.rate + ' %)' +  formattedStartDate
  }

  // get rate which fits the timestamp
  getRate (timestamp) {
    let nowSec = parseFloat(timestamp / 1000).toFixed(0)

    // copy array by value (not by reference which causes re rendering, ending in an infinite loop)
    let rates = this.rates.slice()

    // sort array descending
    rates.sort((a, b) => b.startDate - a.startDate)

    // iterate all rates and get youngest one
    for (let i = 0; i < rates.length; i++) {
      let rate = rates[i]
      if (rate.startDate <= nowSec) {
        return rate
      }
    }
    return nowSec
  }
}
