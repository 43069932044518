<template>
  <div>
    <!-- if no items were found or loaded -->
    <v-row
      v-show="!items"
    >
      <v-col class="col-12">
        {{$t('reservation.noItemsFound')}}
      </v-col>
    </v-row>

    <!-- new grid -->
    <v-row class="justify-center break-word">
      <v-col
        v-for="item in filteredItems()"
        v-show="item.isActive()"
        :key="item.id"
        class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-5 px-0 py-2"
        :class="{'greyedOut': !item.canISeeTheMachine()}"
      >
        <v-carousel
          hide-delimiters
          :show-arrows="!!item.hasVariants()"
          height="100%"
          next-icon="fal fa-chevron-double-right"
          prev-icon="fal fa-chevron-double-left"
        >
          <v-carousel-item>
            <v-card
              light
              :hover="item.canISeeTheMachine()"
              class="mx-3 my-4"
              @click.prevent="showItemDetail(item)"
            >
              <!-- image -->
              <v-row>
                <v-col class="col-6 col-md-12 py-0">
                  <v-img
                    class="border-top-left-radius"
                    :class="[{'border-top-right-radius': $vuetify.breakpoint.smdAndUp}]"
                    aspect-ratio="1"
                    :src="item.getImg()"
                  >
                    <v-row class="fill-height justify-end">
                      <!-- favorite star -->
                      <v-col
                        class="text-left pl-5 col-12"
                      >
                        <items-grid-favorite
                          class="pt-2"
                          :item="item"
                        />
                      </v-col>

                      <!-- logo of user -->
                      <v-col
                        v-if="item.getOwner().getLogo()"
                        class="col-7 align-self-end text-center white pa-2"
                      >
                        <v-img
                          :max-height="$vuetify.breakpoint.smAndDown ? 18 : 23"
                          contain
                          :src="item.getOwner().getLogo()"
                        />
                      </v-col>
                    </v-row>
                  </v-img>
                </v-col>

                <!-- title and description -->
                <v-col
                  class="col-6 col-md-12 pl-0 pl-md-5 pr-md-5 py-0"
                >
                  <items-grid-description
                    :item="item"
                    :reservation-button-show="reservationButtonShow"
                    @selectItem="selectMachine(item)"
                  />
                </v-col>
              </v-row>

              <!-- distance, address and prices -->
              <items-grid-info
                :item="item"
                :discounts="discountsInstance"
                :search-start="searchStart"
                :search-end="searchEnd"
              />
            </v-card>
          </v-carousel-item>

          <!-- variants -->
          <v-carousel-item
            v-for="(variant, variantId) in item.getVariantItemsArray()"
            :key="variantId"
          >
            <v-card
              :hover="variant.canISeeTheMachine(false, true)"
              class="mx-3 my-4"
              @click.prevent="showItemDetail(variant, true)"
            >
              <v-row>
                <!-- image -->
                <v-col class="col-6 col-md-12 py-0">
                  <v-img
                    class="border-top-left-radius"
                    :class="[{'border-top-right-radius': $vuetify.breakpoint.smdAndUp}]"
                    aspect-ratio="1"
                    :src="variant.getImg()"
                  >
                    <v-row class="no-gutters">
                      <v-col class="col-3">
                        <items-grid-favorite
                          :item="variant"
                        />
                      </v-col>
                      <v-col class="col-6">

                      </v-col>
                    </v-row>
                  </v-img>
                </v-col>

                <!-- title and description -->
                <v-col
                  class="col-6 col-md-12 pl-0 pl-md-5 pr-md-5 pt-0"
                >
                  <items-grid-description
                    :item="variant"
                    :reservation-button-show="reservationButtonShow"
                    @selectItem="selectMachine(variant)"
                  />
                </v-col>
              </v-row>

              <!-- distance, address and prices -->
              <items-grid-info
                :item="variant"
                :discounts="discountsInstance"
                :search-start="searchStart"
                :search-end="searchEnd"
              />
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Discounts from '../../classes/Modules/Discounts/Discounts'
import ItemsGridDescription from './itemsGrid/ItemsGridDescription'
import ItemsGridInfo from './itemsGrid/ItemsGridInfo'
import ItemsGridFavorite from './itemsGrid/ItemsGridFavorite'

export default {
  name: 'items-grid',

  components: {
    ItemsGridFavorite,
    ItemsGridInfo,
    ItemsGridDescription,
  },

  props: {
    items: {
      required: false
    },

    searchShow: {
      type: Boolean,
      required: false,
      default: true
    },

    reservationButtonShow: {
      type: Boolean,
      required: false,
      default: true
    },

    // search periode (for advanced search)
    searchStart: {
      type: Number,
      required: false,
      default: null
    },

    searchEnd: {
      type: Number,
      required: false,
      default: null
    }
  },

  data () {
    return {
      search: '',
      discountsInstance: new Discounts()
    }
  },

  watch: {
    async items () {
      await this.discountsInstance.loadDiscountsByItems(this.items)
    }
  },

  methods: {
    // filter items by the search string
    filteredItems () {
      if (this.items && this.items.items) {
        return this.items.getItems().filter((item) => {
          return (!item.hasOwnProperty('reservations') || item.hasOwnProperty('reservations')) &&
            (item.getName().toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
              item.getDescription().toLowerCase().indexOf(this.search.toLowerCase()) >= 0)
        })
      } else {
        return []
      }
    },

    // shows the item details and stores the search details in the store
    showItemDetail (item, ignoreDistance = false) {
      if (item.canISeeTheMachine(true, ignoreDistance)) {
        // save tmp search
        this.$emit('ItemGrid:saveTmpSearch')
        this.$router.push({name: 'ItemDetail', params: {id: item.id}})
      }
    },

    selectMachine (item) {
      /* global EventBus */
      EventBus.$emit('selectMachine', item)
    }
  }
}

</script>

<style lang="scss">
  .fa-star {
    color: map-get($orange, base);
  }

  .fa-star-o {
    color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: map-get($orange, base);
  }

  .v-window__prev, .v-window__next {
    margin: 0 0 !important;
  }
</style>
