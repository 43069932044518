export default class PermissionHandler {
  /**
   * does the current user has permission to enter the asked route?
   * if permission granted: return null
   * if permission denied: return route object {name: 'RouteName'}
   * @param route
   */
  static hasPermission (route) {
    // does the route has a required min gid ?
    if (!route || !route.hasOwnProperty('minGid')) { // no min gid is required: all users (even not logged-in users) can access this route
      return true
    }
    // does the app user has the required min gid ?
    /* global store */
    const appUserInstance = store.state.appUserInstance
    if (appUserInstance && appUserInstance.getRealUser().getGid() <= route.minGid) { // access granted
      return true
    }
    // user doesn't have required minimal gid
    // is user logged in?
    if (store.getters.isUserLoggedIn()) { // user does not have the required access rights
      return {name: 'PermissionDenied'}
    }
    // try to log-in user
    return {name: 'Login'}
  }
}
