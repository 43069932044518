import Menu from './Menu'

/**
 * extends the menu with sub menus
 */

export default class MainMenu extends Menu {
  constructor (params) {
    super(params)
    this.subMenus = params.subMenus ? params.subMenus : []
    this.expanded = false
  }

  // expand sub menus
  toggleSubMenus () {
    this.expanded = !this.expanded
  }

  // collapse sub menus
  collapse () {
    this.expanded = false
  }

  expand () {
    this.expanded = true
  }

  /**
   * GETTERS
   */

  // are sub menus expanded, visible though?
  areSubmenusExpanded () {
    return this.expanded
  }

  getSubMenus () {
    return this.subMenus
  }

  hasSubmenus () {
    return this.subMenus.length > 0
  }
}
