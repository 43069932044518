<template>
  <div class="menu-container">
    <div class="menu-list" v-if="menuListInstance">

      <!--menu edit buttons-->
      <v-container
        v-if="isEditing"
      >
        <v-row
          class="px-4"
          justify="space-around"
        >
          <v-col class="col-auto">
            <v-btn class="pa-1"
                   color="error"
                   outlined
                   @click="cancelEdit"
            >
              <span class="fa fa-times-circle mr-2"></span>
              {{ $t('general.cancel') }}
            </v-btn>
          </v-col>

          <v-col class="col-auto">
            <v-btn class="pa-1"
                   color="primary"
                   outlined
                   @click="saveUserMenu"
            >
              <span class="fa fa-check-circle mr-2"></span>
              {{ $t('general.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <div
        v-for="(item, mIndex) in menuListInstance.getMenuList()"
        :key="mIndex"
        class="h5 mb-0  clickable"
      >
        <!-- main entry -->
        <v-row
          no-gutters
          v-if="item && item.getRoute() && PermissionHandler.hasPermission(item.getRoute().meta) === true && (!item.isOnlyMobile() || (item.isOnlyMobile() && $vuetify.breakpoint.smAndDown))"
          class="menu-entry pa-4 align-center"
          :class="{'border-bottom pb-2': mobileMode, 'selected-menu': highlightMenu(item), 'editing':isEditing }"
          @click="handleMenuClick(item)"
        >
          <!-- Icon -->
          <v-col class="col-2">
            <h5
              class="mr-2 secondary--text"
              :class="item.getIcon()"
            ></h5>
          </v-col>
          <!-- Title -->
          <v-col
            v-if="item.getRoute()"
            :class="{'col-10': !item.hasSubmenus(), 'col-8': item.hasSubmenus() || isEditing}"
          >
            <div class="h5">{{ item.getTitle() }}</div>
          </v-col>

          <!-- expanding icon -->
          <v-col
            v-if="item.hasSubmenus() && !isEditing"
            @click.stop="item.toggleSubMenus()"
            class="col-2 justify-end d-flex secondary--text mb-0"
            style="height: 30px;"
          >
            <!-- expanded sub menus -->
            <span
              v-if="item.areSubmenusExpanded() && !isEditing"
              class="fal fa-angle-up align-self-center fa-2x"
            />

            <!-- collapsed sub menu -->
            <span
              v-else
              class="fal fa-angle-down fa-2x mt-n1"
            />
          </v-col>

          <!-- Edit checkbox -->
          <v-spacer v-if="isEditing"></v-spacer>
          <v-col
            v-if="isEditing"
            class="col-auto"
          >
            <v-checkbox
              v-model="item.isActive"
              :disabled="checkboxIsEnabled(item)"
              @click.prevent.stop="handleMenuClick(item)"
            />
          </v-col>
        </v-row>


        <!-- sub menus -->
        <v-expand-transition>
          <div v-if="item.areSubmenusExpanded() || isEditing" class="py-0">
            <div
              v-for="(subItem, index) in item.getSubMenus()"
              :key="index"
            >
              <v-row
                no-gutters
                class="wrap menu-entry pa-3"
                :class="{'selected-menu': highlightMenu(subItem), 'editing':isEditing, 'py-0':isEditing}"
                @click="handleMenuClick(subItem)"
                v-if="item && subItem.getRoute() && PermissionHandler.hasPermission(subItem.getRoute().meta) === true"
              >
                <v-col
                  cols="8"
                  class="my-0 py-0 offset-2 subtitle-1">
                  {{ subItem.getTitle() }}
                </v-col>
                <v-spacer v-if="isEditing"></v-spacer>
                <v-col
                  v-if="isEditing"
                  class="col-auto ma-0 pa-0">
                  <v-checkbox
                    class="my-0 mr-1 pa-0"
                    v-model="subItem.isActive"
                    :disabled="checkboxIsEnabled(subItem)"
                    @click.prevent.stop="handleMenuClick(subItem)"
                  />
                </v-col>
              </v-row>
            </div>
          </div>
        </v-expand-transition>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from "../../../classes/Menu/Menu"
import MainMenu from "../../../classes/Menu/MainMenu"
import MenuList from '../../../classes/Menu/MenuList'
import menuConfiguration from "@/configs/menuConfiguration"
import PermissionHandler from '../../../classes/PermissionHandler'
import definitions from '../../../../definitions'
import {UsersServices as apiUser} from "@/services/api/users/UsersServices";


export default {
  name: "MenuItems",

  data () {
    return {
      menuListInstance: null,
      PermissionHandler: PermissionHandler,
      menuUserPreferences: null,
      isEditing: false,
    }
  },

  props: {
    mobileMode: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  mounted () {
    this.initMenu()

    /* global EventBus */
    EventBus.$on('initMenu', () => this.initMenu())
    EventBus.$on('finishEditMenu', () =>this.isEditing = false)
    EventBus.$on('editMenu', () => {
      this.isEditing = true
      if (this.mobileMode) {
        EventBus.$emit('editMobileMenu')
      }
      this.initMenu()
    })
  },

  methods: {
    async initMenu () {
      //cloning the menu user settings object from the store
      //to edit it without changing the state
      this.menuUserPreferences = JSON.parse(JSON.stringify(this.$store.state.appUserInstance.menuSettings))
      // generate all menu entries
      await this.generateMenuList()

      // open menu which belongs to the actual routing
      if (!this.isEditing)
        this.menuListInstance.expandMenuByCurrentRouteName(this.$route.name)
    },

    generateMenuList () {
      this.menuListInstance = new MenuList()
      // loop the menuItems object & populate the menuList
      for (let mainMenu of menuConfiguration.menuConf) {
        const userMenuConfig = this.menuUserPreferences.find(pref => pref.name === mainMenu.name)

        //generate menu marked as inactive only if in edit mode
        const generate = this.isEditing || !userMenuConfig || userMenuConfig.active
        if (!generate) continue
        //special case for "my employer machines": generate the employee menu only if in "employee mode"
        const employeeMode = this.$store.state.appUserInstance.hasOtherUser()
        if (!employeeMode && mainMenu.name === 'employee') continue
        const newMenu = this.generateMenu(mainMenu, userMenuConfig)
        // only add main menu if active or not new or not in edit mode
        if (typeof userMenuConfig !== 'undefined' && !userMenuConfig?.active && !this.isEditing) continue
        this.menuListInstance.addMenu(newMenu)
      }
      return Promise
    },

    generateMenu (menuConfig, userMenuConfig) {
      this.addNewMainMenuToUserConfig(userMenuConfig, menuConfig)
      //the parameters object to be sent to Menu.js constructor
      let menuObject = {
        name: menuConfig.name,
        active: !userMenuConfig ? true : userMenuConfig.active,
        title: menuConfig.title,
        routeName: menuConfig.routeName,
        icon: menuConfig.icon,
        minGid: menuConfig.minGid,
        onlyMobile: menuConfig.onlyMobile,
        subMenus: this.generateSubMenus(menuConfig, userMenuConfig)
      }
      return new MainMenu(menuObject)
    },

    generateSubMenus (menuConfig, userMenuConfig) {
      const generatedSubMenuList = []
      const submenus = menuConfig.subMenus
      const userSubMenus = userMenuConfig?.subMenus
      if (!submenus || !submenus.length) {
        return
      }
      // iterate all submenus
      for (const subMenu of submenus) {
        const userSubMenuConf = userSubMenus?.find(userSubConf => userSubConf.name === subMenu.name)

        //discount submenu special case: generate only if module is present
        if (subMenu.name === 'rentDiscounts') {
          if (!this.$store.state.appUserInstance.getRealUser().modules.find(module => module.identifier === definitions.modules.discount)) continue
        }

        //generate submenu only if it's marked as active, in edit mode or if it has newly been added by programmers (=== undefined)
        const newSubMenu = new Menu({
          name: subMenu.name,
          title: subMenu.title,
          routeName: subMenu.routeName,
          icon: subMenu.icon,
          minGid: subMenu.minGid,
          onlyMobile: subMenu.onlyMobile,
          active: typeof userSubMenuConf === 'undefined' ? true : userSubMenuConf.active,
          parentMenu: menuConfig.name
        })
        // add new submenus, not yet saved in user configuration
        this.addNewSubMenuToUserConfig(menuConfig, newSubMenu, userSubMenuConf)

        if (typeof userSubMenuConf !== 'undefined' && !userSubMenuConf?.active && !this.isEditing) continue
        generatedSubMenuList.push(newSubMenu)
      }
      return generatedSubMenuList
    },

    addNewMainMenuToUserConfig (userMenuConfig, menuConfig) {
      if (userMenuConfig) {
        return
      }
      this.menuUserPreferences.push({
        active: true,
        name: menuConfig.name,
        subMenus: []
      })
    },

    addNewSubMenuToUserConfig (menuConfig, newSubMenu, userSubMenuConf) {
      // submenu already exists
      if (typeof userSubMenuConf !== 'undefined') {
        return
      }
      // main menu not available
      const foundMainMenuConfig = this.menuUserPreferences.find(mainMenu => mainMenu.name === menuConfig.name)
      if (!foundMainMenuConfig) {
        return
      }
      // generate first new submenu array
      if (!foundMainMenuConfig.subMenus) {
        foundMainMenuConfig.subMenus = []
      }
      foundMainMenuConfig.subMenus.push({
        active: true,
        name: newSubMenu.name
      })
    },

    handleMenuClick (menu) {
      //make routing if not editing the menu
      if (!this.isEditing) this.menuListInstance.makeRouting(menu)
      //if in edit mode: clicking a menu will mark it as active/inactive for the user
      else {
        //the user preferences fetched from backend and stored in the state
        const userConfig = this.findMenuItemByName(this.menuUserPreferences, menu.name)

        //the menu configuration stored in MenuConfig class
        // holds keys not editable by the user (routes/icons/customizable flag)
        const menuConfig = this.findMenuItemByName(menuConfiguration.menuConf, menu.name)

        //if the user clicks a main menu all its submenus will be selected/deselected
        const isMainMenu = menu.hasSubmenus
        const clickedSubmenus = isMainMenu ? menu.getSubMenus() : null
        if (clickedSubmenus && clickedSubmenus.length) {
          let activeItems = 0
          for (const subMenu of clickedSubmenus) {
            subMenu.isActive ? activeItems++ : activeItems--
          }
          activeItems = activeItems - clickedSubmenus.length
          for (const subMenu of clickedSubmenus) {
            const subMenuUserConfig = this.findMenuItemByName(this.menuUserPreferences, subMenu.name)
            const subMenuConfig = this.findMenuItemByName(menuConfiguration.menuConf, subMenu.name)
            //select/deselect submenu if flagged as customizable in the config
            if (subMenuConfig.customizable) {
              //mark all as active/inactive depending if most of them are already active/inactive
              if (activeItems < 0) {
                subMenu.isActive = true
                subMenuUserConfig.active = true
              } else {
                subMenu.isActive = false
                subMenuUserConfig.active = false
              }
            }
          }
          //mark the clicked main menu as active/inactive
          //only if 1) customizable:true 2) all the submenus are disabled 3)it has no own route
          if (menuConfig.customizable) {
            let isEmpty = true
            for (const subMenu of clickedSubmenus) {
              if (subMenu.isActive) isEmpty = false
            }
            if (isEmpty) {
              menu.isActive = false
              userConfig.active = false
            } else {
              menu.isActive = true
              userConfig.active = true
            }
          }
          //todo Pietro: case 3) only if no route
        }
        //handle clicks on single submenus
        else {
          if (menuConfig.customizable) {
            menu.isActive = !menu.isActive
            userConfig.active = !userConfig.active

            //if all submenus are deselected also deselect parent menu if it doesn't have a route and it's customizable
            if (menu.parentMenu) {
              const parentConfig = this.findMenuItemByName(menuConfiguration.menuConf, menu.parentMenu)
              const parentUserConfig = this.findMenuItemByName(this.menuUserPreferences, menu.parentMenu)
              const parentMenu = this.findMenuItemByName(this.menuListInstance.getMenuList(), menu.parentMenu)

              let parentIsEmpty = true
              for (const subMenu of parentMenu.subMenus) {
                if (subMenu.isActive) parentIsEmpty = false
              }
              if (parentConfig.customizable && parentMenu.getRoute().meta.inactive) {
                if (parentIsEmpty) {
                  parentUserConfig.active = false
                  parentMenu.isActive = false
                } else {
                  parentUserConfig.active = true
                  parentMenu.isActive = true
                }
              }
            }
          }
          //display warning if user tries to deactivate a required menu (ie customizable:false)
          else {
            //todo Pietro: add a tooltip or use app notifications
          }
        }
      }
    },

    highlightMenu (menu) {
      //while in edit mode menu highlight is disabled
      if (this.isEditing) {
        return false
      }
      //otherwise only current route will be highlighted as normal
      else return menu.getRoute().name === this.$route.name
    },

    findMenuItemByName (targetObj, menuName) {
      let result
      result = targetObj.find(m => m.name === menuName)
      if (!result) {
        for (const userMenu of targetObj) {
          if (userMenu.subMenus.length) {
            result = userMenu.subMenus.find(s => s.name === menuName)
            if (result) break
          }
        }
      }
      return result
    },

    cancelEdit () {
      this.isEditing = false
      EventBus.$emit('finishEditMenu')
      this.initMenu()
      if (this.mobileMode) {
        EventBus.$emit('closeMenu')
      }

    },

    async saveUserMenu () {
      const success = await apiUser.patchUser(this.$store.state.appUserInstance.getId(), {menuSettings: JSON.stringify(this.menuUserPreferences)})
      if (success) {
        // store new user menu settings in vuex
        this.$store.commit('saveUserMenu', this.menuUserPreferences)
        this.$notify({
          title: this.$t('notify.userUpdated'),
          type: 'success'
        })
      }
      this.cancelEdit()
    },

    checkboxIsEnabled (menu) {
      const userConfig = this.findMenuItemByName(menuConfiguration.menuConf, menu.name)
      return !userConfig.customizable
    },
  }
}
</script>

<style lang="scss">
.selected-menu {
  background-color: var(--v-primary-base);
  color: white !important;
}

.menu-entry:not(.editing):hover {
  background-color: var(--v-primary-base);
  color: white !important;
}
.menu-container{
  z-index: 2;
}
</style>
