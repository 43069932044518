import Vue from 'vue'
import Item from '../../Item'

/**
 * the model for discounts
 */
export default class Discount {

  /**
   * database id
   * @type {null}
   */
  id = null

  /**
   * the description of this discount
   * @type {string}
   */
  description = ''

  /**
   * the date when its billed
   * @type {null, Date}
   */
  targetDate = null

  /**
   * the linked machines
   * @type {Array}
   */
  module_discount_itemLinks = []

  /**
   * the duration of the  discount
   * Is ALWAYS 12
   * @type {number}
   */
  duration_in_month = 12

  /**
   * discount levels
   * @type {Array}
   */
  module_discount_levels = []

  /**
   * all accessible machines for the user
   * @type {Array}
   */
  userMachines = []

  /**
   * raw api object with all billed reservations fetched from /reservations/billed/:startdate/:enddate
   * @type {Array}
   */
  discountReservations = null

  constructor (rawDiscount) {
    this.addEmptyDiscountLevel()
    if (rawDiscount) {
      this.parseRawDiscount(rawDiscount)
    }
  }

  /**
   * load an existing discount
   */
  async loadDiscount() {
    if (!this.id) {
      throw new Error('Discount Id is not defined')
    }
    /* global EventBus*/
    EventBus.$emit('spinnerShow');

    try {
      let response = await axios.get(api + 'module/discount/' + this.id);

      if (response.status === 200) {
        this.parseRawDiscount(response.data)
      } else {
        Vue.notify({
          title: i18n.t('v13.discountLoadFailed'),
          text: response.status,
          type: 'error'
        })
      }
    } catch (e) {
      Vue.notify({
        title: i18n.t('v13.discountLoadFailed'),
        type: 'error'
      })
    } finally {
      EventBus.$emit('spinnerHide')
    }
  }

  /**
   * create a new discount
   */
  async saveDiscount() {
    let successful = false

    if (this.valid())  {
      /* global EventBus axios api i18n */
      EventBus.$emit('spinnerShow');

      let postBody = {
        description: this.description,
        // this is always 12 months!!
        duration_in_month: 12,
        targetDate: this.targetDate.toISOString(),
        unitId: this.module_discount_itemLinks[0].getUnit().getId(),
        itemIdArray: []
      }

      this.module_discount_itemLinks.forEach((machine) => {
        postBody.itemIdArray.push(machine.id)
      })

      try {
        let response = await axios.post(api + 'module/discount', postBody);
        if (response.status === 200) {
          await Promise.all(
            this.module_discount_levels.map((level) => {
                return this.createDiscountLevel(response.data.id, level)
              }
            )
          )
          successful = true
        } else {
          Vue.notify({
            title: i18n.t('v13.addingFailed'),
            text: response.status,
            type: 'error'
          })
        }
      } catch (e) {
        Vue.notify({
          title: i18n.t('v13.addingFailed'),
          type: 'error'
        })
      } finally {
        EventBus.$emit('spinnerHide')
      }
    }

    // eslint-disable-next-line
    return Promise.resolve(successful)
  }

  /**
   * creates a discount level
   * @param level
   * @returns {Promise<PromiseConstructor>}
   */
  async createDiscountLevel(discountId, level) {
    EventBus.$emit('spinnerShow')
    level.lowerThreshold = parseFloat(level.lowerThreshold)
    level.upperThreshold = parseFloat(level.upperThreshold)
    level.discountPerQuantity = parseFloat(level.discountPerQuantity)

    if (level.lowerThreshold >= 0 && level.upperThreshold > 0 && level.discountPerQuantity > 0) {
      try {
        let response = await axios.post(api + 'module/discount/' + discountId + '/level', level)

        if (response.status !== 200) {
          Vue.notify({
            title: i18n.t('v13.addingFailed'),
            text: response.status,
            type: 'error'
          })
        }
      } catch (e) {
        Vue.notify({
          title: i18n.t('v13.addingFailed'),
          type: 'error'
        })
      } finally {
        EventBus.$emit('spinnerHide')
      }
    }
  }

  /**
   * Update itself as existing discount
   */
  async updateDiscount() {
    // eventually store new discount levels
    this.storeAdditionalDiscountLevels()

    /* global EventBus axios api i18n */
    EventBus.$emit('spinnerShow');
    let successful = true
    try {
      let response = await axios.patch(api + 'module/discount/' + this.id, {
        description: this.description,
        itemIdArray: this.module_discount_itemLinks.map((link) => link.id)
      })

      if (response.status !== 200)  {
        successful = false
        Vue.notify({
          title: i18n.t('v13.updateFailed'),
          text: response.status,
          type: 'error'
        })
      }
    } catch (e) {
      successful = false
      Vue.notify({
        title: i18n.t('v13.updateFailed'),
        type: 'error'
      })
    } finally {
      EventBus.$emit('spinnerHide')
    }
    // eslint-disable-next-line
    return Promise.resolve(successful)
  }

  /**
   * Delete an existing discount
   * @param discountInstance Discount
   */
  async delete() {
    /* global EventBus axios api i18n */
    EventBus.$emit('spinnerShow');

    try {
      let response = await axios.delete(api + 'module/discount/' + this.id);

      if (response.status !== 200) {
        Vue.notify({
          title: i18n.t('v13.updateFailed'),
          text: response.status,
          type: 'error'
        })
      }
    } catch (e) {
      Vue.notify({
        title: i18n.t('v13.updateFailed'),
        type: 'error'
      })
    } finally {
      EventBus.$emit('spinnerHide')
    }
  }

  /**
   * parse an api discount object
   * @param rawDiscount
   */
  parseRawDiscount(rawDiscount) {
    this.id = rawDiscount.id
    this.description = rawDiscount.description
    this.targetDate = new Date(rawDiscount.targetDate)

    let links = rawDiscount.module_discount_itemLinks

    // parse item instances
    if (links && links.length && links[0].hasOwnProperty('Item')) {
      for (let i = 0; i < links.length; i++) {
        let rawItem = links[i].Item
        let itemInstance = this.userMachines.find(item => item.getId() === rawItem.id)
        if (itemInstance) this.module_discount_itemLinks.push(itemInstance) // get item instance from my items
        else { // only get information from api
          rawItem.ItemUnit = rawDiscount.ItemUnit
          this.module_discount_itemLinks.push(new Item(rawItem))
        }
      }
    }

    this.module_discount_levels = rawDiscount.module_discount_levels

    // sort discount levels by lowerThreshold if any
    if (this.module_discount_levels) this.module_discount_levels.sort((a, b) => {return a.lowerThreshold - b.lowerThreshold})

    this.duration_in_month = rawDiscount.duration_in_month
    this.ItemUnit =  rawDiscount.ItemUnit
  }

  /**
   * get form descriptors
   * @returns {Promise<{selectedMachines: [], targetDate: Date, discountLevels: [], description: string, machines: []}>}
   */
  async getFormData() {
    /* global store*/
    const user = store.state.appUserInstance.getRealUser()
    this.userMachines = await user.fetchAllMyItems()

    if (this.id) {
      await this.loadDiscount()
    }
    // eslint-disable-next-line
    return Promise.resolve(this)
  }

  /**
   * remove a discount level
   * @param index
   */
  async removeDiscountLevel(level, key) {
    if (this.id && level) {
      /* global EventBus */
      EventBus.$emit('spinnerShow');
      let requestSuccesful = false
      try {
        let response = await axios.delete(api + 'module/discount/' + this.id + '/level/' + level)

        if (response.status === 200) {
          requestSuccesful = true

          this.module_discount_levels.splice(key, 1)
        } else {
          Vue.notify({
            title: i18n.t('v13.updateFailed'),
            text: response.status,
            type: 'error'
          })
        }
      } catch (e) {
        Vue.notify({
          title: i18n.t('v13.updateFailed'),
          type: 'error'
        })
      } finally {
        EventBus.$emit('spinnerHide')
      }

      // eslint-disable-next-line
      return Promise.resolve(requestSuccesful)
    } else {
      this.module_discount_levels.splice(key, 1)
      // eslint-disable-next-line
      return Promise.resolve(true)
    }
  }

  /**
   * store an additional discount level
   * @returns {Promise<boolean>}
   */
  async storeAdditionalDiscountLevels () {
    let levels = this.module_discount_levels.filter(tmpLevel => !tmpLevel.hasOwnProperty('id'))

    // iterate levels to add
    for (let i = 0; i < levels.length; i++) {
      this.createDiscountLevel(this.id, levels[i])
    }
  }

  /**
   * add a empty discount level
   */
  addEmptyDiscountLevel() {
      this.module_discount_levels.push({
        lowerThreshold: null,
        upperThreshold: null,
        discountPerQuantity: null
      })
  }

  /**
   * check if the discount is valid
   * @returns {boolean}
   */
  valid() {
    let valid = true

    if (!this.description) {
      valid = false
      Vue.notify({
        title: i18n.t('v13.discountForm.descriptionEmptyError'),
        type: 'error'
      })
    }

    if (!this.targetDate) {
      valid = false
      Vue.notify({
        title: i18n.t('v13.discountForm.dateNotSet'),
        text: i18n.t('v13.discountForm.mustSetDate'),
        type: 'error'
      })
    }

    if (this.module_discount_itemLinks.length === 0) {
      valid = false
      Vue.notify({
        title: i18n.t('v13.discountForm.noMachineSelected'),
        text: i18n.t('v13.discountForm.mustSelectMachine'),
        type: 'error'
      })
    }

    return valid
  }

  /**
   * Make sure you can select only machines with the same unit
   */
  filterSelectableMachines() {
    if (this.module_discount_itemLinks.length >= 1) {
      this.userMachines.forEach((machine) => {
        if (machine.unitId === this.module_discount_itemLinks[0].unitId) {
          machine.disabled = false
        } else {
          machine.disabled = true
        }
      })
    }
    return this.userMachines
  }

  /**
   * check if the lower threshold is larger thant the proceeding upper
   * @param key
   * @returns {string | VueI18n.LocaleMessages[]|string | VueI18n.LocaleMessages[]}
   */
  validateLowerThreshold(key) {
    if (this.module_discount_levels.length > 0 && this.module_discount_levels[key]) {
      const lowerThreshold =  parseInt(this.module_discount_levels[key].lowerThreshold)
      if (lowerThreshold < 0) {
        return [i18n.t('v13.discountForm.negativeValuesNotAllowed')]
      }

      if (this.module_discount_levels[key -1]) {
        const proceedingUpperThreshold = parseInt(
          this.module_discount_levels[key -1].upperThreshold
        )
        if (lowerThreshold !== proceedingUpperThreshold) {
          return [i18n.t('v13.discountForm.mustEqualToProceeding')]
        }
      }
    }
  }

  /**
   * make sure upper is greater than lower
   * @param level
   * @returns {string | VueI18n.LocaleMessages[]}
   */
  validateUpperThreshold(level) {
    if (parseInt(level.upperThreshold) <= parseInt(level.lowerThreshold)) {
      return [i18n.t('v13.discountForm.UpperMustBeGreaterThanLower')]
    }
  }

  /**
   * get unit of the first selected item
   * @returns {null}
   */
  getUnit (assumeCounterCoefficient = false, short = false) {
    let item = this.unitHelper()
    if (item) {
      if (short) return item.getUnitShort(assumeCounterCoefficient)
      else return item.getUnitName(assumeCounterCoefficient)
    }
    return null
  }

  getUnitId () {
    let item = this.unitHelper()
    if (item) return item.getUnit().getId()
    return null
  }

  unitHelper () {
    if (this.module_discount_itemLinks && this.module_discount_itemLinks.length) {
      let item = this.userMachines.find(tmpItem => tmpItem.getId() === this.module_discount_itemLinks[0].id)
      return item
    }
    return null
  }

  /**
   * does this discount have a particular item
   * @param itemId
   * @returns {Boolean | undefined}
   */
  containsItemId (itemId) {
    return this.module_discount_itemLinks.find(link => link.id === itemId)
  }

  /**
   * add or remove item
   * @param item
   */
  changeItem (item) {
    let index = this.module_discount_itemLinks.findIndex(link => link.id === item.getId())

    if (index !== -1) {
      // link exists. delete
      this.module_discount_itemLinks.splice(index, 1)
    } else {
      this.module_discount_itemLinks.push(item)
    }
  }

  /**
   * fetching all billed reservations to calculate the discount. these reservations are from the past
   * @returns {Promise<PromiseConstructor>}
   */
  async loadDiscountReservations (billingStart) {
    if (!this.targetDate) {
      Vue.notify({
        title: i18n.t('discount.targetDateMissing'),
        type: 'error'
      })
      throw new Error('Unexpected Error: The target date is missing inside Discount.loadDiscountReservations()!')
    }
    // subtract a year, if target date > billing start
    const fullYearForStartDate = this.targetDate.getMonth() > billingStart.getMonth() ? billingStart.getFullYear() - 1 : billingStart.getFullYear()
    // prepare start and end date
    let startDate = new Date (fullYearForStartDate, this.targetDate.getMonth(), 1, 0, 0, 0, 0)
    let endDate = new Date (billingStart.getFullYear(), billingStart.getMonth(), billingStart.getDate(), 0, 0, 0, 0)

    /* global EventBus axios api i18n */
    try {
      let response = await axios.get(api + 'reservations/billed/' + startDate.getTime() / 1000 + '/' + endDate.getTime() / 1000)

      // store data
      this.discountReservations = response.data
    } catch (e) {
      Vue.notify({
        title: i18n.t('v13.errorOnCalculatingDiscounts'),
        type: 'error'
      })
    }
  }

  /**
   * SETTERS
   */

  addItemLink (itemInstance) {
    this.module_discount_itemLinks.push(itemInstance)
  }

  /**
   * GETTERS
   */
  getId () {
    return this.id
  }

  getDiscountLevels () {
    return this.module_discount_levels
  }

  getItemLinks () {
    return this.module_discount_itemLinks
  }

  getDescription () {
    return this.description
  }

  getTargetDate () {
    return this.targetDate
  }

  getDiscountReservations () {
    return this.discountReservations
  }
}
