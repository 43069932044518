<template>
  <v-row
    class="footer-bottom"
  >
    <!-- menu -->
    <v-col
      v-if="$store.state.appUserInstance"
      class="col-12 col-md-3 menu-col"
    >
      <menu-container/>
    </v-col>
    <div class="blocker" v-if="isEditingMenu"></div>

    <!-- main content -->
    <v-col class="col-12 col-md-9">
      <router-view />
    </v-col>
  </v-row>
</template>

<script>
import MenuContainer from './menu/MenuContainer'

export default {
  name: 'layoutWithMenu',

  components: {
    MenuContainer,
  },
  data () {
    return {
      isEditingMenu: false
    }
  },
  mounted () {
    /*global EventBus*/
    EventBus.$on('editMenu', () => {
      this.isEditingMenu = true
    })
    EventBus.$on('finishEditMenu', () => {
      this.isEditingMenu = false
    })
  }
}
</script>

<style lang="scss">
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .footer-bottom {
    margin-bottom: $s-footer-mobile;
  }
}

.menu-col {
  z-index: 5;
}

.blocker {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(33, 33, 33, 0.20);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  z-index: 3;
}
</style>
