import Vue from 'vue'

export default class IbanHelper {
  static checkIbanOfAppUser () {
    /* global store i18n */
    const userInstance = store.getters.getAppUserInstance()
    // unexpected error
    if (!userInstance) {
      Vue.notify({
        title: i18n.t('invoice.appUserMissing'),
        type: 'error'
      })
      return false
    }
    // check iban
    if (!userInstance.isIbanValid()) {
      Vue.notify({
        title: i18n.t('invoice.missingIban'),
        text: i18n.t('invoice.provideIban'),
        type: 'error'
      })
      return false
    }
    // everything alright
    return true
  }
}
