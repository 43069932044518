<template>
  <v-fade-transition>
    <v-row>
      <v-col>
        <router-view/>
      </v-col>
    </v-row>
  </v-fade-transition>
</template>

<script>
  export default {
    name: 'LayoutBlank'
  }
</script>
