import Translations from './Translations'

export default class CancellationPolicy extends Translations {
  constructor (apiCancellationPolicy) {
    super(apiCancellationPolicy.translations)

    this.id = apiCancellationPolicy.id
    this.cancellationLimit = apiCancellationPolicy.cancellationLimit
  }

  /**
   * GETTERS
   */

  getCancellationLimit () {
    return this.cancellationLimit
  }

  getId () {
    return this.id
  }

  getName () {
    return this.getCurrentTranslation().getName()
  }

  getDescription () {
    return this.getCurrentTranslation().getDescription()
  }
}
