import Vue from 'vue'
import Reservation from './Reservation'
import User from './User'
import {utils} from 'xlsx'
import XlsxHelper from './XlsxHelper/XlsxHelper'

export default class Reservations {
  /**
   * itemId
   * startDate
   * endDate
   * array of reservation instances
   * @param params
   */
  constructor (params = {}) {
    if (params && params.reservationInstances) { // array of reservation instances
      this.reservations = params.reservationInstances
    } else if (params && params.apiReservations) { // parse raw reservation json from api
      this.reservations = this.parseApiReservations(params.apiReservations)
    } else {
      this.reservations = []
    }
  }

  // parse reservations array from api
  parseApiReservations (apiReservations) {
    let tmpReservations = []
    // iterate api reservations array
    apiReservations.forEach((apiReservation) => {
      tmpReservations.push(new Reservation(apiReservation, new User(apiReservation.ReservationsUser)))
    })
    return tmpReservations
  }

  /**
   *
   * itemId
   * startDate
   * endDate
   * @param params
   */
  async loadReservations (params) {
    let url = ''
    let autoParse = false

    if (params.itemId && params.startDate && params.endDate) {
      url = 'reservations/item/' + params.itemId + '/' + params.startDate + '/' + params.endDate
    } else if (params.type && params.type === 'upcoming') {
      url = 'reservations/upcoming/' + params.page + '/' + params.limit
    } else if (params.type && params.type === 'hasNoAmount') {
      url = 'reservations/hasNoAmount/' + params.page + '/' + params.limit
    } else if (params.type && params.type === 'hasNoAmountDate') {
      url = 'reservations/hasNoAmount/belongToUserItems/' + params.from + '/' + params.to + '/' + params.page + '/' + params.limit
    } else if (params.from && params.to && params.hasOwnProperty('page') && params.hasOwnProperty('limit')) {
      url = 'reservations/user/' + params.from + '/' + params.to + '/' + params.page + '/' + params.limit
    } else if (params.type && params.type === 'notInvoiced' && params.endDate) {
      url = 'reservations/notInvoiced/' + params.endDate
      autoParse = true
    }

    /* global EventBus axios api i18n */
    EventBus.$emit('spinnerShow')

    try {
      let response = await axios.get(api + url)

      if (response.status === 200) {
        let reservations = []

        // reset reservations
        if (params.loadMore) reservations = this.getReservations()

        let reservationsArray = response.data.reservations ? response.data.reservations : response.data
        // create new reservation objects
        reservationsArray.forEach((tmpReservation) => {
          // has user object ?
          if (tmpReservation.ReservationsUser) reservations.push(new Reservation(tmpReservation, new User(tmpReservation.ReservationsUser)))
          else if (tmpReservation.uid) reservations.push(new Reservation(tmpReservation, new User({uid: tmpReservation.uid})))
        })
        if (autoParse) {
          this.reservations = this.parseApiReservations(reservationsArray)
        }
        return reservations
      } else {
        Vue.notify({
          title: i18n.t('v4.reservationsCouldNotBeenLoaded'),
          text: response.status,
          type: 'error'
        })

        return null
      }
    } catch (e) {
      Vue.notify({
        title: i18n.t('v4.reservationsCouldNotBeenLoaded'),
        type: 'error'
      })

      return null
    } finally {
      EventBus.$emit('spinnerHide')
    }
  }

  // sort reservations array by date
  sortByStartDate (params = {}) {
    if (params.descending) return this.reservations.sort((a, b) => b.getStartDate() - a.getStartDate())
    return this.reservations.sort((a, b) => a.getStartDate() - b.getStartDate())
  }

  // delete single reservation from reservations array
  deleteReservation (id) {
    this.reservations = this.reservations.filter((reservation) => {
      return reservation.getId() !== id
    })
  }

  /**
   * SETTERS
   */

  // set reservations array
  setReservations (reservations) {
    this.reservations = reservations
  }

  // push new reservation to reservations array
  addReservation (reservation) {
    this.reservations.push(reservation)
  }

  addReservations (reservations) {
    for (let reservation in reservations) {
      this.reservations.push(reservations[reservation])
    }
  }

  /**
   * GETTERS
   * params: sortByStartDate
   */

  /**
   * get the first reservation in the array (sorted by start date)
   */
  async getStartCounterOfFirstReservation () {
    let reservations =  await this.getReservations({sortByStartDate: true})
    let startCounter = reservations[0].getStartCounter()
    return startCounter
  }

  /**
   * Get the end counter of the last reservation of the reservations array
   * @returns {Promise<*>}
   */
  async getEndCounterOfLastReservation () {
    let reservations = await this.getReservations({sortByStartDate: true})
    return reservations[reservations.length - 1].getEndCounter()
  }

  /**
   *
   * @param params (sortByStartDate: Boolean, default: false | descending: Boolean, default: false)
   * @returns {*}
   */
  getReservations (params = {}) {
    // sort reservations by start date
    if (params.sortByStartDate) {
      return this.sortByStartDate(params)
    } else {
      return this.reservations
    }
  }

  // iterate all reservations and get total time in s
  getTotalTime () {
    let totalTime = 0
    this.reservations.forEach((reservation) => {
      totalTime += reservation.getReservationTime()
    })

    return totalTime
  }

  getOccupation (start, end) {
    let occupation = 0
    this.reservations.forEach((reservation) => {
      occupation += reservation.getOccupation(start, end)
    })

    return occupation
  }

  // total costs of all reservations
  getTotal () {
    // iterate all reservations
    let total = 0
    this.reservations.forEach((reservation) => {
      total += parseFloat(reservation.getSaldo())
    })

    /* global store */
    return store.getters.roundCHF(total)
  }

  // get total vat of all reservations
  getTotalExklVat () {
    // iterate all reservations
    let totalExklVat = 0
    this.reservations.forEach((reservation) => {
      totalExklVat += reservation.getSaldoExklVat()
    })

    return totalExklVat
  }

  // total exkl. vat rounded
  getTotalExklVatRounded () {
    return Math.round(this.getTotalExklVat() * 100) / 100
  }

  // total of vat prices
  getTotalVat () {
    // iterate all reservations
    let totalVat = 0
    this.reservations.forEach((reservation) => {
      totalVat += reservation.getVatPrice()
    })

    return totalVat
  }

  getTotalVatRounded () {
    return Math.round(this.getTotalVat() * 100) / 100
  }

  // get all different tax rates in the reservations and calc total
  getAllVatRatesAndTotal () {
    // iterate reservations
    let taxRates = []
    this.reservations.forEach((reservation) => {
      // was tax rate already added to tax rates array?
      let foundTaxRate = taxRates.find((tmpRate) => {
        // the rat id and the vat id have to be identically
        return tmpRate.vat.getId() === reservation.getVat().getId() &&
          tmpRate.rateId == reservation.getVatRate().id
      })
      if (foundTaxRate) { // vat rate already added
        // add costs of reservation
        foundTaxRate.total += parseFloat(reservation.getVatPrice())
      } else { // vat rate not yet added
        taxRates.push({
          vat: reservation.getVat(),
          vatName: reservation.getVatName(false),
          rateId: reservation.getVatRate().id,
          total: reservation.getVatPrice()
        })
      }
    })

    // sort vat rates by short term
    taxRates.sort((a, b) => {
      if (a.vat.getShort() < b.vat.getShort()) return -1
      if (a.vat.getShort() > b.vat.getShort()) return 1
      return 0
    })

    return taxRates
  }

  // total amount
  getTotalAmount () {
    let totalAmount = 0
    // iterate reservations
    for (let i = 0; i < this.reservations.length; i++) {
      totalAmount += parseFloat(this.reservations[i].getAmount())
    }

    return totalAmount
  }

  /**
   * returns a work sheet to export with XLSX
   */
  async getWorkSheet (itemInstance) {
    // init excel worksheet
    let workSheet = utils.json_to_sheet(await this.getJsonWorkSheet(itemInstance))

    // change header
    let xlsxHelper = new XlsxHelper(workSheet)
    let headerArray = [
      i18n.t('general.renter'),
      '',
      '',
      '',
      '',
      '',
      i18n.t('archiveExport.renterIsMember'),
      i18n.t('general.from'),
      '',
      i18n.t('general.to'),
      '',
      i18n.t('setAmount.startCounterShort'),
      i18n.t('setAmount.endCounterShort'),
      i18n.t('v2.counterCoefficient'),
      i18n.t('invoice.use'),
      '',
      i18n.t('invoice.pricePerUnit'),
      '',
      i18n.t('v6.minFeeShort'),
      '',
      i18n.t('general.total'),
      '',
      i18n.t('v6.cancelledThe'),
      i18n.t('v13.rejectedReservation'),
      i18n.t('v2.messageToReservation'),
      i18n.t('general.lessor'),
      '',
      '',
      '',
      '',
      ''
    ]
    await xlsxHelper.setHeader(headerArray)

    // set auto width
    await xlsxHelper.setColumnWidthAuto()

    return xlsxHelper.getWorkSheet()
  }

  /**
   * returns array with json objects which we can transform to an excel worksheet
   * it's a helper function though
   * @param unitShort
   * @returns {Array}
   */
  async getJsonWorkSheet (itemInstance, showItem = false) {
    let jsonWorkSheet = []

    // iterate all reservations
    let reservations = await this.getReservations({sortByStartDate: true})
    reservations.forEach((reservation) => {
      jsonWorkSheet.push(reservation.getJson(itemInstance, showItem))
    })

    return jsonWorkSheet
  }
}
